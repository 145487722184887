(function (widgets) {
  function init_imagemap_multi($el) {
    var anchors = $el.find('div.image-area a');
    $.each(anchors, function (i) {
      $(this).click(function (e) {
        var inp = $(this).find('input');
        if (inp.val() == 'unselected') {
          inp.val('selected');
        } else {
          inp.val('unselected');
        }
        $(this).toggleClass('selected');
        return false;
      });
      if ($(this).find('input').val() == 'selected') {
        $(this).addClass('selected');
      }
    });
  }

  var ImageMapMultiple = widgets.Widget.extend(
    {
      render: function render() {
        ImageMapMultiple.__super__.render.call(this);
        init_imagemap_multi(this.$el);
      },
    },
    {
      types: ['imagemap-multiple'],
      views: ['imagemap-multiple'],
    }
  );

  ImageMapMultiple.register();
  widgets.ImageMapMultiple = ImageMapMultiple;
})((Gryphon.widgets = Gryphon.widgets || {}));

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "    <thead>\n        <tr class=\"matrix-row grid-row-"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.response_options : depth0)) != null ? stack1.length : stack1), depth0))
    + "\">\n            <td>&nbsp;</td>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.response_options : depth0), {"name":"each","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </tr>\n    </thead>\n\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.phases : depth0), {"name":"each","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " \n\n    ";
},"2":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "            <th class=\"matrix-header-response-";
  stack1 = lambda((depth0 != null ? depth0.key : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</th>\n";
},"4":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "    <tbody id=\"phase-"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\" ";
  stack1 = __default(require("../js/helpers/ifnotphase.js")).call(depth0, (depths[1] != null ? depths[1].phase_pos : depths[1]), (data && data.index), {"name":"ifnotphase","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " data-phase=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">\n";
  stack1 = helpers.each.call(depth0, depth0, {"name":"each","hash":{},"fn":this.program(7, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  \n    </tbody>\n    ";
},"5":function(depth0,helpers,partials,data) {
  return "style=\"display: none;\"";
  },"7":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, buffer = "        <tr ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.varname : depth0), {"name":"if","hash":{},"fn":this.program(8, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n\n            <th class=\"grid-item-text-left\">";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</th>\n";
  stack1 = helpers.each.call(depth0, (depths[2] != null ? depths[2].response_options : depths[2]), {"name":"each","hash":{},"fn":this.program(10, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </tr>\n        ";
},"8":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "id=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.varname : depth0), depth0))
    + "\"";
},"10":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "            <td class=\"grid-cell\" >\n                ";
  stack1 = __default(require("../js/helpers/partial.js")).call(depth0, "grid-cell-input", {"name":"partial","hash":{
    'answer': ((depths[1] != null ? depths[1].answer : depths[1])),
    'input_class': ((depth0 != null ? depth0.input_class : depth0)),
    'input_width': ((depths[2] != null ? depths[2].input_width : depths[2])),
    'input_type': ((depths[3] != null ? depths[3].input_type : depths[3])),
    'code': ((depth0 != null ? depth0.code : depth0)),
    'category_name': ((depths[2] != null ? depths[2].name : depths[2])),
    'name': (__default(require("../js/helpers/get.js")).call(depth0, (depths[1] != null ? depths[1].response_inputs : depths[1]), (data && data.index), {"name":"get","hash":{},"data":data}))
  },"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n            </td>\n";
},"12":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = " \n\n    <thead>\n        <tr class=\"matrix-row grid-row-"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.category_option : depth0)) != null ? stack1.length : stack1), depth0))
    + "\">\n            <td ";
  stack1 = __default(require("../js/helpers/unlessequal.js")).call(depth0, (depths[1] != null ? depths[1].resp_width : depths[1]), "auto", {"name":"unlessequal","hash":{},"fn":this.program(13, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "            ></td>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.phases : depth0), {"name":"each","hash":{},"fn":this.program(15, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </tr>\n    </thead>\n    <tbody>\n";
  stack1 = helpers.each.call(depth0, (depths[1] != null ? depths[1].response_options : depths[1]), {"name":"each","hash":{},"fn":this.program(19, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </tbody>\n    ";
},"13":function(depth0,helpers,partials,data) {
  return "\n              style=\"width: auto\"\n";
  },"15":function(depth0,helpers,partials,data,depths) {
  var stack1, escapeExpression=this.escapeExpression, buffer = "              "
    + escapeExpression(__default(require("../js/helpers/set.js")).call(depth0, depth0, {"name":"set","hash":{
    'category_index': ((data && data.index))
  },"data":data}))
    + "\n";
  stack1 = helpers.each.call(depth0, depth0, {"name":"each","hash":{},"fn":this.program(16, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"16":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "              <th ";
  stack1 = __default(require("../js/helpers/ifnotphase.js")).call(depth0, (depths[1] != null ? depths[1].phase_pos : depths[1]), (depths[1] != null ? depths[1].category_index : depths[1]), {"name":"ifnotphase","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = __default(require("../js/helpers/ifcond.js")).call(depth0, (depths[2] != null ? depths[2].resp_width : depths[2]), "!=", "auto", {"name":"ifcond","hash":{},"fn":this.program(17, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "                  data-phase=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].category_index : depths[1]), depth0))
    + "\"\n                  scope=\"col\" class=\"";
  stack1 = lambda((depth0 != null ? depth0.varname : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1['0'] : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</th>\n";
},"17":function(depth0,helpers,partials,data,depths) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "\n                  style=\"width: "
    + escapeExpression(lambda((depths[3] != null ? depths[3].resp_width : depths[3]), depth0))
    + ";\"\n";
},"19":function(depth0,helpers,partials,data,depths) {
  var stack1, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "        "
    + escapeExpression(__default(require("../js/helpers/set.js")).call(depth0, depth0, {"name":"set","hash":{
    'response_index': ((data && data.index))
  },"data":data}))
    + "\n        <tr ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.varname : depth0), {"name":"if","hash":{},"fn":this.program(8, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n          <th class=\"grid-item-text-left\" scope=\"row\">\n            ";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n          </th>\n";
  stack1 = helpers.each.call(depth0, (depths[1] != null ? depths[1].phases : depths[1]), {"name":"each","hash":{},"fn":this.program(20, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </tr>\n";
},"20":function(depth0,helpers,partials,data,depths) {
  var stack1, escapeExpression=this.escapeExpression, buffer = "          "
    + escapeExpression(__default(require("../js/helpers/set.js")).call(depth0, depth0, {"name":"set","hash":{
    'category_index': ((data && data.index))
  },"data":data}))
    + "\n";
  stack1 = helpers.each.call(depth0, depth0, {"name":"each","hash":{},"fn":this.program(21, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"21":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "          <td ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.varname : depth0), {"name":"if","hash":{},"fn":this.program(8, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " class=\"grid-cell\" ";
  stack1 = __default(require("../js/helpers/ifnotphase.js")).call(depth0, (depths[1] != null ? depths[1].phase_pos : depths[1]), (depths[1] != null ? depths[1].category_index : depths[1]), {"name":"ifnotphase","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " data-phase=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].category_index : depths[1]), depth0))
    + "\">\n            ";
  stack1 = __default(require("../js/helpers/partial.js")).call(depth0, "grid-cell-input", {"name":"partial","hash":{
    'answer': ((depth0 != null ? depth0.answer : depth0)),
    'input_class': ((depths[2] != null ? depths[2].input_class : depths[2])),
    'input_width': ((depths[4] != null ? depths[4].input_width : depths[4])),
    'input_type': ((depths[4] != null ? depths[4].input_type : depths[4])),
    'code': ((depths[2] != null ? depths[2].code : depths[2])),
    'category_name': ((depth0 != null ? depth0.name : depth0)),
    'name': (__default(require("../js/helpers/get.js")).call(depth0, (depth0 != null ? depth0.response_inputs : depth0), (depths[2] != null ? depths[2].response_index : depths[2]), {"name":"get","hash":{},"data":data}))
  },"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n          </td>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<table id=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "-matrix\" class=\"matrix-layout table-striped\" "
    + escapeExpression(lambda((depth0 != null ? depth0.render : depth0), depth0))
    + "-layout\">\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.transpose : depth0), {"name":"unless","hash":{},"fn":this.program(1, data, depths),"inverse":this.program(12, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " \n</table>\n\n<input type=\"hidden\" name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.id : depth0), depth0))
    + "-row_count\" value=\""
    + escapeExpression(lambda((depth0 != null ? depth0.cat_count : depth0), depth0))
    + "\">\n<input type=\"hidden\" name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.id : depth0), depth0))
    + "-ritem_count\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.response_options : depth0)) != null ? stack1.length : stack1), depth0))
    + "\">";
},"useData":true,"useDepths":true});
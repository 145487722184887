var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '    ', 'error', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "      <tr class=\""
    + escapeExpression(lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "\">\n        <td id=\"orientation_content_"
    + escapeExpression(lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "\" colspan=\"3\">\n          <div class=\"scale-container\">\n            <div class=\"scale-label-left\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1['0'] : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n            <div class=\"scale-options\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.values : depth0), {"name":"each","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "            </div>\n            <div class=\"scale-label-right\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1['1'] : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n          </div>\n        </td>\n      </tr>\n";
  stack1 = helpers['if'].call(depth0, (depths[1] != null ? depths[1].dont_know : depths[1]), {"name":"if","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      <tr class=\"hide\">\n        <td colspan=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].colcount : depths[1]), depth0))
    + "\">\n          <input type=\"hidden\" name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "\" value=\""
    + escapeExpression(__default(require("../js/helpers/ans.js")).call(depth0, (depth0 != null ? depth0.answer : depth0), (depth0 != null ? depth0.name : depth0), {"name":"ans","hash":{},"data":data}))
    + "\" />\n        </td>\n      </tr>\n";
},"4":function(depth0,helpers,partials,data,depths) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "              <div\n                class=\"scale_button scale_cell_value\"\n                id=\"r-"
    + escapeExpression(lambda((depths[1] != null ? depths[1].name : depths[1]), depth0))
    + "-"
    + escapeExpression(lambda(depth0, depth0))
    + "\"\n                onkeydown=\"on_change(event)\"\n                role=\"checkbox\"\n                aria-checked=\"false\"\n                onclick=\"scale_select('"
    + escapeExpression(lambda((depths[1] != null ? depths[1].name : depths[1]), depth0))
    + "', '"
    + escapeExpression(lambda((depths[1] != null ? depths[1].name_dk : depths[1]), depth0))
    + "', "
    + escapeExpression(lambda(depth0, depth0))
    + ")\"\n              >\n                <span tabindex=\"0\">"
    + escapeExpression(lambda(depth0, depth0))
    + "</span>\n              </div>\n";
},"6":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "      <tr class=\"scale_dont_know dk-input\">\n        <td colspan=\""
    + escapeExpression(lambda((depths[2] != null ? depths[2].colcount : depths[2]), depth0))
    + "\">\n          ";
  stack1 = __default(require("../js/helpers/partial.js")).call(depth0, "dk-partial", {"name":"partial","hash":{
    'dk_value': (997),
    'dk_id': ((depth0 != null ? depth0.name_dk : depth0)),
    'dk_text': ((depths[2] != null ? depths[2].dk_text : depths[2]))
  },"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n        </td>\n      </tr>\n";
},"8":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "  <input type=\"hidden\" name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.key : depth0), depth0))
    + "\" value=\""
    + escapeExpression(lambda((depth0 != null ? depth0.value : depth0), depth0))
    + "\" />\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, buffer = "<div class=\"question question-scale\">\n  <div class=\"question-text\">";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  <div class=\"scale_table_container\">\n    <table class=\"scale_table\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.category_options : depth0), {"name":"each","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </table>\n  </div>\n";
  stack1 = __default(require("../js/helpers/eachkeys.js")).call(depth0, (depth0 != null ? depth0.passthru : depth0), {"name":"eachkeys","hash":{},"fn":this.program(8, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>";
},"usePartial":true,"useData":true,"useDepths":true});
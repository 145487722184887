var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '    ', 'error', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '    ', 'error', depth0, {
    'style': ("display: none")
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"5":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "  <div class=\"therm_cont clearfix\">\n    <div class=\"question-text\">";
  stack1 = lambda((depth0 != null ? depth0.qtext : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n    <div class=\"therm_instructions\">";
  stack1 = lambda((depths[1] != null ? depths[1].instructions : depths[1]), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n\n    <div class=\"temperature-indicator-mobile\">\n      <div>Temperature</div>\n      <div>\n        <input\n          class=\"slider-input-mobile\"\n          min=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].therm_min : depths[1]), depth0))
    + "\"\n          max=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].therm_max : depths[1]), depth0))
    + "\"\n          type=\"number\"\n          id=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.resp_key : depth0), depth0))
    + "-mobile\"\n          name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.resp_key : depth0), depth0))
    + "-mobile\"\n          value=\""
    + escapeExpression(__default(require("../js/helpers/answer.js")).call(depth0, (depth0 != null ? depth0.resp_key : depth0), {"name":"answer","hash":{},"data":data}))
    + "\"\n          inputmode=\"numeric\"\n        />\n      </div>\n    </div>\n    <div class=\"therm_middle\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.img : depth0), {"name":"if","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n      <div class=\"therm-container\">\n        <div class=\"levels\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.labels : depth0), {"name":"each","hash":{},"fn":this.program(8, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </div>\n        <div class=\"thermometer\" id=\"option-1\">\n          <div class=\"results\">\n            <div class=\"on\">\n              <div class=\"therm-drag ui-slider-handle\">\n                <input\n                  class=\"slider-input\"\n                  min=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].therm_min : depths[1]), depth0))
    + "\"\n                  max=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].therm_max : depths[1]), depth0))
    + "\"\n                  type=\"number\"\n                  id=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.resp_key : depth0), depth0))
    + "\"\n                  name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.resp_key : depth0), depth0))
    + "\"\n                  value=\""
    + escapeExpression(__default(require("../js/helpers/answer.js")).call(depth0, (depth0 != null ? depth0.resp_key : depth0), {"name":"answer","hash":{},"data":data}))
    + "\"\n                  inputmode=\"numeric\"\n                />\n                <div class=\"slider-indicator\">\n                  <div class=\"slider-indicator-line\"></div>\n                  <div class=\"slider-indicator-dot\"></div>\n                </div>\n              </div>\n            </div>\n            <div class=\"off\"></div>\n          </div>\n          <div class=\"top-rounded\"></div>\n        </div>\n      </div>\n      ";
  stack1 = helpers['if'].call(depth0, (depths[1] != null ? depths[1].dk : depths[1]), {"name":"if","hash":{},"fn":this.program(10, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n  </div>\n";
},"6":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "      <div class=\"therm_img\"><img src=\""
    + escapeExpression(lambda((depth0 != null ? depth0.img : depth0), depth0))
    + "\" alt=\"\" border=\"0\" /></div>\n";
},"8":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "          <div id=\"val-"
    + escapeExpression(lambda((data && data.key), depth0))
    + "\" class=\"level\">\n            <div class=\"level-label\">"
    + escapeExpression(lambda(depth0, depth0))
    + "</div>\n            <div class=\"level-degrees\">"
    + escapeExpression(lambda((data && data.key), depth0))
    + "°</div>\n          </div>\n";
},"10":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = " ";
  stack1 = __default(require("../js/helpers/partial.js")).call(depth0, "dk-partial", {"name":"partial","hash":{
    'index': ((data && data.index)),
    'dk_id': ((depth0 != null ? depth0.dk_resp : depth0)),
    'dk_value': (997),
    'response_id': ((depth0 != null ? depth0.response_id : depth0)),
    'dk_text': ((depths[2] != null ? depths[2].dk_text : depths[2]))
  },"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "<div class=\"question question-thermometer\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.program(3, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.categories : depth0), {"name":"each","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});
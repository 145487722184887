var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "\n    <div class=\"validation-result alert alert-error\">"
    + escapeExpression(((helper = (helper = helpers.error_message || (depth0 != null ? depth0.error_message : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"error_message","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "<div class=\"registration-widget\">";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  <h5>";
  stack1 = ((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</h5>\n  <input\n    type=\"text\"\n    id=\"r-";
  stack1 = ((helper = (helper = helpers.email_id || (depth0 != null ? depth0.email_id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"email_id","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n    name=\"";
  stack1 = ((helper = (helper = helpers.email_id || (depth0 != null ? depth0.email_id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"email_id","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n    placeholder=\"";
  stack1 = ((helper = (helper = helpers.placeholder_email || (depth0 != null ? depth0.placeholder_email : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"placeholder_email","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n    class=\"registration-email-input\"\n  />\n  <div class=\"terms-agree-wrap\">\n    <input\n      type=\"checkbox\"\n      id=\"r-";
  stack1 = ((helper = (helper = helpers.terms_and_conds_id || (depth0 != null ? depth0.terms_and_conds_id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"terms_and_conds_id","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n      name=\"";
  stack1 = ((helper = (helper = helpers.terms_and_conds_id || (depth0 != null ? depth0.terms_and_conds_id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"terms_and_conds_id","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n      class=\"registration-tos-agree\"\n    />\n    <span>\n      <label for=\"r-";
  stack1 = ((helper = (helper = helpers.terms_and_conds_id || (depth0 != null ? depth0.terms_and_conds_id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"terms_and_conds_id","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n        ";
  stack1 = ((helper = (helper = helpers.terms_and_conditions_text || (depth0 != null ? depth0.terms_and_conditions_text : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"terms_and_conditions_text","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</label>\n    </span>\n  </div>\n  <div class=\"join-btn-wrap\">\n    <button\n      id=\"join_button\"\n      class=\"btn-join\"\n      type=\"button\"\n    >\n    <span class=\"button-text\">";
  stack1 = ((helper = (helper = helpers.join_button_text || (depth0 != null ? depth0.join_button_text : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"join_button_text","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</span>\n    <div class=\"loader-button\">\n      <svg class=\"circular\" viewBox=\"25 25 50 50\">\n        <circle class=\"path\" cx=\"50\" cy=\"50\" r=\"20\" fill=\"none\" stroke-width=\"7\" stroke-miterlimit=\"10\"/>\n      </svg>\n    </div>\n    </button>\n  </div>\n</div>";
},"useData":true});
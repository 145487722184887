module.exports = function (name, value, options) {
  if (
    // If it's a regular single code grid, and the result is
    // the given value.
    getAnswer(name) === String(value) ||
    getAnswer(name) === 'on' ||
    getAnswer(name + '-' + String(value))
  ) {
    return options.fn(this);
  }

  return options.inverse(this);
};

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '    ', 'error', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "    <div class=\"dyngrid-phase dyngrid-phase-hidden\" data-phase=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">\n      <div class=\"dyngrid-category-text\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1['0'] : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n      <div class=\"question-response-list\">\n        ";
  stack1 = helpers.each.call(depth0, (depths[1] != null ? depths[1].response_options : depths[1]), {"name":"each","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </div>\n    </div>\n";
},"4":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = " ";
  stack1 = __default(require("../js/helpers/partial.js")).call(depth0, "response-button", {"name":"partial","hash":{
    'answer': ((depths[1] != null ? depths[1].answer : depths[1])),
    'group': ((depths[1] != null ? depths[1].name : depths[1])),
    'input_class': ((depth0 != null ? depth0.input_class : depth0)),
    'text': ((depth0 != null ? depth0.text : depth0)),
    'input_name': (__default(require("../js/helpers/get.js")).call(depth0, (depths[1] != null ? depths[1].response_inputs : depths[1]), (data && data.index), {"name":"get","hash":{},"data":data})),
    'input_type': ((depths[2] != null ? depths[2].input_type : depths[2])),
    'code': ((depth0 != null ? depth0.code : depth0))
  },"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"6":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "<input type=\"hidden\" name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.key : depth0), depth0))
    + "\" value=\""
    + escapeExpression(lambda((depth0 != null ? depth0.value : depth0), depth0))
    + "\" />\n";
},"8":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "\n<div class=\"dk-center\">\n  <input type=\"checkbox\" class=\"dk-check\" />\n  <span class=\"dk-label\"\n    ><label for=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "-dk\">";
  stack1 = lambda((depth0 != null ? depth0.dk_text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</label></span\n  >\n</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"question question-"
    + escapeExpression(lambda((depth0 != null ? depth0.type : depth0), depth0))
    + "\">\n  <div class=\"question-text\">";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  <div class=\"dyngrid\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.category_options : depth0), {"name":"each","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  </div>\n</div>\n\n";
  stack1 = __default(require("../js/helpers/eachkeys.js")).call(depth0, (depth0 != null ? depth0.passthru : depth0), {"name":"eachkeys","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.dk : depth0), {"name":"if","hash":{},"fn":this.program(8, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true,"useDepths":true});
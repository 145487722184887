class Localization {
  locale = 'en';
  /*
    Object in format of
      {
        es: { '...string...': '...localizedString...' },
        de: { '...string...': '...localizedString...' },
      }
  */
  localizedStrings = {};

  setLocale(locale) {
    window.locale = this.locale = locale;
  }

  fetchLocalizations(locale) {
    if (this.localizedStrings[locale]) return;

    $.ajax({
      type: 'get',
      global: false, // Don't trigger $.ajaxError
      url: `${process.env.API_BASE_URL}interface_translations/${locale}`,
      success: (localized) => {
        const localizedString = localized.reduce((acc, obj) => {
          const str = Object.keys(obj)[0];
          return {
            ...acc,
            [str]: obj[str],
          };
        }, {});

        this.localizedStrings = {
          ...this.localizedStrings,
          [locale]: localizedString,
        };
      },
    });
  }

  localize(string) {
    if (this.localizedStrings[this.locale]) {
      return this.localizedStrings[this.locale][string];
    }

    return string;
  }
}

export default new Localization();

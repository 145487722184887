(function (widgets) {
  // TODO :: Deprecate
  window.collisionDetect = function (helper) {
    var posX = window.pageX;
    var dropper_right_bound =
      $('#placement-drop').offset().left + $('#placement-drop').width();
    if ($(helper).text().length >= 40) {
      $(helper).addClass('text-wrap');
    }
    if (
      (posX &&
        $('#placement-drop').width() - posX - 6 > 0 &&
        posX >= dropper_right_bound - $(helper).width()) ||
      posX >= dropper_right_bound - $(helper).find('span').width() ||
      posX >= dropper_right_bound - $(helper).find('img').width() ||
      posX >=
        dropper_right_bound - $(helper).find('.placement-pointer-text').width()
    ) {
      $(helper).addClass('collision-right');
    } else {
      $(helper).removeClass('collision-right');
    }
  };

  var Placement = widgets.Widget.extend(
    {
      constructor: function PlacementConstructor(object) {
        Placement.__super__.constructor.call(this, object);
        this._placementOptions = {};
        var DECIMAL_PRECISION = function (range) {
          var max = range.split(',')[1],
            l = max.split(object.dec_sep);
          if (l.length > 1) {
            return l[1].length;
          } else {
            return 0;
          }
        };

        $.extend(this._placementOptions, {
          narrow_view: object.narrow_view || false,
          wide_view: object.wide_view || false,
          left_message: object.left_message || '',
          right_message: object.right_message || '',
          left_message_ordered: object.left_message_ordered || '',
          right_message_ordered: object.right_message_ordered || '',
          range: object.range || '0,100',
          value_max: object.range.split(',')[1].trim() || parseInt(100),
          value_min: object.range.split(',')[0].trim() || parseInt(0),
          show_value: object.show_value,
          decimal_precision: DECIMAL_PRECISION(object.range),
          accesibility: true,
        });
      },

      render: function render() {
        Placement.__super__.render.call(this);
        $(document).on('mousemove', function (e) {
          window.pageX = e.pageX;
          window.pageY = e.pageY;
        });
        $(document).placement({
          data_cls: '.placement-values',
          drag_cls: '.placement-item',
          pointer_cls: '.placement-indicator',
          dropzone: '.placement-drop',
          slider_id: '.placement-slider',
          container_id: '.placement-outer',
          options: this._placementOptions,
        });
      },
    },
    {
      types: ['placement'],
      views: ['placement'],
    }
  );

  Placement.register();
  widgets.Placement = Placement;
})((Gryphon.widgets = Gryphon.widgets || {}));

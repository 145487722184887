var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "<div class=\"openend-subtext-cont\">\n";
  stack1 = this.invokePartial(require("./open_entry.hbs"), '  ', 'open_entry', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "  <div class=\"openend-subtext-message\">";
  stack1 = lambda((depth0 != null ? depth0.subtext : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n</div>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = " ";
  stack1 = this.invokePartial(require("./open_entry.hbs"), '', 'open_entry', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + " ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.subtext : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"usePartial":true,"useData":true});
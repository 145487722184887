var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "  <div class=\"question-text\">";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '    ', 'error', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var stack1, buffer = " ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.color : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.program(10, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " ";
},"5":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "\n      <style>\n        /* Sets up user-specified colors which do not belong to our default set of \"supported\" colors */\n        /*\n          NOTE: We are using !important here, because user-defined colors should ALWAYS take priority\n          over system-defined colors\n        */\n\n";
  stack1 = __default(require("../js/helpers/startsWith.js")).call(depth0, (depth0 != null ? depth0.color : depth0), "#", {"name":"startsWith","hash":{},"fn":this.program(6, data),"inverse":this.program(8, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "      </style>\n      <li\n        class=\"text-highlight-marker text-highlight-marker-c"
    + escapeExpression(lambda((data && data.index), depth0))
    + " text-highlight-marker-";
  stack1 = lambda((depth0 != null ? depth0.color : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\"\n        tabindex=\"0\"\n        onkeydown=\"on_change(event)\"\n        role=\"radio\"\n        aria-checked=\"false\"\n        data-marker-code=\""
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\"\n        data-marker-index=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\"\n        data-marker-label=\"c"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\"\n        data-marker-color=\""
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + "\"\n        data-marker-max=\""
    + escapeExpression(lambda((depth0 != null ? depth0.max_selections : depth0), depth0))
    + "\"\n        data-marker-min=\""
    + escapeExpression(lambda((depth0 != null ? depth0.min_selections : depth0), depth0))
    + "\"\n      >\n        <span\n          class=\"text-highlight-marker-dot text-highlight-marker-dot-c"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\"\n        ></span>\n        <i\n          class=\"text-highlight-marker-icon text-highlight-marker-icon-c"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\"\n        >\n          <svg\n            width=\"18px\"\n            height=\"18px\"\n            viewBox=\"0 0 18 18\"\n            version=\"1.1\"\n            xmlns=\"http://www.w3.org/2000/svg\"\n            xmlns:xlink=\"http://www.w3.org/1999/xlink\"\n          >\n            <title>Group 3</title>\n            <g\n              id=\"05-Image-Highlight"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\"\n              stroke=\"none\"\n              stroke-width=\"1\"\n              fill=\"none\"\n              fill-rule=\"evenodd\"\n            >\n              <g\n                id=\"05-Image-Highlight"
    + escapeExpression(lambda((data && data.index), depth0))
    + "----Mobile\"\n                transform=\"translate(-158.000000, -236.000000)\"\n              >\n                <g\n                  id=\"Group-3"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\"\n                  transform=\"translate(158.000000, 237.000000)\"\n                >\n                  <path\n                    d=\"M12.3636364,12.7866915 L13.9090909,12.7866915 L13.9090909,4.63636364 C13.9090909,3.78636364 13.2136364,3.09090909 12.3636364,3.09090909 L4.185252,3.09090909 L4.185252,4.63636364 L12.3636364,4.63636364 L12.3636364,12.7866915 Z M4.63636364,12.3636364 L4.63636364,0 L3.09090909,0 L3.09090909,3.09090909 L0,3.09090909 L0,4.63636364 L3.09090909,4.63636364 L3.09090909,12.3636364 C3.09090909,13.2136364 3.78636364,13.9090909 4.63636364,13.9090909 L12.3636364,13.9090909 L12.3636364,17 L13.9090909,17 L13.9090909,13.9090909 L17,13.9090909 L17,12.3636364 L4.63636364,12.3636364 Z\"\n                    id=\"Shape"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\"\n                    fill=\"#433c54\"\n                    fill-rule=\"nonzero\"\n                  ></path>\n                  <line\n                    x1=\"4.63636364\"\n                    y1=\"12.3636364\"\n                    x2=\"17\"\n                    y2=\"-1.13686838e-13\"\n                    id=\"Path-2"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\"\n                    stroke=\"#433c54\"\n                  ></line>\n                </g>\n              </g>\n            </g>\n          </svg>\n        </i>\n        <!-- prettier-ignore -->\n        <p class=\"text-highlight-marker-caption text-highlight-marker-caption-c"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">"
    + escapeExpression(lambda((depth0 != null ? depth0.text : depth0), depth0))
    + "</p>\n      </li>\n";
},"6":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "        /* Escape hex values */\n        .text-highlight-marker.text-highlight-marker-\\"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " {\n          border: 1px solid "
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " !important;\n          color: "
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " !important;\n        }\n        .text-highlight-marker.text-highlight-marker-\\"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " svg,\n        .text-highlight-marker.text-highlight-marker-\\"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " svg path {\n          fill: "
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " !important;\n        }\n        .text-highlight-marker.text-highlight-marker-\\"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " svg line {\n          stroke: "
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " !important;\n        }\n        .text-highlight-marker.text-highlight-marker-\\"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + ":hover,\n        .text-highlight-marker.text-highlight-marker-\\"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + ".text-highlight-selected {\n          background: "
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " !important;\n          border: 1px solid "
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " !important;\n          color: #fff !important;\n        }\n        .text-highlight-marker.text-highlight-marker-\\"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + ":hover svg,\n        .text-highlight-marker.text-highlight-marker-\\"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + ":hover svg path,\n        .text-highlight-marker.text-highlight-marker-\\"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + ".text-highlight-selected svg,\n        .text-highlight-marker.text-highlight-marker-\\"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + ".text-highlight-selected svg path {\n          fill: #fff !important;\n        }\n        .text-highlight-marker.text-highlight-marker-\\"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + ":hover svg line,\n        .text-highlight-marker.text-highlight-marker-\\"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + ".text-highlight-selected svg line {\n          stroke: #fff !important;\n        }\n";
},"8":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "        .text-highlight-marker.text-highlight-marker-"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " {\n          border: 1px solid "
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " !important;\n          color: "
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " !important;\n        }\n        .text-highlight-marker.text-highlight-marker-"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " svg,\n        .text-highlight-marker.text-highlight-marker-"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " svg path {\n          fill: "
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " !important;\n        }\n        .text-highlight-marker.text-highlight-marker-"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " svg line {\n          stroke: "
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " !important;\n        }\n        .text-highlight-marker.text-highlight-marker-"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + ":hover,\n        .text-highlight-marker.text-highlight-marker-"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + ".text-highlight-selected {\n          background: "
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " !important;\n          border: 1px solid "
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " !important;\n          color: #fff !important;\n        }\n        .text-highlight-marker.text-highlight-marker-"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + ":hover svg,\n        .text-highlight-marker.text-highlight-marker-"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + ":hover svg path,\n        .text-highlight-marker.text-highlight-marker-"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + ".text-highlight-selected svg,\n        .text-highlight-marker.text-highlight-marker-"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + ".text-highlight-selected svg path {\n          fill: #fff !important;\n        }\n        .text-highlight-marker.text-highlight-marker-"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + ":hover svg line,\n        .text-highlight-marker.text-highlight-marker-"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + ".text-highlight-selected svg line {\n          stroke: #fff !important;\n        }\n";
},"10":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "      <li\n        class=\"text-highlight-marker text-highlight-marker-c"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\"\n        tabindex=\"0\"\n        onkeydown=\"on_change(event)\"\n        role=\"radio\"\n        aria-checked=\"false\"\n        data-marker-code=\""
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\"\n        data-marker-index=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\"\n        data-marker-label=\"c"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\"\n        data-marker-color\n        data-marker-max=\""
    + escapeExpression(lambda((depth0 != null ? depth0.max_selections : depth0), depth0))
    + "\"\n        data-marker-min=\""
    + escapeExpression(lambda((depth0 != null ? depth0.min_selections : depth0), depth0))
    + "\"\n      >\n        <span\n          class=\"text-highlight-marker-dot text-highlight-marker-dot-c"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\"\n        ></span>\n        <i\n          class=\"text-highlight-marker-icon text-highlight-marker-icon-c"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\"\n        >\n          <svg\n            width=\"18px\"\n            height=\"18px\"\n            viewBox=\"0 0 18 18\"\n            version=\"1.1\"\n            xmlns=\"http://www.w3.org/2000/svg\"\n            xmlns:xlink=\"http://www.w3.org/1999/xlink\"\n          >\n            <title>Group 3</title>\n            <g\n              id=\"05-Image-Highlight"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\"\n              stroke=\"none\"\n              stroke-width=\"1\"\n              fill-rule=\"evenodd\"\n            >\n              <g\n                id=\"05-Image-Highlight"
    + escapeExpression(lambda((data && data.index), depth0))
    + "----Mobile\"\n                transform=\"translate(-158.000000, -236.000000)\"\n              >\n                <g\n                  id=\"Group-3"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\"\n                  transform=\"translate(158.000000, 237.000000)\"\n                >\n                  <path\n                    d=\"M12.3636364,12.7866915 L13.9090909,12.7866915 L13.9090909,4.63636364 C13.9090909,3.78636364 13.2136364,3.09090909 12.3636364,3.09090909 L4.185252,3.09090909 L4.185252,4.63636364 L12.3636364,4.63636364 L12.3636364,12.7866915 Z M4.63636364,12.3636364 L4.63636364,0 L3.09090909,0 L3.09090909,3.09090909 L0,3.09090909 L0,4.63636364 L3.09090909,4.63636364 L3.09090909,12.3636364 C3.09090909,13.2136364 3.78636364,13.9090909 4.63636364,13.9090909 L12.3636364,13.9090909 L12.3636364,17 L13.9090909,17 L13.9090909,13.9090909 L17,13.9090909 L17,12.3636364 L4.63636364,12.3636364 Z\"\n                    id=\"Shape"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\"\n                  ></path>\n                  <line\n                    x1=\"4.63636364\"\n                    y1=\"12.3636364\"\n                    x2=\"17\"\n                    y2=\"-1.13686838e-13\"\n                    id=\"Path-2"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\"\n                  ></line>\n                </g>\n              </g>\n            </g>\n          </svg>\n        </i>\n        <!-- prettier-ignore -->\n        <p class=\"text-highlight-marker-caption text-highlight-marker-caption-c"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">"
    + escapeExpression(lambda((depth0 != null ? depth0.text : depth0), depth0))
    + "</p>\n      </li>\n      ";
},"12":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "        <!-- prettier-ignore -->\n        <span\n          id=\""
    + escapeExpression(lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "\"\n          class=\"highlight-response\"\n          data-widget-id=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].element_id : depths[1]), depth0))
    + "\"\n          data-name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "\"\n          data-varname=\""
    + escapeExpression(lambda((depth0 != null ? depth0.varname : depth0), depth0))
    + "\"\n          data-selectable=\""
    + escapeExpression(lambda((depth0 != null ? depth0.selectable : depth0), depth0))
    + "\"\n          >";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1['0'] : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + " </span>\n        <input\n          id=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "\"\n          class=\"image-highlight-input\"\n          type=\"hidden\"\n          name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "\"\n          value=\""
    + escapeExpression(__default(require("../js/helpers/answer.js")).call(depth0, (depth0 != null ? depth0.name : depth0), (depth0 != null ? depth0.code : depth0), {"name":"answer","hash":{},"data":data}))
    + "\"\n        />\n";
},"14":function(depth0,helpers,partials,data) {
  var stack1, buffer = " ";
  stack1 = __default(require("../js/helpers/partial.js")).call(depth0, "dk-partial", {"name":"partial","hash":{
    'index': ((data && data.index)),
    'dk_id': ((depth0 != null ? depth0.dk_id : depth0)),
    'dk_value': (997),
    'dk_text': ((depth0 != null ? depth0.dont_know : depth0))
  },"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " ";
},"16":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "            <th scope=\"col\" id=\"label_c"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">"
    + escapeExpression(lambda((depth0 != null ? depth0.text : depth0), depth0))
    + "</th>\n";
},"18":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "            <td headers=\"label_c"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">\n              <textarea\n                rows=\"6\"\n                cols=\"40\"\n                class=\"input textarea\"\n                name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_name : depth0), depth0))
    + "\"\n              ></textarea>\n            </td>\n";
},"20":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "    <input type=\"hidden\" name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.key : depth0), depth0))
    + "\" value=\""
    + escapeExpression(lambda((depth0 != null ? depth0.value : depth0), depth0))
    + "\" />\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "<div class=\"question question-text-highlight text-highlight\">\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.read_only : depth0), {"name":"unless","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  <div class=\"text-highlight-widget text-highlight-block\">\n    <ul\n      class=\"text-highlight-markers text-highlight-gradient\"\n      role=\"radiogroup\"\n      aria-labelledby=\"text-highlight\"\n    >\n      ";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.highlights : depth0), {"name":"each","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    </ul>\n    <div class=\"text-highlight-board\">\n      <div class=\"inner\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.category_options : depth0), {"name":"each","hash":{},"fn":this.program(12, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "      </div>\n    </div>\n    ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.dk : depth0), {"name":"if","hash":{},"fn":this.program(14, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    <div class=\"answers answers-table\" style=\"display: none\">\n      <table class=\"grid grid-table-mode\">\n        <tbody>\n          <tr>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.highlights : depth0), {"name":"each","hash":{},"fn":this.program(16, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "          </tr>\n        </tbody>\n        <tbody>\n          <tr>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.highlights : depth0), {"name":"each","hash":{},"fn":this.program(18, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "          </tr>\n        </tbody>\n      </table>\n    </div>\n";
  stack1 = __default(require("../js/helpers/eachkeys.js")).call(depth0, (depth0 != null ? depth0.passthru : depth0), {"name":"eachkeys","hash":{},"fn":this.program(20, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});
(function ($, defaults) {
  $.extend(defaults, {
    actionClass: 'uniform-action',
    activeClass: 'uniform-active',
    buttonClass: 'uniform-button',
    checkboxClass: 'uniform-checker',
    checkedClass: 'uniform-checked',
    disabledClass: 'uniform-disabled',
    inputClass: 'uniform-input',
    filenameClass: 'uniform-filename',
    focusClass: 'uniform-focus',
    hoverClass: 'uniform-hover',
    multiselectClass: 'uniform-multiselect',
    radioClass: 'uniform-radio',
    selectClass: 'uniform-selector',
    uploadClass: 'uniform-uploader',
    textareaClass: 'uniform-textarea',
  });
})(jQuery, jQuery.uniform.defaults);

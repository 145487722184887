import '../../../vendor/video-dial/lib/video.modified.js';
import '../../../vendor/video-dial/requirement/html-canvas-video-player/js/canvas-video-player.js';
(function (widgets) {
  var Video = widgets.Widget.extend(
    {
      render: function render() {
        Video.__super__.render.call(this);
        this._render();
      },

      destroy: function destroy() {
        Video.__super__.destroy.call(this);
        this._logEvent({
          event: 'nextQuestion',
          message: 'Next question',
        });
        if (this.player && typeof this.player.dispose === 'function') {
          this.player.dispose();
        }
      },

      _render: function () {
        var object = this._object;
        var plays = object.plays;
        var autoplay = object.player.autoplay || false;
        var total_plays = 0;
        var watchedVal = page_state.form_data[object.watched_input_id] || '0';
        var watchedInput = $('input[name="' + object.watched_input_id + '"]');
        var self = this;

        var iOSVersion,
          iOS =
            /iPad|iPhone|iPod/.test(navigator.platform) ||
            (/iPad|iPhone|iPod/.test(navigator.userAgent) &&
              !/Windows/.test(navigator.userAgent)),
          isChrome = (function () {
            var check = false;
            (function (a) {
              if (/crios|chrome/gi.test(a)) {
                check = true;
              }
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
          })();

        if (iOS) {
          iOSVersion = (function () {
            var match = navigator.userAgent.match(/OS (\d+)_/i);
            if (match && match[1]) {
              return parseInt(match[1]);
            }
          })();
        }
        var useCanvasVideoPlayer =
          (iOS && iOSVersion < 10) || (iOS && iOSVersion >= 10 && isChrome);

        var setCanvasAndDialDimensions = function () {
          setTimeout(function () {
            var orientation =
              window.screen.height > window.screen.width
                ? 'portrait'
                : 'landscape';
            if (
              orientation === 'landscape' ||
              ($('#player').width() > $('canvas').width() &&
                $('canvas').width() > 0)
            ) {
              // Orientation :: Landscape
              // set the dial element's container to
              // the width of the canvas element
              if ($(window).height() - 160 < 300) {
                $('canvas').height($(window).height() - 160);
              } else {
                $('canvas').height(300);
              }
              $('canvas').width($('canvas').height() * 1.7);

              $('#player').width($('canvas').width());
              $('#player').height($('canvas').height());
            } else {
              // Orientation :: Portrait
              // set the canvas width/height
              $('canvas').width($('#player').width());
              $('canvas').height($('canvas').width() / 1.7);

              $('#player').height($('canvas').height());
            }
          });
        };

        watchedInput.val(watchedVal);

        videojs.options.flash.swf = '/static/video/video-js.swf';
        videojs.options.techOrder = ['html5', 'flash'];

        this._has_flash = Gryphon.util.has_flash();

        /**
         * Very naive function to guess the playback tech used.
         * Must only be called once the player is ready to have
         * any hope of giving the right answer.
         */
        function getTech() {
          return $('video').length ? 'html5' : 'flash';
        }

        // Note: To be used with iOS / autoplay
        // Show the mute button so users may
        // unmute the playing video
        // Ref https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
        function toggleMuteButton() {
          var checkVolumeCtrl, checkMuteCtrl;
          $('.vjs-control-bar').show().css({
            'background-color': 'transparent',
            visibility: 'visible',
            opacity: 1,
          });
          setTimeout(function () {
            $('.vjs-control').not('.vjs-mute-control').addClass('vjs-hidden');
            checkVolumeCtrl = setInterval(function () {
              if ($('.vjs-volume-control').is(':visible')) {
                clearInterval(checkVolumeCtrl);
                $('.vjs-volume-control').addClass('vjs-hidden');
              }
            }, 100);
            if (!$('.vjs-mute-control').hasClass('vjs-vol-0')) {
              $('.vjs-mute-control')
                .addClass('vjs-vol-0')
                .css({
                  'font-size': '14px',
                  bottom: '12px',
                  right: '-10px',
                })
                .removeClass('vjs-hidden');
            }
            checkMuteCtrl = setInterval(function () {
              if ($('.vjs-mute-control').hasClass('vjs-hidden')) {
                window.console.log(true);
                $('.vjs-mute-control').removeClass('vjs-hidden');
                clearInterval(checkMuteCtrl);
              }
            }, 100);
          });
        }

        function initializePlayer(player) {
          self._logEvent({ event: 'initializePlayer' });
          if (iOS && autoplay) {
            toggleMuteButton();
          }

          player.on('error', function (event) {
            // [SSTSD-1409 / GRYP-8242] Fallback to other media sources
            if (player.error().code === 4) {
              self._logEvent({
                event: 'error',
                message:
                  '[MEDIA_ERR_SRC_NOT_SUPPORTED] The associated resource has been ' +
                  'found to be unsuitable',
              });
              // Fallback to next source
              var sources = object.player.sources.filter(
                (source) => source.src !== player.currentSrc()
              );
              if (sources.length > 0) {
                player.src(sources);
                return;
              } else {
                self._logEvent({
                  event: 'error',
                  message: 'No supported media sources found',
                });
              }
            } else {
              self._logEvent({
                event: 'error',
                message: event.message || 'Unknown',
              });
            }

            // skips to next question with response code ERROR

            $('#video-error').val(object.response_codes.ERROR);
            mainNav.show();
            $('#next_button').click();
          });

          player.on('ended', function () {
            watchedInput.val(object.response_codes.WATCHED);

            total_plays++;
            self._logEvent({
              event: 'ended',
              widget: 'video',
              totalPlays: total_plays,
            });

            if (plays > 0 && total_plays >= plays) {
              $('#next_button').click();
              return;
            }

            try {
              player.currentTime(0);
              player.pause();

              player.bigPlayButton.one('click', function () {
                // Make doubly sure we're at the beginning before
                // starting playback.
                player.currentTime(0);
                player.play();
              });
            } catch (e) {}

            if (object.hide_buttons) {
              mainNav.show();
            }
          });

          var otherEvents = [
            'loadstart',
            'loadedmetadata',
            'loadeddata',
            'loadedalldata',
          ];

          $.each(otherEvents, function (i, eventName) {
            var logEvent = $.proxy(self, '_logEvent', {
              event: eventName,
            });
            player.on(eventName, logEvent);
          });

          player.on('play', function () {
            self._logEvent({ event: 'play', widget: 'video' });
          });

          player.on('pause', function () {
            self._logEvent({ event: 'pause', widget: 'video' });
          });

          player.on('', function () {
            self._logEvent({ event: '' });
          });

          // If video isn't playable
          if (player.currentSrc() === '') {
            player.trigger({ type: 'error', message: 'No source' });
          }

          if (useCanvasVideoPlayer) {
            var canvasVideo,
              _player = player;

            window.addEventListener(
              'orientationchange',
              function () {
                setTimeout(function () {
                  setCanvasAndDialDimensions();
                });
              },
              false
            );

            if (iOSVersion >= 8) {
              $('.video-js-canvas-wrapper').show();
              canvasVideo = new CanvasVideoPlayer({
                videoSelector: '.video-js video',
                canvasSelector: '.video-js-canvas',
                audio: '#audioplayer',
                autoplay: autoplay,
              });
              canvasVideo.pausedByUser = true;
              $('.video-js-canvas').show();
              $('.video-js').css({
                background: 'transparent',
                width: '100%',
                position: 'absolute',
                top: '0px',
                left: '0px',
                'z-index': '999',
              });
              $('.video-js video').attr('muted', 'muted');
              $('.vjs-big-play-button, .video-js').css({
                'pointer-events': 'none',
              });
              $('.vjs-loading-spinner').css('visibility', 'hidden');
              document.getElementById(canvasVideo.canvas.id).addEventListener(
                'touchstart',
                function () {
                  var icon = $('#touchPlayBtn').find('.fa');

                  player = canvasVideo;
                  if (player.playing) {
                    player.pausedByUser = true;

                    if (isChrome && iOSVersion >= 10) {
                      player.video.pause();
                    } // for Chrome iOS

                    player.pause();
                    $('#touchPlayBtn').fadeIn('fast');
                    $('.vjs-big-play-button').fadeIn('fast');
                  } else {
                    player.pausedByUser = false;

                    // for Chrome iOS
                    var toggleIconInt;
                    if (
                      isChrome &&
                      iOSVersion >= 10 &&
                      player.video.readyState < 2
                    ) {
                      var conf = confirm('Begin video playback?');
                      if (conf) {
                        player.video.play();
                        setTimeout(function () {
                          player.video.pause();
                        });

                        toggleIconInt = setInterval(function () {
                          if ($('#touchPlayBtn').is(':visible')) {
                            $('#touchPlayBtn').hide();
                            clearInterval(toggleIconInt);
                          }
                        }, 300);
                      }
                    }

                    player.play();
                    $('#touchPlayBtn').fadeOut('fast');
                    $('.vjs-big-play-button').fadeOut('fast');
                  }
                },
                false
              );

              if (canvasVideo && canvasVideo.video) {
                canvasVideo.video.addEventListener('timeupdate', function (e) {
                  if (!canvasVideo.playing && !canvasVideo.pausedByUser) {
                    _player.trigger('ended');
                  }
                });
              }

              setCanvasAndDialDimensions();
            }
          }

          var allowPause = true; // TODO: Make an option in video widget for this (like video-dial)
          if (allowPause) {
            // allow play/pause onclick on video
            $('.video-js video').on('click', function () {
              if (player.paused()) {
                player.play();
              } else {
                player.pause();
              }
            });
          }
        }

        if (object.hide_buttons) {
          mainNav.hide();
        }

        self._logEvent({
          event: 'Template loaded',
          videoSettings: object.player,
        });

        if (iOS && autoplay) {
          // By default, iOS devices must have the video muted in order to autoplay
          $('#player').attr('muted', 'muted');
        }

        var player = (this.player = videojs('player', {
          preload: 'auto',
        }));

        player.ready(function () {
          player._isReady = true;
          player._techUsed = getTech();
          self._logEvent({ event: 'playerReady' });
        });
        initializePlayer(player);
      },

      _logEvent: function _logEvent(data) {
        /* jshint devel: true */
        if (!window.eventLog) {
          return;
        }

        var player = this.player;

        try {
          data = data || {};

          data.application = 'gryphon.ivw';
          data.date = Date();
          data.userAgent = navigator.userAgent;
          data.questionType = 'video';
          data.playerIsReady = false;
          data.url = window.location.href;
          data.has_flash = this._has_flash;

          if (player && player._isReady) {
            data.playerIsReady = true;
            data.currentSrc = player.currentSrc();
            data.currentTime = player.currentTime();
            data.duration = player.duration();
            data.tech = player._techUsed;
          }

          eventLog.log(data);
        } catch (err) {
          console.log(err);
        }
      },
    },
    {
      types: ['video'],
      views: ['video'],
    }
  );

  Video.register();
  widgets.Video = Video;
})((Gryphon.widgets = Gryphon.widgets || {}));

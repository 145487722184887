(function ($) {
  var ResponseButton = {
    options: {
      color: 'teal',
      defaultColor: 'default',
      onChange: $.noop,
    },

    _create: function () {
      var el = this.element;
      var subject = this;

      this.input = el.find('.response-button-input');
      this.input.data('responsebutton-instance', this);
      this.textInput = el.find('input[type=text]');

      this.textInput.click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        subject.check();
      });

      this.xor = this._getXor();
      this.group = this.input.data('group');
      this.isChecked = false;

      // TODO: bind to the `change` event instead
      var onClick = $.proxy(this, '_onClick');
      el.on('click keypress', onClick);
    },

    _init: function () {
      // We must read state from the DOM to initialise correctly.
      if (this.input.is(':checked')) {
        this._check();
      }
    },

    _getXor: function () {
      return this.input.hasClass('xor') || this.input.attr('type') === 'radio';
    },

    check: function () {
      this._check();
      this._uncheckExclusiveSiblings();
    },

    uncheck: function () {
      this._setColor(this.options.defaultColor, this.options.color);
      this.isChecked = false;
      this.input.prop('checked', false);
    },

    toggle: function () {
      if (this.isChecked) {
        this.uncheck();
      } else {
        this.check();
      }
      this.input.parent('.response-button').trigger('blur');
    },

    disable: function () {
      this._ignore = true;
      this.input.prop('disabled', true);
      this.element.addClass('disabled');
    },

    enable: function () {
      this._ignore = false;
      this.input.prop('disabled', false);
      this.element.removeClass('disabled');
    },

    /**
     * Hyphenate any inner text using soft hyphens, while preserving any inner
     * HTML structure, so that the text doesn't overflow or clip the button.
     */
    hyphenate: function () {
      this.element.shyphen();
    },

    _onClick: function (event) {
      global.disable_dk_check();
      if (
        event &&
        event.type === 'keypress' &&
        event.key !== ' ' &&
        event.key !== 'Enter'
      ) {
        return;
      }
      if (
        event &&
        event.type === 'keypress' &&
        event.key === ' ' &&
        event.target.tagName.toLowerCase() === 'input'
      ) {
        return;
      }
      if ($(event.target).parents('.kid-widget').length < 1) {
        if (!this._ignore) {
          event.preventDefault(); // stop the label from getting clicked
          event.stopPropagation(); // if this fires, it is done
          this.toggle();
          this.options.onChange(this.isChecked, event);
          this.input.triggerHandler('click');
          if (event.type === 'keypress') {
            $(event.target).focus();
          }
        }
      }
    },

    /*
     * internal function to set the color as checked. Needed for
     * use at init where the event would never be fired, yet the
     * triggered input has already been selected (ie going back
     * in a survey).
     */
    _check: function () {
      this._setColor(this.options.color, this.options.defaultColor);
      this.input.prop('checked', true);
      this.element.attr('aria-checked', 'true');
      this.input.attr('aria-checked', 'true');
      this.isChecked = true;
    },

    _setColor: function (newColor, oldColor) {
      this.element
        .removeClass('response-button-' + oldColor)
        .addClass('response-button-' + newColor);
    },

    _setOption: function _setOption(key, value) {
      if (key === 'color') {
        if (this.isChecked) {
          this._setColor(value, this.options.color);
        }
      }
      $.Widget.prototype._setOption.call(this, key, value);
    },

    _uncheckExclusiveSiblings: function () {
      var siblings = this.xor ? this._getSiblings() : this._getXorSiblings();

      $.each(siblings, function (index, sibling) {
        sibling.uncheck();
      });
    },

    /**
     * Returns array of sibling xor ResponseButton instances
     * (not elements), excluding this one.
     */
    _getXorSiblings: function () {
      var siblings = this._getSiblings();
      return $.grep(siblings, function (sibling) {
        return sibling.xor;
      });
    },

    /**
     * Returns array of sibling ResponseButton instances
     * (not elements), excluding this one.
     */
    _getSiblings: function () {
      var siblingInputs = $('[data-group="' + this.group + '"]').not(
        this.input
      );
      return siblingInputs.map(function () {
        return $(this).data('responsebutton-instance');
      });
    },
  };

  $.widget('yougov.responsebutton', ResponseButton);
})(jQuery);

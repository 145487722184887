var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "    <span class=\"openend-inline-prompt\">("
    + escapeExpression(lambda((depth0 != null ? depth0.prompt : depth0), depth0))
    + ")</span>\n    ";
},"3":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "\n    <span class=\"openend-inline-error\">"
    + escapeExpression(lambda((depth0 != null ? depth0.error_message : depth0), depth0))
    + "</span>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<span class=\"trailing-open\">\n  <span class=\"openend-inline\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.prompt : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = this.invokePartial(require("./open_entry.hbs"), '', 'open_entry', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </span>\n</span>\n";
},"usePartial":true,"useData":true});
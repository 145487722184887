(function ($, Gryphon) {
  var has = Object.prototype.hasOwnProperty;

  /**
   * Assign all properties from source objects onto the target.
   *
   * Right-most sources' properties win.
   *
   * @param {object} target The target object
   * @param {...object} source A source object
   */
  var assignAll = function assign(target, source) {
    var sources = Array.prototype.slice.call(arguments, 1);
    var key, i;
    for (i = 0; i < sources.length; i++) {
      source = sources[i];
      for (key in source) {
        if (has.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
  };

  /**
   * Assign public properties from a source object onto the target.
   *
   * Public properties are those that don't begin with an underscore.
   *
   * @param {object} target The target object
   * @param {object} source A source object
   */
  var assignPublicProps = function assign(target, source) {
    var key, i;
    for (key in source) {
      if (has.call(source, key) && key.charAt(0) !== '_') {
        target[key] = source[key];
      }
    }
  };

  /**
   * Base object class useful for inheritance.
   * Create a subclass thus:
   *     var View = Gryphon.BaseClass.extend({
   *         render: function render() {
   *             // Call superclass's render method
   *             View.__super__.render.call(this);
   *             this.doOtherStuff();
   *         },
   *         doOtherStuff: function doOtherStuff() {}
   *     });
   */
  function BaseClass() {}

  /**
   * Class creation function, heavily based on Backbone's `extend` method.
   *
   * Sets up a proper prototypal inheritance chain. Creates a `__super__`
   * property on the child constructor, which points to the parent's
   * prototype.
   *
   * For example, in the `render` method of a hypotheical `View` class, the
   * method on the superclass can be called like so:
   *     View.__super__.render.call(this);
   * @param {Object} protoProps Object containing properties that will form
   *     new class's prototypal object. If one of its properties is called
   *     `constructor`, its value will be the new class's constructor. Otherwise,
   *     a wrapper around the parent constructor is used.
   * @param {Object} [staticProps] Object containing static properties to attach
   *     to the constructor itself.
   */
  BaseClass.extend = function extend(protoProps, staticProps) {
    var parentCtor = this;
    var childCtor;

    if (protoProps && has.call(protoProps, 'constructor')) {
      childCtor = protoProps.constructor;
    } else {
      childCtor = function () {
        parentCtor.apply(this, arguments);
      };
    }

    // Copy over static/class properties (including this one!)
    assignPublicProps(childCtor, parentCtor);
    assignAll(childCtor, staticProps);

    function Proxy() {
      this.constructor = childCtor;
    }
    Proxy.prototype = parentCtor.prototype;
    childCtor.prototype = new Proxy();

    // Populate the proxy object with the intended prototypal properties
    assignAll(childCtor.prototype, protoProps);

    // Attach easy access to superclass prototype
    childCtor.__super__ = parentCtor.prototype;

    return childCtor;
  };

  Gryphon.BaseClass = BaseClass;
})(jQuery, (window.Gryphon = window.Gryphon || {}));

'use strict';

export default class MatrixFlashcards {
  constructor(context) {
    this.self = context;

    mainNav.nextButton.bind('click keypress', $.proxy(this, 'next'));
    mainNav.backButton.bind('click keypress', $.proxy(this, 'previous'));

    this._completed_phases = this.self._object.completed_phases || {};
  }

  _initResponseElements(layout) {
    const options = {
      color: this.self._object.color || 'blue',
    };
    const context = this;
    if (this.self._object.autoadvance) {
      options.onChange = $.proxy(function (checked, event) {
        if (checked) {
          context.next(event);
        }
      }, context);
    }

    const $responses = layout.elements;
    $responses.responsebutton(options);
    this._resizeButtonsForLayout(layout);
  }

  /**
   * Resize buttons based on the provided layout.
   * Note: comes from existing dyngrid.js
   *
   * @param {object} layout The layout instance.
   */
  _resizeButtonsForLayout(layout) {
    const self = this;
    setTimeout(function () {
      const buttons = layout.elements;
      const thresholdWidth = layout._object.thresholds[0];
      const isLargeDevice = layout.status.windowWidth > thresholdWidth;

      self._resetButtonSizes(buttons);
      buttons.responsebutton('hyphenate');

      if (isLargeDevice || layout.status.columns > 1) {
        if (layout.status.columns === 1) {
          buttons.css('display', 'inline-block');
          self._equaliseButtonSizes(buttons, 'width');
        }
        self._equaliseButtonSizes(buttons, 'height');
      }
    });
  }

  _equaliseButtonSizes(buttons, dimension) {
    buttons.equalisr(dimension);
  }

  _resetButtonSizes(buttons) {
    buttons.css('display', '').width('auto').height('auto');
  }

  _onLayout(layout) {
    // Note :: dyngrid does button styling and resizing starting here
    this._resizeButtonsForLayout(layout);
  }

  render() {
    const columns = this.self._object.response_options.length;
    // This logic was pulled from the existing 'dyngrid' widget
    // which currently displays answers in a 'button' style
    const $context = this;
    this.self.phases = $.map(
      this.self.$el.find('[data-phase]'),
      function (elm) {
        const $elm = $(elm);
        const $responses = $elm.find('.response-button');
        const responseListContainer = $elm.find('.question-response-list');
        const config = {
          columns,
          thresholds: [Gryphon.widgets.MEDIA_SKINNY_MAX_WIDTH],
          onLayout: $.proxy($context, '_onLayout'),
        };
        const layout = new Gryphon.widgets.layout.DynamicRowLayout(
          responseListContainer,
          $responses,
          config
        );

        $context._initResponseElements(layout);
        layout.render();
        return { $el: $elm, layout: layout };
      }
    );

    this.self._object.zero_phase_back =
      $('#back_button').css('display') != 'none';
    this.self._object.last_phase_next =
      $('#next_button').css('display') != 'none';

    this._setPhase(this.self._object.current_phase);
  }

  next(ev) {
    if (ev && ev.type === 'keypress' && ev.key !== ' ' && ev.key !== 'Enter') {
      return;
    }
    this._setPhaseCompletion();
    var next = this.self._object.current_phase + 1;
    var total = this.self._object.total_phases;
    if (next >= total) {
      click_next({ key: ev.key, type: ev.type });
    } else {
      this._setPhase(next);
    }
  }

  previous(ev) {
    if (ev && ev.type === 'keypress' && ev.key !== ' ' && ev.key !== 'Enter') {
      return;
    }
    this._setPhaseCompletion();
    var prev = this.self._object.current_phase - 1;
    if (prev < 0) {
      click_back({ key: ev.key, type: ev.type });
    } else {
      this._setPhase(prev);
    }
  }

  _setPhaseCompletion() {
    var current = this.self._object.current_phase;
    this._completed_phases[current] = Boolean(
      this.self.phases[current].$el.find('input:checked').length
    );
  }

  _setPhase(phase) {
    mainNav.disable();

    if ($(window).scrollTop() > 0 && $.scrollTo) {
      try {
        this._resizeButtonsForLayout();
        $.scrollTo(0, transitionPhases(this));
      } catch (e) {}
    } else {
      transitionPhases(this);
    }

    function transitionPhases(context) {
      var oldPhase = context.self.phases[context.self._object.current_phase];
      var newPhase = context.self.phases[phase];
      oldPhase.layout.elements.responsebutton('disable');
      oldPhase.$el.fadeOut(500, function () {
        oldPhase.$el.addClass('dyngrid-phase-hidden');
        newPhase.$el
          .removeClass('dyngrid-phase-hidden')
          // Undo display: none style from fadeOut
          .show();

        var buttons = newPhase.layout.elements;
        buttons.responsebutton('enable');
        context._onLayout(newPhase.layout);

        context.self._object.current_phase = phase;
        context._setNavButtonVisibility();
      });
    }
  }

  _setNavButtonVisibility() {
    var current = this.self._object.current_phase;
    var button_states = {
      back_button: true,
      next_button: true,
    };

    if (
      this.self._object.required !== 'NONE' &&
      this.self._object.autoadvance
    ) {
      if (!this._completed_phases[current]) {
        button_states.next_button = false;
      }
    }

    if (current === 0) {
      button_states.back_button = this.self._object.zero_phase_back;
    } else if (
      current == this.self._object.total_phases - 1 &&
      !this.self._object.required
    ) {
      button_states.next_button = this.self._object.last_phase_next;
    }

    set_nav_button_visibility(button_states);
  }
}

(function (_) {
  _.mixin({
    /**
     * Return a new array of arrays, each of length `size`, except perhaps the
     * last one, if the length of `list` is not divisible by `size`.
     *
     * For example:
     *     group([1,2,3,4,5,6,7], 3) // equals [[1,2,3], [4,5,6], [7]]
     *
     * @param {array} list The array to form groups from
     * @param {number} size The target length of each group
     */
    group: function group(list, size) {
      var ret = [];
      var i, remainder, subArray;
      for (i = 0; i < list.length; i++) {
        remainder = i % size;
        if (remainder === 0) {
          subArray = [];
          ret.push(subArray);
        }
        subArray.push(list[i]);
      }
      return ret;
    },

    /**
     * Return a new array of `number` sub-arrays. The lengths of the sub-arrays
     * will differ by at most 1, with the longer sub-arrays at the start.
     *
     * For example:
     *     split([1,2,3,4,5,6,7], 3) // equals [[1,2,3], [4,5], [6,7]
     *     split([1,2,3,4,5,6,7], 4) // equals [[1,2], [3,4], [5,6], [7]]
     *
     * @param {array} list The array to split
     * @param {number} number The number of sub-array to create
     */
    split: function split(list, number) {
      var listLength = list.length;
      number = Math.min(listLength, number);
      var size = Math.floor(listLength / number);
      var remainder = listLength % number;
      var ret = [];
      var pointer = 0;
      for (var i = 0; i < number; i++) {
        var subSize = size;
        if (remainder) {
          subSize += 1;
          remainder -= 1;
        }
        ret.push(list.slice(pointer, pointer + subSize));
        pointer += subSize;
      }
      return ret;
    },

    /**
     * Return a transposed version of the supplied `arrayOfArrays`.
     *
     * The `arrayOfArrays` need not be square, nor need each sub-array be of
     * the same length.
     *
     * Falsey values (also missing values, due to different sub-array lengths
     * or sparse arrays) are removed from the resultant sub-arrays!
     *
     * For example:
     *     transpose([[1,2], [3,4], [5,6]]) // equals [[1,3,5], [2,4,6]]
     *     transpose([[1,2,3], [4,5]]) // equals [[1,4], [2,5], [3]]
     *     transpose([[1,2,false], [4,5,6]]) // equals [[1,4], [2,5], [6]]
     *     transpose([[1,2,3]]) // equals [[1], [2], [3]]
     *
     * @param {array<array>} arrayOfArrays 2D array to transpose
     */
    transpose: function transpose(arrayOfArrays) {
      while (arrayOfArrays.length < 2) {
        arrayOfArrays.push([]);
      }
      return _.map(_.zip.apply(_, arrayOfArrays), _.compact);
    },
  });
})(_);

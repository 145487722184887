(function (widgets) {
  var ImageMapSingle = widgets.Widget.extend(
    {
      render: function render() {
        ImageMapSingle.__super__.render.call(this);
        var self = this;
        var anchors = self.$el.find('div.image-area a');
        $.each(anchors, function (i) {
          if ($(this).find('input').val() == 'selected') {
            $(this).addClass('selected');
          }
        });

        $('div.image-area a').click(function (e) {
          $.each(anchors, function (i) {
            $(this).find('input').val('unselected');
            $(this).removeClass('selected');
          });

          var inp = $(this).find('input');
          if (inp.val() == 'unselected') {
            inp.val('selected');
          } else {
            inp.val('unselected');
          }
          $(this).toggleClass('selected');
          return false;
        });
      },
    },
    {
      types: ['imagemap-single'],
      views: ['imagemap-single'],
    }
  );

  ImageMapSingle.register();
  widgets.ImageMapSingle = ImageMapSingle;
})((Gryphon.widgets = Gryphon.widgets || {}));

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "accept=\""
    + escapeExpression(lambda((depth0 != null ? depth0.file_types : depth0), depth0))
    + "\"";
},"3":function(depth0,helpers,partials,data) {
  return "multiple";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"question question-upload\">\n";
  stack1 = this.invokePartial(require("./error.hbs"), '  ', 'error', depth0, {
    'extra_classes': ("hide")
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "  <div class=\"question-text\">";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n  <div class=\"question-response-form\">\n    <div class=\"upload-container\" id=\"upload-dropbox\">\n      <div class=\"input-container\">\n        <div>\n          <button\n            class=\"response-button\"\n            role=\"button\"\n            tabindex=\"0\"\n            style=\"user-select: none\"\n          >\n             "
    + escapeExpression(lambda((depth0 != null ? depth0.btn_text : depth0), depth0))
    + "\n            <i class=\"response-button-icon\">\n                <svg width=\"18\" height=\"18\" viewBox=\"0 0 18 18\" xmlns=\"http://www.w3.org/2000/svg\">\n                    <g stroke=\"#FFF\" stroke-width=\"2.5\" fill=\"none\" fill-rule=\"evenodd\">\n                        <path d=\"M13.285 6.708 8.581 2.005 3.877 6.708M8.55 2.13v9.24M3.6 16.65h9.9\"/>\n                    </g>\n                </svg>\n            </i>\n          </button>\n        </div>\n        <label for=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "\">"
    + escapeExpression(lambda((depth0 != null ? depth0.drag_text : depth0), depth0))
    + "</label>\n        <input type=\"file\" id=\"r-upload-"
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "\" name=\"upload-"
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "\" onclick=\"this.value=null;\"\n        class=\"file-input\" data-min-number=\""
    + escapeExpression(lambda((depth0 != null ? depth0.min_number : depth0), depth0))
    + "\"\n        data-max-number=\""
    + escapeExpression(lambda((depth0 != null ? depth0.max_number : depth0), depth0))
    + "\" data-max-size=\""
    + escapeExpression(lambda((depth0 != null ? depth0.max_filesize_MB : depth0), depth0))
    + "\"\n        ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.file_types : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.multiple : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n        <div class=\"file-list\"></div>\n        <input type=\"hidden\" id=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "\" name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "\" />\n      </div>\n    </div>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true});
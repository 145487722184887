(function ($, widgets, templates) {
  $.extend(widgets, {
    MEDIA_SMALL_DEVICE_MAX_WIDTH: 320,
    MEDIA_SKINNY_MAX_WIDTH: 575,
    MEDIA_FULL_WIDTH: 1020,
    RELAYOUT_PERIOD: 250,
  });

  /**
   * Class from which all widgets inherit.
   *
   * Provides a consistent interface to managing the life-cycle of widgets on
   * the client, whatever the underlying technology driving the widget is.
   *
   * All of `Widget`'s methods are designed to be called via super calls in
   * subclasses. This is to ensure consistent behaviour across widgets.
   */
  var Widget = Gryphon.BaseClass.extend(
    {
      /**
       * Base constructor.
       *
       * Only light operations, like attaching event listeners or binding
       * variables should be done in this function.
       *
       * @param {object} object The question object that describes the instance.
       *     Must specify element_id, the id of the element in which to render,
       *     which is assumed to already exist in the DOM.
       */
      constructor: function WidgetConstructor(object) {
        /**
         * The jQuery-wrapped element that contains this widget.
         * @public
         * @readonly
         */
        this.$el = $('#' + object.element_id);

        if (!object.element_id || !this.$el.length) {
          throw new TypeError('No valid element_id specified!');
        }

        /**
         * @private
         * @readonly
         */
        this._object = object;
      },

      /**
       * Renders and inserts the template into the widget's `$el` element.
       */
      render: function render() {
        var object = this.object();
        var html = templates[object.view](object);
        this.$el.html(html);

        // To help screen readers, reset focus to the top of the
        // form after new widgets render
        mainNav.blur();
        $('#main_form').focus();

        // Legacy Color Palette [GRYP-9764]
        // Behind feature flag for following widget types
        var feature_flag_legacy_colors = [
          'dyngrid',
          'dyngrid-check',
          'placement',
          'image-highlight',
          'text-highlight',
        ];
        if (
          !!object.legacy_colors &&
          _.filter(feature_flag_legacy_colors, function (v) {
            return v === object.type;
          }).length > 0
        ) {
          $('html').addClass('legacy-colors');
        } else {
          $('html').removeClass('legacy-colors');
        }

        // Feature flag list so we can
        // iteratively support turning
        // this on for each widget
        var feature_flag_displaymax = [
          'multiple',
          'multiple-colorpicker',
          'card-sort',
        ];
        if (
          object.displaymax &&
          _.filter(feature_flag_displaymax, function (v) {
            return v === object.type;
          }).length > 0
        ) {
          this._initDisplaymax();
        }

        // note: if we already have an error message displayed
        // do not hide the show_on_skip elements or interrupt the
        // click_next event
        var whitelist = [
          'single',
          'multiple',
          'dropdown',
          'grid',
          'grid-check',
          'grid-open',
        ];
        this._object.show_on_skip = _.filter(object.response_options, {
          options: { show_on_skip: true },
        });
        if (
          $('.alert-error').is(':visible') ||
          !this._object.show_on_skip ||
          (this._object.show_on_skip &&
            this._object.show_on_skip.length == 0) ||
          whitelist.indexOf(object.type) === -1
        ) {
          return;
        }

        // handle our 'show_on_skip' responses
        $(document).on(
          'after_widget_rendered',
          $.proxy(this, '_handleShowOnSkip')
        );
      },

      _handleShowOnSkip: function _handleShowOnSkip() {
        var self = this;
        var el,
          elms = self._object.show_on_skip || [];
        for (var i = 0; i < elms.length; i++) {
          el = $('#' + self._object.element_id + '-' + elms[i].code);

          if (!$('.alert-error').is(':visible')) {
            el.hide();
          }
        }

        // interrupt the next click event to check for conditionals
        if (mainNav) {
          mainNav.nextButton.bind(
            'click keypress',
            $.proxy(this, '_checkConditionals')
          );
        }
      },

      isPassed: function isPassed(self, answered, elms, hidden) {
        // we only care about checking when the 'show_on_skip' is
        // present within a question, otherwise, business as usual
        // treat like a SOFT require and allow continuation after initially shown
        if (
          !self._object.show_on_skip ||
          elms.length == 0 ||
          answered.length > 0 ||
          self._object.required === 'SOFT'
        ) {
          return true;
        }
        // rely on require=HARD for further restrictions
        if (hidden.length == 0 && self._object.required !== 'HARD') {
          return true;
        }
        return false;
      },

      _checkConditionals: function _checkConditionals(ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        var self = this,
          el,
          elms = self._object.show_on_skip || [];

        var responses = $('#' + self._object.element_id + ' input');
        var answered = _.filter(responses, function (i) {
          return $(i).is(':checked');
        });
        var hidden = _.filter(elms, function (i) {
          return $('#' + self._object.element_id + '-' + i.code).is(':hidden');
        });

        if (self.isPassed(self, answered, elms, hidden)) {
          return click_next({ key: ev.key, type: ev.type });
        }

        // show the message and extra response option
        for (var i = 0; i < elms.length; i++) {
          el = $('#' + self._object.element_id + '-' + elms[i].code);
          if (el.css('display') === 'none') {
            el.show();
            if ($('.alert-error').is(':visible')) {
              $('.alert-error').text(self._object.required_text);
            } else {
              $('.question-text').after(
                '<div class="alert alert-error">' +
                  self._object.required_text +
                  '</div>'
              );
            }
          }
        }
      },

      /**
       * Manages the behaviour specific to widgets with `displaymax` option set.
       *
       * This is mostly a wrapper around legacy code and its side-effects; call
       * `.applySideEffects()` to initialise the behaviours.
       */
      _initDisplaymax: function _initDisplaymax() {
        this.displaymax_soft_prompts = 0;
        this.applySideEffects();
      },

      // NOTE :: As part of the bigger lift, we'll probably want to deprecate this
      // method used in grid.js
      applySideEffects: function applySideEffects() {
        this.update_paged_buttons();
        // Defined at the widget-level
        mainNav.nextButton.bind('click keypress', $.proxy(this, 'p_next'));
        mainNav.backButton.bind('click keypress', $.proxy(this, 'p_back'));
        // Used for the DynamicRowLayout widget
        $(document).on(
          'after_widget_rendered',
          $.proxy(this, 'setPagedVisibility', false)
        );
      },

      /**
       * Important :: This function does the actual hiding / showing
       * of the elements (response options, subquestions, etc) from
       * the DynamicRowLayout class
       *
       */
      setPagedVisibility: function setPagedVisibility(elems) {
        var self = this;
        if (!elems) {
          elems = self.$el.find('.dynamic-row');
        }
        if (elems.length == 0) {
          return;
        }

        // setup our range of display indices based on current phase
        // and displaymax value
        var range = {
          min: self._object.phase_pos * self._object.displaymax,
          max:
            self._object.phase_pos * self._object.displaymax +
            self._object.displaymax -
            1,
        };
        _.each(elems, function (row, idx) {
          if (idx < range.min || idx > range.max) {
            $(row).hide();
          } else {
            $(row).show();
          }
        });
      },

      // NOTE :: As part of the bigger lift, we'll probably want to deprecate this
      // method used in grid.js
      update_paged_buttons: function update_paged_buttons() {
        if (this._object.back_allowed == undefined) {
          this._object.back_allowed =
            $('#back_button').css('display') != 'none';
        }
        var button_states = {};
        button_states.next_button = true;
        button_states.back_button = this._object.back_allowed;
        var pos = this._object.phase_pos;
        if (pos > 0 || this._object.back_allowed) {
          button_states.back_button = true;
        }
        set_nav_button_visibility(button_states);
      },

      /**
       * Checks required and soft_required and returns based on conditional
       *
       * This can be extended within the widget, but will generally cover
       * most widgets with subquestion types (like grid)
       *
       * Note: Widgets with response option pagination should check once all pages
       * have been displayed before determining whether the required flag is
       * satisfied or not
       */
      // NOTE :: As part of the bigger lift, we'll probably want to deprecate this
      // method used in grid.js
      required_is_satisfied: function required_is_satisfied() {
        var required = this._object.required === 'HARD' ? true : false;
        var soft_required = this._object.soft_required;
        if (required || (soft_required && !this.displaymax_soft_prompts)) {
          if (this.selected_rows_count() < this.visible_rows_count()) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      },

      // NOTE :: As part of the bigger lift, we'll probably want to deprecate this
      // method used in grid.js
      selected_rows_count: function selected_rows_count() {
        var phase_rows = this.get_phase_rows();
        var isCategorical = !!phase_rows.find('input:radio, input:checkbox')
          .length;
        var num_selected = 0;
        phase_rows.each(function () {
          var phase_row = $(this);

          if (isCategorical) {
            if (phase_row.find('input:checked').length) {
              num_selected++;
            }
          } else {
            var inputs = phase_row.find('input:text');
            var answers = 0;
            inputs.each(function () {
              if ($(this).val() !== '') {
                answers += 1;
              }
            });
            if (answers === inputs.length) {
              num_selected += 1;
            }
          }
        });
        return num_selected;
      },

      // NOTE :: As part of the bigger lift, we'll probably want to deprecate this
      // method used in grid.js
      visible_rows_count: function visible_rows_count() {
        if (this._object.displaymax && this._object.transpose) {
          // If transposed and displaymax, we need to divide the number of table rows
          // with the total # of table cells to determine visible columns
          // NOTE: This solution is specific to grid type widgets for now
          return (
            this.get_phase_rows().length /
            $('table.grid-layout tbody > tr').length
          );
        }
        // Each widget is responsible for returning the right
        // elements for the `get_phase_rows` method since each
        // widget is constructed differently
        return this.get_phase_rows().length;
      },

      // NOTE :: As part of the bigger lift, we'll probably want to deprecate this
      // method used in grid.js
      display_required_message: function display_required_message(container) {
        var template = Gryphon.templates['displaymax-required-message'];
        var data = { required_message: this._object.required_message };
        $(container).before(template(data));
        if (this._object.soft_required) {
          this.displaymax_soft_prompts = 1;
        }
      },

      // NOTE :: As part of the bigger lift, we'll probably want to deprecate this
      // method used in grid.js
      clear_required_message: function clear_required_message() {
        $('#displaymax-required-error').remove();
      },

      /**
       * Returns a deep copy of the original object passed to the constructor.
       *
       * This is used by the base `render` method, so if a subclass needs to
       * modify the object prior to passing it to its template, it should extend
       * this method. This also prevents the template from modifying the original
       * object.
       */
      object: function object() {
        return _.clone(this._object, true);
      },

      /**
       * Destroy the widget.
       *
       * This is the best place to remove event listeners. Removing DOM nodes
       * added by the view template isn't necessary.
       */
      destroy: function destroy() {
        $(document).off('after_widget_rendered');
      },
    },

    // Static properties
    {
      /**
       * Given a question object representing a new widget instance, return a new
       * instance of the appropriate `Widget` subclass.
       *
       * @param {object} object The question object that describes the instance.
       */
      fromObject: function fromObject(object) {
        var typeMap = Widget._subClasses[object.type];
        if (typeMap && object.view in typeMap) {
          return new typeMap[object.view](object);
        }
        return null;
      },

      /**
       * Register a `Widget` subclass with a widget type so that it is known to
       * `Widget.fromObject`.
       *
       * Depends on static properties of the constructor, `types` and `views`,
       * which are array of strings of types and views supported by the subclass.
       */
      register: function register() {
        var i, j, view, type;
        for (i = 0; (type = this.types[i]); i++) {
          if (!(type in Widget._subClasses)) {
            Widget._subClasses[type] = {};
          }

          var typeMap = Widget._subClasses[type];

          for (j = 0; (view = this.views[j]); j++) {
            if (!(view in templates)) {
              throw new Error("Template '" + view + "' does not exist.");
            }

            if (view in typeMap) {
              throw new Error(
                "Cannot register widget due to existing entry for type '" +
                  type +
                  "' and view '" +
                  view +
                  "'."
              );
            }

            typeMap[view] = this;
          }
        }
      },

      /**
       * The list of types supported by class.
       * Must be overridden by subclasses if they are to be registered.
       */
      types: null,

      /**
       * The list of views supported by a class.
       * Must be overridden by subclasses if they are to be registered.
       */
      views: null,

      /**
       * Private map used by `Widget`.
       * @private
       */
      _subClasses: {},
    }
  );

  widgets.Widget = Widget;
})(jQuery, (Gryphon.widgets = Gryphon.widgets || {}), Gryphon.templates);

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  return "aria-checked=\"true\"";
  },"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = " ";
  stack1 = __default(require("../js/helpers/partial.js")).call(depth0, "open_trailer", (depth0 != null ? depth0.trailer : depth0), {"name":"partial","hash":{},"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " ";
},"5":function(depth0,helpers,partials,data) {
  return "\n  checked=\"checked\" aria-checked=\"true\" ";
  },"7":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return " value=\""
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\"\n  ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"response-button\" role=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_type : depth0), depth0))
    + "\" ";
  stack1 = __default(require("../js/helpers/ifans.js")).call(depth0, (depth0 != null ? depth0.answer : depth0), (depth0 != null ? depth0.input_name : depth0), (depth0 != null ? depth0.code : depth0), {"name":"ifans","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " tabindex=\"0\">\n  ";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = __default(require("../js/helpers/ifindexof.js")).call(depth0, ((stack1 = (depth0 != null ? depth0.trailer : depth0)) != null ? stack1.type : stack1), "open", "==", 0, {"name":"ifindexof","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " <input id=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.input_name : depth0), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\"\n  name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_name : depth0), depth0))
    + "\" class=\"response-button-input hide "
    + escapeExpression(lambda((depth0 != null ? depth0.input_class : depth0), depth0))
    + "\"\n  data-group=\""
    + escapeExpression(lambda((depth0 != null ? depth0.group : depth0), depth0))
    + "\" type=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_type : depth0), depth0))
    + "\" ";
  stack1 = __default(require("../js/helpers/ifans.js")).call(depth0, (depth0 != null ? depth0.answer : depth0), (depth0 != null ? depth0.input_name : depth0), (depth0 != null ? depth0.code : depth0), {"name":"ifans","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, (depth0 != null ? depth0.input_type : depth0), "radio", {"name":"ifequal","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " >\n</div>\n";
},"useData":true});
/**
 * The EventLog class and initializer.
 * @param {number} maxEvents The maximum number of events before flushing.
 * @param {number} timeoutPeriod The timeout period for the buffer flushing.
 */
(function ($) {
  var EventLog = function (maxEvents, timeoutPeriod) {
    return {
      _url: window.EVENTLOG_URL,
      _maxEvents: maxEvents || 10,
      _timeoutPeriod: timeoutPeriod || 5000,

      /**
       * The events stack.
       **/
      _events: [],

      /**
       * The reference to the buffer timer.
       **/
      _timeout: null,

      /**
       * Start the timer that handles the buffering.
       **/
      _setTimeout: function () {
        var _this = this;

        // Flush the buffer and clear the timeout.
        _this.flush();

        this._timeout = window.setTimeout(function () {
          _this.flush();
        }, _this._timeoutPeriod);
      },

      /**
       * Add the log to the stack, starting a buffer timer if none exists.
       * @param {Object} data The body of the event.
       **/
      log: function (data) {
        if (this._url) {
          if (window.segments) {
            var segmentParts = window.segments.split('/');
            data.visa = segmentParts.length > 1 ? segmentParts[1] : '';
          }

          this._events.push(data);

          if (this._events.length >= this._maxEvents) {
            this.flush();
            return;
          }

          // Start a buffering timeout if none exists.
          if (!this._timeout) {
            this._setTimeout();
          }
        }
      },

      /**
       * Add the event to the stack with some specific params.
       * @param {String} name The name of the event we want to register.
       * @param {Object} eventDetails The specific events parameters we need to pass.
       **/
      eventDetails: function (name, eventDetails) {
        if (eventDetails == '' || eventDetails == null) {
          eventDetails = {};
        }
        var data = {
          app: 'gryphon.ivw.frontend',
          event: name,
          event_time: new Date().toISOString(),
          event_details: eventDetails,
          context: {
            user_agent: this.getBrowserInfo(),
            os: this.getOsInfo(),
          },
        };
        this.log(data);
      },

      /**
       * Return the name of the browser and its version
       **/
      getBrowserInfo: function () {
        var ua = navigator.userAgent,
          tem,
          M =
            ua.match(
              /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
            ) || [];
        if (/trident/i.test(M[1])) {
          tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
          return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
          tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
          if (tem != null)
            return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M = M[2]
          ? [M[1], M[2]]
          : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M.join(' ');
      },

      /**
       * Return the name of the OS
       **/
      getOsInfo: function () {
        var userAgent = window.navigator.userAgent,
          platform = window.navigator.platform,
          macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
          windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
          iosPlatforms = ['iPhone', 'iPad', 'iPod'],
          os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
          os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
          os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
          os = 'Windows';
        } else if (/Android/.test(userAgent)) {
          os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
          os = 'Linux';
        }

        return os;
      },

      /**
       * Add the event to the stack with some specific params.
       * @param {String} name The name of the event we want to register.
       **/
      eventNoDetails: function (name) {
        this.eventDetails(name, null);
      },

      /**
       * Send the current event stack to the server, and reset everything.
       **/
      flush: function () {
        // Note that we only create new timeouts during the log method.
        // There is no need for a timeout when there is nothing in the stack.
        window.clearTimeout(this._timeout);
        this._timeout = null;

        if (this._events.length) {
          $.ajax({
            type: 'POST',
            url: this._url,
            data: { events: this._events },
            dataType: 'json',
            global: false,
          });
          this._events = [];
        }
      },

      /**
       * Reset everything.
       **/
      clear: function () {
        // eslint-disable-next-line no-redeclare
        /* global console:true */
        window.clearTimeout(this._timeout);
        this._timeout = null;
        this._events = [];
        console.log('Cleared!');
      },
    };
  };
  window.EventLog = EventLog;
})(jQuery);

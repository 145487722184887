var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  return "        <div class=\"modal-close-toggle\">\n          <i class=\"ygicon-popup-close popup-close\" data-dismiss=\"modal\"></i>\n        </div>\n";
  },"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '            ', 'error', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"5":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "          <div class=\"question-response-column\">\n            <div class=\"question-response-item\">\n              <label class=\"response-label\" for=\"r-"
    + escapeExpression(lambda((depths[1] != null ? depths[1].input_id : depths[1]), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\">\n                <input id=\"r-"
    + escapeExpression(lambda((depths[1] != null ? depths[1].input_id : depths[1]), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\" class=\"response-input\" name=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].input_id : depths[1]), depth0))
    + "\"\n                ";
  stack1 = __default(require("../js/helpers/ifans.js")).call(depth0, (depths[1] != null ? depths[1].answer : depths[1]), (depths[1] != null ? depths[1].input_id : depths[1]), (depth0 != null ? depth0.code : depth0), {"name":"ifans","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " type=\"radio\" value=\""
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\">\n                <span class=\"label-inner\">\n                  <span class=\"label-text\"\n                    >";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.text : depth0), {"name":"if","hash":{},"fn":this.program(8, data, depths),"inverse":this.program(10, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</span\n                  >\n                </span>\n              </label>\n            </div>\n          </div>\n";
},"6":function(depth0,helpers,partials,data) {
  return " checked=\"checked\"\n                ";
  },"8":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda;
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { return stack1; }
  else { return ''; }
  },"10":function(depth0,helpers,partials,data) {
  return "&nbsp;";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div\n  id=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.input_idx : depth0), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\"\n  class=\"trailing-popup modal fade\"\n  role=\"dialog\"\n  data-required=\""
    + escapeExpression(lambda((depth0 != null ? depth0.required : depth0), depth0))
    + "\"\n  data-required-text=\""
    + escapeExpression(lambda((depth0 != null ? depth0.required_text : depth0), depth0))
    + "\"\n>\n  <div class=\"modal-dialog modal-sm\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.show_close_button : depth0), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "      </div>\n      <div class=\"modal-body\">\n        <div class=\"question-text\">";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n        <div class=\"question-response-list\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.response_options : depth0), {"name":"each","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </div>\n      </div>\n      <div class=\"modal-footer\"></div>\n    </div>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});
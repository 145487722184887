var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "<div class=\"question-text\">";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '    ', 'error', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "    <a\n      href=\"\"\n      style=\""
    + escapeExpression(lambda((depth0 != null ? depth0.style : depth0), depth0))
    + "\"\n      tabindex=\"0\"\n      onkeydown=\"on_change(event)\"\n      role=\"checkbox\"\n      aria-checked=\"false\"\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.read_only : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "      >\n      <span>"
    + escapeExpression(lambda((depth0 != null ? depth0.label : depth0), depth0))
    + "</span>\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.read_only : depth0), {"name":"unless","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </a>\n";
},"5":function(depth0,helpers,partials,data) {
  return "      class=\"selected inactive\"\n      onclick=\"return false\"\n";
  },"7":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "      <input\n        type=\"hidden\"\n        name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_name : depth0), depth0))
    + "\"\n";
  stack1 = __default(require("../js/helpers/ifanswerexists.js")).call(depth0, (depth0 != null ? depth0.input_name : depth0), {"name":"ifanswerexists","hash":{},"fn":this.program(8, data),"inverse":this.program(10, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        >\n";
},"8":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression;
  return "        value=\""
    + escapeExpression(__default(require("../js/helpers/answer.js")).call(depth0, (depth0 != null ? depth0.input_name : depth0), {"name":"answer","hash":{},"data":data}))
    + "\"\n";
},"10":function(depth0,helpers,partials,data) {
  return "        value=\"unselected\"\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"question question-imagemapsingle\">\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.read_only : depth0), {"name":"unless","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<div\n  class=\"image-map\"\n  style=\"width:"
    + escapeExpression(lambda((depth0 != null ? depth0.img_width : depth0), depth0))
    + "px; height:"
    + escapeExpression(lambda((depth0 != null ? depth0.img_height : depth0), depth0))
    + "px; background-image:url("
    + escapeExpression(lambda((depth0 != null ? depth0.img_src : depth0), depth0))
    + ");\">\n  <div class=\"image-area\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.areas : depth0), {"name":"each","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n</div>\n</div>\n";
},"usePartial":true,"useData":true});
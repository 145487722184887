'use strict';

global.page_state = {};

global.clear_spd_elements_state = function () {
  /**
   * Clear and hide SPD (GDPR) consent content
   */
  $('.spd-consent-text p').text('');
  $('.spd-content').hide();
  if (mainNav) {
    mainNav.skipButton.hide();
  }
};

export function get_page_state() {
  return page_state;
}

export function set_page_state(state) {
  page_state = state;
  return page_state;
}

export function init_page_state(res) {
  if (res.init_state) {
    for (var name in res.init_state) {
      page_state[name] = res.init_state[name];
    }
  }
}

export function clear_page_state() {
  $(window).off('resize.videoPlayer orientationChanged.videoPlayer');
  clear_spd_elements_state();
  $(page_state).unbind();
  page_state = {};
  page_state.vardata = {};
  page_state.page_events = {};
}

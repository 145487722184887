/**
 * Handle mutually exclusive checkboxes within groups.
 *
 * Define a group of checkboxes by setting their `data-xor-group` attributes to
 * the same value. The checkboxes in the group can be anywhere in the document,
 * and are handled even if added dynamically.
 * To make particular checkboxes exclusive, add the `xor` class to them, and
 * checking them will clear all their siblings in the group. Checking those
 * without that class will clear all `xor` siblings in the group.
 *
 * Sample markup:
 *     <input type="checkbox" data-xor-group="w37" name="a">
 *     <input type="checkbox" data-xor-group="w37" name="b">
 *     <input type="checkbox" data-xor-group="w37" name="c" class="xor">
 *     <input type="checkbox" data-xor-group="w37" name="d" class="xor">
 */
(function ($) {
  var eventName = 'click.xor-group';
  $(document).on(eventName, '[data-xor-group]', function (event) {
    var $this = $(this);
    var group = $this.data('xorGroup');
    var isXor = $this.hasClass('xor');
    var siblings = $(':checkbox')
      .filter('[data-xor-group="' + group + '"]')
      .not($this);

    if (isXor) {
      siblings.prop('checked', false);
    } else if ($this.is(':checked')) {
      siblings.filter('.xor').prop('checked', false);
    }
    $.uniform.update();
  });
})(jQuery);

module.exports = function (arr, options) {
  if (options.inverse && !arr.length) {
    return options.inverse(this);
  }

  return arr
    .map(function (item, index) {
      if (typeof item === 'string') {
        item = String(item);
      }

      item.$index = index;
      item.$first = index === 0;
      item.$last = index === arr.length - 1;

      return options.fn(item);
    })
    .join('');
};

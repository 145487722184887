var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '    ', 'error', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = " ";
  stack1 = __default(require("../js/helpers/partial.js")).call(depth0, "dk-partial", {"name":"partial","hash":{
    'dk_id': ((depth0 != null ? depth0.dk_id : depth0)),
    'wide_view': ((depth0 != null ? depth0.wide_view : depth0)),
    'narrow_view': ((depth0 != null ? depth0.narrow_view : depth0)),
    'dk_value': ((depth0 != null ? depth0.dk_value : depth0)),
    'response_id': ((depth0 != null ? depth0.response_id : depth0)),
    'dk_text': ((depth0 != null ? depth0.dk_text : depth0))
  },"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"question question-rule\">\n  <div class=\"question-text\">";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  <script>\n    window.rule_narrow_view = '"
    + escapeExpression(lambda((depth0 != null ? depth0.narrow_view : depth0), depth0))
    + "'\n    window.rule_wide_view = '"
    + escapeExpression(lambda((depth0 != null ? depth0.wide_view : depth0), depth0))
    + "'\n  </script>\n  <div\n    class=\"placement-widget rule-outer narrow_view_"
    + escapeExpression(lambda((depth0 != null ? depth0.narrow_view : depth0), depth0))
    + " wide_view_"
    + escapeExpression(lambda((depth0 != null ? depth0.wide_view : depth0), depth0))
    + " clearfix\"\n  >\n    ";
  stack1 = __default(require("../js/helpers/partial.js")).call(depth0, "rule-partial", {"name":"partial","hash":{
    'show_value': ((depth0 != null ? depth0.show_value : depth0)),
    'input_id': ((depth0 != null ? depth0.input_id : depth0)),
    'right_message': ((depth0 != null ? depth0.right_message : depth0)),
    'left_message': ((depth0 != null ? depth0.left_message : depth0)),
    'increment': ((depth0 != null ? depth0.increment : depth0)),
    'max': ((depth0 != null ? depth0.max : depth0)),
    'min': ((depth0 != null ? depth0.min : depth0)),
    'widget': ("rule")
  },"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    <input\n      type=\"hidden\"\n      name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.response_id : depth0), depth0))
    + "\"\n      id=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "-value\"\n      class=\"hidden-input\"\n      value=\""
    + escapeExpression(__default(require("../js/helpers/ans.js")).call(depth0, (depth0 != null ? depth0.answer : depth0), (depth0 != null ? depth0.response_id : depth0), {"name":"ans","hash":{},"data":data}))
    + "\"\n    />\n  </div>\n  ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.dont_know : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n</div>\n";
},"usePartial":true,"useData":true});
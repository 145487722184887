(function (widgets) {
  var baseClass = 'text-highlight';
  var textareaValuesCopy = [];

  var classes = {
    question: baseClass,
    blockLevel: '-block',
    grid: 'grid-table-mode',
    textarea: 'textarea',
    widget: '-widget',
    markers: '-markers',
    markersDisabled: '-markers-disabled',
    marker: '-marker',
    markerIcon: '-marker-icon',
    markerCaption: '-marker-caption',
    board: '-board',
    active: '-active',
    selected: '-selected',
    qerror: 'hasError',
    error: '-error',
  };

  var events = {
    marker: {
      beforeChange: '',
      afterChange: '',
    },
  };

  var IS_TOUCH = (function () {
    var check = false;
    /* eslint-disable no-useless-escape*/
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      ) {
        check = true;
      }
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  })();

  var TextHighlight = widgets.Widget.extend(
    {
      render: function render() {
        TextHighlight.__super__.render.call(this);
        var settings = this._object;

        $.each(classes, function (key, value) {
          if (value.substring(0, 1) === '-') {
            classes[key] = baseClass + value;
          }
        });

        var self = this;
        var $question = $('.question-text-highlight');

        this.$el.find('input:checkbox').uniform();

        self.classes = classes;
        self.events = events;
        self.selectors = {};
        self.config = {};

        $.each(classes, function (key, value) {
          self.selectors[key] = '.' + value;
        });

        var $grid = $question.find(self.selectors.grid);
        var $textarea = $grid.find(self.selectors.textarea);
        var $widget = $question.find(self.selectors.widget);
        var $markers = $widget.find(self.selectors.markers);
        var $marker = $markers.find(self.selectors.marker);
        self.textarea = $textarea;
        self.widget = $widget;
        self.markers = $markers;
        self.marker = $marker;

        self.handleHighlight = function (
          node,
          backgroundColor,
          selectedColor,
          selectedCode,
          inputName,
          markerIndex
        ) {
          var widgetId = $(node).attr('data-widget-id');
          var textAreaIndex = $('#' + inputName).attr('data-marker-index');

          var textarea = $('#' + widgetId)
            .find('textarea')
            .eq(textAreaIndex ? textAreaIndex : markerIndex);

          var values = textarea.val().split('\n');
          if (!$('#' + inputName).data('selectable')) {
            return;
          }

          if ($('.dk-input input').attr('checked') === 'checked') {
            // disable DK only if we click again text
            document.getElementsByClassName('dk-input')[0].click();
          } else {
            if (
              backgroundColor === 'rgba(0, 0, 0, 0)' ||
              backgroundColor === 'transparent'
            ) {
              // highlight
              $('#' + inputName)
                .css({
                  'background-color': selectedColor,
                  color: $(self.selectors.selected).css('color'), // use the text color of the selected button
                })
                .attr('data-marker-index', markerIndex);
              $('#r-' + inputName).val(selectedCode);

              if (values === '' || values.indexOf(inputName) === -1) {
                textarea.val(textarea.val() + inputName + '\n');
              }
            } else {
              // un-highlight
              $('#' + inputName)
                .css({
                  'background-color': 'transparent',
                  color: 'var(--color-stonegrey-1200)',
                })
                .removeAttr('data-marker-index');
              $('#r-' + inputName).val('');

              if (values.indexOf(inputName) !== -1) {
                values.splice(values.indexOf(inputName), 1);
                textarea.val(values.join('\n'));
              }
            }
          }
        };

        // pre-select the first marker (highlighter)
        setTimeout(function () {
          // Use the `element_id` value to target the specific instance, as there
          // could be more than one instance of the question on a given page
          $('#' + settings.element_id)
            .find('.text-highlight-marker:first')
            .click();
          $('.question-cont').not(':last').find('.dk-input').remove();
        });

        this._calculateCoords();
        this.setup_markers(self);
        this.setup_events(self);

        if (
          this._object.custom_colorder !== '' &&
          this._object.custom_colorder !== 'None'
        ) {
          self._custom_colorder(this, this._object.custom_colorder);
        }

        global.disable_dk_check();
      },

      setup_events: function setup_events(self) {
        var config = self.config;
        $.each(self.events, function (key, value) {
          if (typeof value === 'object') {
            $.each(value, function (subkey, subvalue) {
              if (!subvalue) {
                self.events[key][subkey] = subkey.toLowerCase();
              }
            });
          } else {
            if (!value) {
              self.events[key] = key.toLowerCase();
            }
          }
        });

        $('.text-highlight-marker')
          .off('click')
          .on('click', function () {
            $(this)
              .addClass(self.classes.selected)
              .blur()
              .siblings()
              .removeClass(self.classes.selected);
          });

        $('.dk-input input')
          .off('change')
          .on('change', function (evt) {
            $('.text-highlight-board').toggleClass('hidden-response');
            var isChecked = evt.currentTarget.checked;
            var textareas = $('.grid-table-mode').find('textarea');
            var i;

            if (isChecked) {
              for (i = 0; i < textareas.length; i++) {
                textareaValuesCopy[i] = textareas[i].value;
                textareas[i].value = '';
              }
              // set all as unselectable while DK is checked
              _.each($('.text-highlight-board span'), function (el) {
                // set the selected colors in data attributes
                $(el)
                  .data('bg-color', $(el).css('background-color'))
                  .data('text-color', $(el).css('color'))
                  .css({
                    'background-color': 'transparent',
                    color: 'var(--color-stonegrey-1200)',
                  });
              });
            } else {
              for (i = 0; i < textareas.length; i++) {
                textareas[i].value = textareaValuesCopy[i];
              }
              _.each($('.text-highlight-board span'), function (el) {
                if ($(el).data('bg-color') && $(el).data('text-color')) {
                  // restore previous selections (if applicable)
                  $(el)
                    .css({
                      'background-color': $(el).data('bg-color'),
                      color: $(el).data('text-color'),
                    })
                    .removeData('bg-color')
                    .removeData('text-color');
                }
              });
            }
          });
        handle_keyboard_checkbox('.dk-input input');

        if (IS_TOUCH) {
          // mobile / touch-enabled device
          config.touchCounter = $.map(
            $('.text-highlight-board span'),
            function () {
              return 0;
            }
          );

          $('body')
            .off('touchstart')
            .on('touchstart', '.text-highlight-board span', function (e) {
              e.stopPropagation();

              self.touchStartEl = this;
              var selectedColor = $('.' + self.classes.selected).css(
                  'background-color'
                ),
                selectedCode = $('.' + self.classes.selected).data(
                  'marker-code'
                ),
                markerIndex = $('.' + self.classes.selected).data(
                  'marker-index'
                ),
                inputName = $(this).data('name'),
                backgroundColor = $(this).css('background-color');

              if (!selectedColor) {
                return;
              }
              self.handleHighlight(
                this,
                backgroundColor,
                selectedColor,
                selectedCode,
                inputName,
                markerIndex
              );
            });

          var touchMoveHandler = function (evt) {
            if (evt.target === this) {
              if (evt.cancelable) {
                evt.preventDefault();
              }
              self._checkTouchover(evt.changedTouches);
            }
          };

          var spans = document.querySelectorAll('.text-highlight-board span');
          for (var i = 0; i < spans.length; i++) {
            spans[i].addEventListener('touchmove', touchMoveHandler);
          }
        } else {
          // desktop (most likely)
          $(window)
            .off('mouseup')
            .on('mouseup', function () {
              $('.text-highlight-board span').unbind('mouseover');
            });

          $('.text-highlight-board span')
            .off('mousedown')
            .on('mousedown', function (e) {
              var btn = e.which || e.button;
              var widgetId = $(this).attr('data-widget-id');
              var marker = $('#' + widgetId).find('.' + self.classes.selected);

              var selectedColor = marker.css('background-color'),
                selectedCode = marker.data('marker-code'),
                markerIndex = marker.data('marker-index'),
                inputName = $(this).data('name'),
                backgroundColor = $(this).css('background-color');

              if (!selectedColor) {
                return;
              }
              self.handleHighlight(
                this,
                backgroundColor,
                selectedColor,
                selectedCode,
                inputName,
                markerIndex
              );
              if (btn === 1) {
                $('.text-highlight-board span')
                  .off('mouseover')
                  .on('mouseover', function (e) {
                    (btn = e.which || e.button),
                      (inputName = $(this).data('name')); // jshint ignore:line
                    if (!btn || btn === 0) {
                      return;
                    }
                    self.handleHighlight(
                      this,
                      backgroundColor,
                      selectedColor,
                      selectedCode,
                      inputName,
                      markerIndex
                    );
                  });
              }
            });
        }

        mainNav.nextButton.bind('click keypress', $.proxy(self, '_next'));
      },

      setup_markers: function setup_markers(self) {
        var icon, style, color, elements;
        var queue = [];

        elements = document.querySelectorAll('.text-highlight-marker');
        elements.forEach((marker) => {
          // Push to Promise queue
          queue.push(
            new Promise((resolve) => {
              // Create timing interval for rendering data-attr
              marker['__timing__'] = setInterval(() => {
                // user-defined colors should resolve immediately
                if ($(marker).data('marker-color') !== '') {
                  clearInterval(marker['__timing__']);
                  resolve(true);
                  return;
                }
                style = window.getComputedStyle(marker);
                bgColor = style.getPropertyValue('border-color');
                if (bgColor !== 'rgba(0, 0, 0, 0)') {
                  clearInterval(marker['__timing__']);
                  if (
                    !$(marker).data('marker-color') ||
                    $(marker).data('marker-color') === '' ||
                    $(marker).data('marker-color') === 'transparent' ||
                    $(marker).data('marker-color') === 'rgba(0, 0, 0, 0)'
                  ) {
                    // Update data-attr so we can use this value later
                    // for previously selected answers
                    $(marker).data('marker-color', bgColor);
                    resolve(true);
                  }
                }
              }, 10);
            })
          );
        });

        Promise.all(queue).then(() => {
          _set_from_page_state();
        });

        // set defined values from page state
        function _set_from_page_state() {
          var name,
            code,
            selectedColor,
            markerIndex,
            values,
            markers = $(self.marker);
          $.each(
            $('.text-highlight-board input[type="hidden"]'),
            (i, input) => {
              if ($(input).val() !== '') {
                name = $(input).attr('name');
                code = $(input).val();
                $.each(markers, (i, marker) => {
                  if (
                    parseInt(code) === parseInt($(marker).data('marker-code'))
                  ) {
                    selectedColor = $(marker).css('border-color');
                    markerIndex = $(marker).data('marker-index');
                    values = $(self.textarea.eq(markerIndex)).val().split('\n');

                    $('.text-highlight-board span[data-name="' + name + '"]')
                      .css({
                        'background-color': selectedColor,
                        color: '#fff',
                      })
                      .attr('data-marker-index', markerIndex);

                    if (
                      $(self.textarea.eq(markerIndex)).val() === '' ||
                      values.indexOf(name) === -1
                    ) {
                      $(self.textarea.eq(markerIndex)).val(
                        $(self.textarea.eq(markerIndex)).val() + name + '\n'
                      );
                    }
                  }
                });
              }
            }
          );
        }
      },

      _calculateCoords: function _calculateCoords() {
        var self = this,
          config = self.config,
          $spot;

        config.coords = $.map(
          $('.text-highlight-board span'),
          function (spot, spotIndex) {
            $spot = $(spot);
            return {
              startX: $spot.offset().left,
              startY: $spot.offset().top,
              endX: $spot.offset().left + $spot.outerWidth(),
              endY: $spot.offset().top + $spot.outerHeight(),
            };
          }
        );
      },

      _checkTouchover: function _checkTouchover(touches) {
        var self = this,
          config = self.config,
          touch = touches[0], // only care for the first touch event
          touchX = touch.pageX,
          touchY = touch.pageY,
          isXY;

        $.each(config.coords, function (coordIndex, coord) {
          isXY =
            touch.pageX > coord.startX &&
            touch.pageX < coord.endX &&
            touch.pageY > coord.startY &&
            touch.pageY < coord.endY;

          if (isXY && config.touchCounter[coordIndex] === 0) {
            config.touchCounter[coordIndex]++;
            var $this = $('.text-highlight-board span').eq(coordIndex);
            var selectedColor = $('.' + self.classes.selected).css(
                'background-color'
              ),
              selectedCode = $('.' + self.classes.selected).data('marker-code'),
              markerIndex = $('.' + self.classes.selected).data('marker-index');
            var inputName = $this.data('name');
            var backgroundColor = $this.css('background-color');

            if ($this[0] !== self.touchStartEl) {
              self.handleHighlight(
                $this[0],
                backgroundColor,
                selectedColor,
                selectedCode,
                inputName,
                markerIndex
              );
            }
            return false;
          }
        });
      },

      _next: function _next(ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        if (IS_TOUCH) {
          $('body').off('touchstart');
          var spans = document.querySelectorAll('.text-highlight-board span');
          for (var i = 0; i < spans.length; i++) {
            spans[i].removeEventListener('touchmove', function () {});
          }
        } else {
          $('.text-highlight-board span')
            .unbind('mousedown')
            .unbind('mouseover');
        }
        click_next({ key: ev.key, type: ev.type });
      },

      _custom_colorder: function _custom_colorder(widget, order) {
        var self = this,
          $order = typeof order !== 'object' ? JSON.parse(order) : order;

        if ($(self.marker).length > 0) {
          var t_headers = self.marker,
            caption = self.selectors.markerCaption;
          for (var c in $order) {
            if (typeof $order[c] === 'number') {
              // Integer-based
              if (
                $(
                  self.selectors.marker +
                    '[data-marker-code="' +
                    $order[c] +
                    '"]'
                )
                  .find(caption)
                  .html() !== $(t_headers).last().find(caption).html()
              ) {
                $(
                  self.selectors.marker +
                    '[data-marker-code="' +
                    $order[c] +
                    '"]'
                )
                  .show()
                  .first()
                  .insertAfter($(self.selectors.marker).last());
              }
            } else {
              // String-based
              if (
                $(self.selectors.marker + ':contains("' + $order[c] + '")')
                  .text()
                  .trim() !== $(t_headers).last().find(caption).html()
              ) {
                $(self.selectors.marker + ':contains("' + $order[c] + '")')
                  .show()
                  .first()
                  .insertAfter($(self.selectors.marker).last());
              }
            }
          }
        }
      },
    },
    {
      types: ['text-highlight'],
      views: ['text-highlight'],
    }
  );

  TextHighlight.register();
  widgets.TextHighlight = TextHighlight;
})((Gryphon.widgets = Gryphon.widgets || {}));

'use strict';

export function _render(object) {
  console.log(object);
}

export default class {
  render(self, object) {
    _render(object);
  }
}

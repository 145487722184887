(function ($) {
  'use strict';

  /**
   * Super-simple equalising plugin. Use to equalise element
   * widths and heights to the maximum in the set.
   * @param {string} dimension Either "width", "height", "outerWidth",
   *     or "outerHeight". If it's one of the latter, then it's assumed
   *     that the elements you're working with are using `border-box`
   *     box-sizing: the elements' `height` or `width` is set to the max
   *     outer- height or width of the selected elements.
   * @param {boolean} [dryRun] If true, return size elements
   *     would have been set to without actually doing it.
   *
   * Example usages:
   * $("div").equalisr("width");
   * $(".item").equalisr("height");
   * var largestWidth = $(".box").equalisr("width", true);
   */
  function findMinMax(arr) {
    var min = arr[0],
      max = arr[0];

    for (var i = 1, len = arr.length; i < len; i++) {
      var v = arr[i];
      min = v < min ? v : min;
      max = v > max ? v : max;
    }
    return [min, max];
  }
  $.fn.equalisr = function (dimension, dryRun) {
    var max = 0;
    var elms = this;

    var calculate = function () {
      elms.each(function () {
        var current = $(this)[dimension]();

        max = current > max ? current : max;
      });

      if (dryRun) {
        return max;
      } else {
        dimension = dimension.replace(/^outer/, '').toLowerCase();
        return elms[dimension](max);
      }
    };
    // Wait until the document has fully loaded before
    // calculating the element dimensions
    var timedout,
      startTime = new Date().getTime();
    var stateChange = setInterval(function () {
      // Timeout and calculate after 30 seconds
      timedout = new Date().getTime() - startTime > 30000;
      var minMax = findMinMax(
        elms.map(function () {
          return $(this)[dimension]();
        })
      );
      if (
        (document.readyState === 'complete' && minMax[1] > minMax[0]) ||
        timedout
      ) {
        clearInterval(stateChange);
        calculate();
      }
    }, 50);
  };
})(jQuery);

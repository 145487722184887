var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '    ', 'error', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  return "      mainNav.hide();\n";
  },"5":function(depth0,helpers,partials,data) {
  return "autostart: true,";
  },"7":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "width: "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.player : depth0)) != null ? stack1.width : stack1), depth0))
    + ",";
},"9":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "height: "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.player : depth0)) != null ? stack1.height : stack1), depth0))
    + ",";
},"11":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "            {file: '";
  stack1 = lambda((depth0 != null ? depth0.src : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "'}";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.$last : depth0), {"name":"unless","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"12":function(depth0,helpers,partials,data) {
  return ",";
  },"14":function(depth0,helpers,partials,data) {
  return "        mainNav.show();\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"question question-video\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n  <div id=\"videoplayer\">\n    <div id=\"player\"></div>\n    <input type=\"hidden\" name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.submit_input_id : depth0), depth0))
    + "\" value=\"1\" />\n    <input\n      type=\"hidden\"\n      name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.watched_input_id : depth0), depth0))
    + "\"\n      id=\"video-watched\"\n      value=\"0\"\n    />\n    <input type=\"hidden\" name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.error_input_id : depth0), depth0))
    + "\" id=\"video-error\" value=\"\" />\n  </div>\n</div>\n\n<script>\n  var has_flash = Gryphon.util.has_flash();\n\n  Gryphon.util.getScript('/static/js/jwplayer.js', function () {\n      var plays = "
    + escapeExpression(lambda((depth0 != null ? depth0.plays : depth0), depth0))
    + ";\n      var total_plays = 0;\n      var watchedVal = page_state.form_data['"
    + escapeExpression(lambda((depth0 != null ? depth0.watched_input_id : depth0), depth0))
    + "'] || '0';\n      var watchedInput = $('input[name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.watched_input_id : depth0), depth0))
    + "\"]');\n\n      watchedInput.val(watchedVal);\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hide_buttons : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n      var player = jwplayer('player');\n\n      player.setup({\n        flashplayer: '/static/js/jwplayer.flash.swf',\n        html5player: '/static/js/jwplayer.html5.js',\n        ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.player : depth0)) != null ? stack1.autoplay : stack1), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n        ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.player : depth0)) != null ? stack1.width : stack1), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n        ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.player : depth0)) != null ? stack1.height : stack1), {"name":"if","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n        playlist: [{\n          sources: [\n";
  stack1 = __default(require("../js/helpers/foreach.js")).call(depth0, ((stack1 = (depth0 != null ? depth0.player : depth0)) != null ? stack1.sources : stack1), {"name":"foreach","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "          ]\n        }]\n      });\n\n      player.onPlaylistComplete(function () {\n        watchedInput.val('"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.response_codes : depth0)) != null ? stack1.WATCHED : stack1), depth0))
    + "');\n\n        total_plays++;\n\n        if (plays > 0 && total_plays >= plays) {\n            $('#next_button').click();\n            return;\n        }\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hide_buttons : depth0), {"name":"if","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      });\n\n      player.onError(function () {\n        $('#video-error').val('"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.response_codes : depth0)) != null ? stack1.ERROR : stack1), depth0))
    + "');\n        mainNav.show();\n        $('#next_button').click();\n      });\n  });\n</script>\n";
},"usePartial":true,"useData":true});
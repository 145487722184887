var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "    <textarea\n        id=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "\"\n        name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "\"\n        rows=\""
    + escapeExpression(lambda((depth0 != null ? depth0.rows : depth0), depth0))
    + "\"\n        cols=\""
    + escapeExpression(lambda((depth0 != null ? depth0.cols : depth0), depth0))
    + "\"\n";
  stack1 = __default(require("../js/helpers/ifanswer.js")).call(depth0, (depth0 != null ? depth0.dk_id : depth0), "on", {"name":"ifanswer","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        aria-labelledby=\""
    + escapeExpression(lambda((depth0 != null ? depth0.element_id : depth0), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "label\"\n        tabindex=\"0\"\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    >"
    + escapeExpression(__default(require("../js/helpers/ans.js")).call(depth0, (depth0 != null ? depth0.answer : depth0), (depth0 != null ? depth0.input_id : depth0), {"name":"ans","hash":{},"data":data}))
    + "</textarea>\n";
},"2":function(depth0,helpers,partials,data) {
  return "        readonly=\"readonly\"\n";
  },"4":function(depth0,helpers,partials,data) {
  return "        class=\"alert-error\"\n";
  },"6":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "    <input\n        id=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "\"\n        name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "\"\n";
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, (depth0 != null ? depth0.type : depth0), "password", {"name":"ifequal","hash":{},"fn":this.program(7, data),"inverse":this.program(9, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        value=\""
    + escapeExpression(__default(require("../js/helpers/ans.js")).call(depth0, (depth0 != null ? depth0.answer : depth0), (depth0 != null ? depth0.input_id : depth0), {"name":"ans","hash":{},"data":data}))
    + "\"\n";
  stack1 = __default(require("../js/helpers/ifanswer.js")).call(depth0, (depth0 != null ? depth0.dk_id : depth0), "on", {"name":"ifanswer","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.prompt : depth0), {"name":"if","hash":{},"fn":this.program(11, data),"inverse":this.program(13, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        tabindex=\"0\"\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(15, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        >\n";
},"7":function(depth0,helpers,partials,data) {
  return "        type=\"password\"\n";
  },"9":function(depth0,helpers,partials,data) {
  return "        type=\"text\"\n";
  },"11":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "        aria-label=\"Input for "
    + escapeExpression(lambda((depth0 != null ? depth0.prompt : depth0), depth0))
    + "\"\n";
},"13":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "        aria-labelledby=\""
    + escapeExpression(lambda((depth0 != null ? depth0.element_id : depth0), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "label\"\n";
},"15":function(depth0,helpers,partials,data) {
  return "        class=\"alert alert-error\"\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = __default(require("../js/helpers/ifcond.js")).call(depth0, (depth0 != null ? depth0.rows : depth0), ">", 1, {"name":"ifcond","hash":{},"fn":this.program(1, data),"inverse":this.program(6, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
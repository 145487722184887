(function (widgets) {
  var IS_TOUCH = (function () {
    var check = false;
    /* eslint-disable no-useless-escape*/
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      ) {
        check = true;
      }
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  })();

  var Upload = widgets.Widget.extend(
    {
      render: function render() {
        Upload.__super__.render.call(this);
        var self = this,
          object = this._object,
          form = (this.form = this.$el.closest('form'));
        self.clickEvent = 'click keypress';
        form.attr('enctype', 'multipart/form-data');
        self.fileBuffer = [];
        self.errorList = [];

        // [GRYP-9785]
        // required=SOFT will submit to the back-end and can
        // return an 'error_message' value, unhide in this case
        if (self._object.error_message !== '') {
          $('.alert-error').removeClass('hide');
        }

        $.validator.methods.filemissing = function (value, element, param) {
          element = self._object;
          if (element.required === 'HARD' && !element.files) {
            return false;
          } else {
            return true;
          }
        };

        $.validator.methods.filesize = function (value, element, param) {
          var i, file;
          element = self._object; // evaluate the mutable _object.files array

          if (element.files) {
            // Check if the element has a FileList before checking each file
            if (element.files.length > 0) {
              for (i = 0; i < element.files.length; i++) {
                file = element.files[i];

                // Grab the filesize of each file (in megabytes)
                if (parseFloat(file.size / 1000000) > param) {
                  return false;
                }
              }
            }
            return true;
          }
          return true;
        };

        $.validator.methods.minnumber = function (value, element, param) {
          element = self._object; // evaluate the mutable _object.files array
          if (element.required === 'HARD') {
            if (element.files) {
              if (param > 0 && element.files.length < param) {
                return false;
              }
              return parseInt(element.files.length) >= param;
            }
            return false;
          } else {
            return true;
          }
        };

        this.validator = form.validate({
          showErrors: function (errorMap, errorList) {
            self.errorList = errorList;
            self._reset(errorList);
            if (errorList.length > 0) {
              var err_msg = '';
              for (var i = 0; i < errorList.length; i++) {
                err_msg += errorList[i].message;
              }
              $('.alert-error').removeClass('hide').text(err_msg);
              $(window).resize();
            }
          },
          submitHandler: function (element, event) {},
        });
        $('#r-upload-' + this._object.input_id).rules('add', {
          filemissing: 1,
          filesize: self._object.max_filesize_MB,
          minnumber: self._object.min_number,
          messages: {
            // [GRYP-9785] Use 'required_text' if provided
            filemissing: jQuery.validator.format(
              self._object.required_text
                ? self._object.required_text
                : self._object.min_number_text
            ),
            filesize: jQuery.validator.format(self._object.max_filesize_text),
            minnumber: jQuery.validator.format(self._object.min_number_text),
          },
        });
        if (IS_TOUCH) {
          // we don't need to display the label for drag and drop on mobile
          $('.input-container label').hide();
          self.clickEvent = 'touchend';
        }

        this._events(self);
      },

      _reset: function _reset(errorList) {
        var self = this;
        if (!errorList) {
          $('.file-list').hide().text('');
          $('.alert-error').hide();
        }
        self.fileBuffer =
          typeof self._object.files === 'object' ? self._object.files : [];
        if (typeof errorList === 'boolean' && errorList === false) {
          $('#r-upload-' + this._object.input_id)[0].value = '';
        }
      },

      _events: function _events(self) {
        $('button[role="button"]').on(self.clickEvent, function (event) {
          $('#r-upload-' + self._object.input_id).trigger('click');
        });

        // When file input changes, auto focus out so our validations can be initialized
        $('#r-upload-' + self._object.input_id).on(
          'change',
          function (event, files, type) {
            self.fileBuffer =
              typeof self._object.files === 'object' &&
              (!files || type === 'onDrop')
                ? self._object.files
                : [];
            var filesBuffer = files ? files : $(this)[0].files;

            if (type === 'onDelete') {
              self._object.files = self.fileBuffer;
              self.fileBuffer = [];
            }

            let param = self._object.file_types;
            param =
              typeof param === 'string'
                ? param.replace(/\s/g, '').replace(/,/g, '|')
                : 'image/*,video/*,text/*';

            if (filesBuffer.length > 0) {
              param = param.replace(/\*/g, '.*');

              for (var i = 0; i < filesBuffer.length; i++) {
                if (
                  !filesBuffer[0].type.match(
                    new RegExp('.?(' + param + ')$', 'i')
                  )
                ) {
                  $('.alert-error').html(self._object.filetype_text).show();
                  return;
                }
              }
            }

            if (
              (self._object.files &&
                filesBuffer &&
                parseInt(filesBuffer.length) +
                  parseInt(self._object.files.length) >
                  parseInt(self._object.max_number)) ||
              (filesBuffer &&
                parseInt(filesBuffer.length) >
                  parseInt(self._object.max_number))
            ) {
              $('.alert-error')
                .html(
                  self._object.max_number_text.replace(
                    '{0}',
                    self._object.max_number
                  )
                )
                .show();
              return;
            }

            Array.prototype.push.apply(self.fileBuffer, filesBuffer);
            self._object.files = self.fileBuffer;
            var valid = self.validator.element(
              '#r-upload-' + self._object.input_id
            );
            if (!valid) {
              self._object.files = [];
              return;
            }
            self._displayFiles(self.fileBuffer);
          }
        );

        if (!IS_TOUCH) {
          // Drag and drop
          var dropbox;
          dropbox = document.getElementById('upload-dropbox');
          dropbox.addEventListener('dragenter', onDragEnter, false);
          dropbox.addEventListener('dragleave', onDragLeave, false);
          dropbox.addEventListener('drop', onDrop, false);
          document.addEventListener('dragover', function (e) {
            e.preventDefault();
          });
        }

        function onDragEnter(e) {
          e.stopPropagation();
          e.preventDefault();
          $('#upload-dropbox').css({
            'background-color': 'var(--color-stonegrey-300)',
            border: '0px',
          });
          $('#upload-dropbox .response-button').css({
            'background-color': 'var(--color-blueberry-1100)',
          });
        }

        function onDragLeave(e) {
          if (e.target.className === 'upload-container') {
            return;
          }
          e.stopPropagation();
          e.preventDefault();
          $('#upload-dropbox').css({
            border: '1px dashed var(--color-stonegrey-900)',
            'background-color': 'var(--color-white)',
          });
          $('#upload-dropbox .response-button').css({
            'background-color': 'var(--color-blueberry-1000)',
          });
        }

        function onDrop(e) {
          e.stopPropagation();
          e.preventDefault();

          var dt = e.dataTransfer,
            files = dt.files;

          $('#upload-dropbox').css({
            border: '1px dashed var(--color-stonegrey-900)',
            'background-color': 'var(--color-white)',
          });
          $('#upload-dropbox .response-button').css({
            'background-color': 'var(--color-blueberry-1000)',
          });

          $('#r-upload-' + self._object.input_id).trigger('change', [
            files,
            'onDrop',
          ]);
        }

        mainNav.nextButton.bind(self.clickEvent, $.proxy(self, '_upload'));
      },

      _bindRemove: function _bindRemove(element) {
        var self = this;
        var files = $('.file-list > div');

        $.each(files, function (idx, row) {
          var removeBtn = $(row).find('button');
          removeBtn.off(self.clickEvent).on(self.clickEvent, function (event) {
            var idx = $(this).data('idx');
            if ($('#file-row-' + idx)) {
              $('#file-row-' + idx).remove();
              self._object.files.splice(idx, 1);
            }
            if (self._object.files.length === 0) {
              self._reset(false);
            } else {
              $('#r-upload-' + self._object.input_id).trigger('change', [
                self._object.files,
                'onDelete',
              ]);
            }
          });
        });
      },

      _displayFiles: function _displayFiles(files) {
        var self = this;
        $('.alert-error').hide();
        if (!files || files.length === 0 || self.errorList.length > 0) {
          return;
        }
        $('.file-list').show().text('');
        for (var i = 0; i < files.length; i++) {
          var html =
            '<div id="file-row-' +
            i +
            '"><span class="icon-file"></span> ' +
            files[i].name +
            ' <em>(' +
            parseFloat(files[i].size / 1000000).toFixed(2) +
            ' MB)</em> <button class="btn-remove" type="button" data-idx="' +
            i +
            '"><span class="icon-remove"></span></button></div>';
          $('.file-list').append(html);
        }
        self._bindRemove();
      },

      _upload: function _upload(ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        var self = this;
        var xhr,
          fd,
          inputVal = [];
        var completed = 0;

        if (!self.form.valid()) {
          return;
        }
        if (!self._object.files || self._object.files.length === 0) {
          click_next({ key: ev.key, type: ev.type });
          return;
        }

        var unsupportedFileNames = [];
        function onLoadEnd(e) {
          try {
            var res = JSON.parse(this.response);
            if (res.status === 'success' && res.file) {
              inputVal.push(res.file);
              $('#r-' + self._object.input_id).val(inputVal.toString());
              completed++;
              if (completed === self._object.files.length) {
                click_next({ key: ev.key, type: ev.type });
              }
            } else if (
              res &&
              res.status === 'failed' &&
              e.srcElement &&
              e.srcElement.status === 406 &&
              e.srcElement.statusText === 'Not Acceptable'
            ) {
              unsupportedFileNames.push(res.file);
              $('.alert-error')
                .text(
                  'The specified file name(s) ' +
                    unsupportedFileNames.join(', ') +
                    ' could not be uploaded due to the file type(s) not being supported.'
                )
                .show();
            } else {
              $('.alert-error').html(res.message).show();
            }
          } catch (exc) {}
        }

        for (var i = 0; i < self._object.files.length; i++) {
          xhr = new XMLHttpRequest();
          fd = new FormData();

          // Local Development uses a Proxy
          var uploadUrl = '/_upload';

          // Everything else expects to be given the base URL
          if (process.env.ENVIRONMENT !== 'development') {
            uploadUrl =
              window.context_name === 'preview'
                ? `${process.env.PROXY_GRYPHON_API_URL}/_upload`
                : `${process.env.PROXY_IVW_URL}/_upload`;
          }

          xhr.open('POST', uploadUrl, true);

          if (window.context_name === 'preview') {
            xhr.withCredentials = true;
          }

          xhr.addEventListener('loadend', onLoadEnd);
          fd.append('questionnaireName', self._object.questionnaire_name);
          fd.append('personId', self._object.person_id);
          fd.append('userId', self._object.user_id);
          fd.append('userFile', self._object.files[i]);
          fd.append('variable', self._object.varname);
          // Initiate a multipart/form-data upload
          xhr.send(fd);
        }
      },

      destroy: function destroy() {
        Upload.__super__.destroy.call(this);
      },
    },
    {
      types: ['upload'],
      views: ['upload'],
    }
  );

  Upload.register();
  widgets.Upload = Upload;
})((Gryphon.widgets = Gryphon.widgets || {}));

module.exports = function (value, left, operator, right, options) {
  var allowedOperators = ['<', '==', '!=', '>'];

  if (!value || isNaN(right) || allowedOperators.indexOf(operator) < 0) {
    return options.inverse(this);
  }
  return handlebarsIfCond.call(
    this,
    value.indexOf(left),
    operator,
    parseInt(right, 10),
    options
  );
};

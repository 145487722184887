var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  return "class=\"response-label\"";
  },"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.skinny : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var stack1, buffer = "  <span class=\"label-inner label-inner-input-text\">\n    <span class=\"label-text\">";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.text : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.program(7, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</span>\n  </span>\n  <div class=\"input-wrapper\">\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda;
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { return stack1; }
  else { return ''; }
  },"7":function(depth0,helpers,partials,data) {
  return "&nbsp;";
  },"9":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "    size=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_width : depth0), depth0))
    + "\"\n";
},"11":function(depth0,helpers,partials,data) {
  return "    oninput=\"this.value=this.value.replace(/[^0-9]/g,'')\"\n";
  },"13":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "    data-xor-group=\""
    + escapeExpression(lambda((depth0 != null ? depth0.category_name : depth0), depth0))
    + "\"\n";
},"15":function(depth0,helpers,partials,data) {
  return " hidden-response";
  },"17":function(depth0,helpers,partials,data) {
  return " grid-input-error";
  },"19":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = __default(require("../js/helpers/ifcond.js")).call(depth0, (depth0 != null ? depth0.input_type : depth0), "!=", "number", {"name":"ifcond","hash":{},"fn":this.program(20, data),"inverse":this.program(23, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"20":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "    value=\""
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\"\n";
  stack1 = __default(require("../js/helpers/ifans.js")).call(depth0, (depth0 != null ? depth0.answer : depth0), (depth0 != null ? depth0.name : depth0), (depth0 != null ? depth0.code : depth0), {"name":"ifans","hash":{},"fn":this.program(21, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"21":function(depth0,helpers,partials,data) {
  return "    checked=\"checked\"\n";
  },"23":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression;
  return "    value=\""
    + escapeExpression(__default(require("../js/helpers/answer.js")).call(depth0, (depth0 != null ? depth0.name : depth0), (depth0 != null ? depth0.code : depth0), {"name":"answer","hash":{},"data":data}))
    + "\"\n";
},"25":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "    aria-label=\""
    + escapeExpression(__default(require("../js/helpers/getText.js")).call(depth0, (depth0 != null ? depth0.label_cat : depth0), {"name":"getText","hash":{},"data":data}))
    + ": "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.label_resp : depth0)) != null ? stack1.text : stack1), depth0))
    + "\"\n";
},"27":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.skinny : depth0), {"name":"if","hash":{},"fn":this.program(28, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"28":function(depth0,helpers,partials,data) {
  return "  </div>\n";
  },"30":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.skinny : depth0), {"name":"if","hash":{},"fn":this.program(31, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"31":function(depth0,helpers,partials,data) {
  var stack1, buffer = "  <span class=\"label-inner\">\n    <span class=\"label-text\">";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.text : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.program(7, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</span>\n  </span>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<label for=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.skinny : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n";
  stack1 = __default(require("../js/helpers/ifcond.js")).call(depth0, (depth0 != null ? depth0.input_type : depth0), "==", "text", {"name":"ifcond","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n  <input\n    id=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\"\n    type=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_type : depth0), depth0))
    + "\"\n    data-column=\""
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\"\n    name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "\"\n    max=\""
    + escapeExpression(lambda((depth0 != null ? depth0.colsum : depth0), depth0))
    + "\"\n";
  stack1 = __default(require("../js/helpers/ifcond.js")).call(depth0, (depth0 != null ? depth0.input_type : depth0), "==", "text", {"name":"ifcond","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = __default(require("../js/helpers/ifcond.js")).call(depth0, (depth0 != null ? depth0.input_type : depth0), "==", "number", {"name":"ifcond","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, (depth0 != null ? depth0.input_type : depth0), "checkbox", {"name":"ifequal","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    class=\""
    + escapeExpression(lambda((depth0 != null ? depth0.dk_id : depth0), depth0))
    + " "
    + escapeExpression(lambda((depth0 != null ? depth0.input_class : depth0), depth0));
  stack1 = __default(require("../js/helpers/ifanswer.js")).call(depth0, (depth0 != null ? depth0.dk_id : depth0), "on", {"name":"ifanswer","hash":{},"fn":this.program(15, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = __default(require("../js/helpers/ifcond.js")).call(depth0, __default(require("../js/helpers/answer.js")).call(depth0, (depth0 != null ? depth0.name : depth0), (depth0 != null ? depth0.code : depth0), {"name":"answer","hash":{},"data":data}), ">", (depth0 != null ? depth0.colsum : depth0), {"name":"ifcond","hash":{},"fn":this.program(17, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n";
  stack1 = __default(require("../js/helpers/ifcond.js")).call(depth0, (depth0 != null ? depth0.input_type : depth0), "!=", "text", {"name":"ifcond","hash":{},"fn":this.program(19, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.label_cat : depth0), {"name":"if","hash":{},"fn":this.program(25, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  >\n";
  stack1 = __default(require("../js/helpers/ifcond.js")).call(depth0, (depth0 != null ? depth0.input_type : depth0), "==", "text", {"name":"ifcond","hash":{},"fn":this.program(27, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = __default(require("../js/helpers/ifcond.js")).call(depth0, (depth0 != null ? depth0.input_type : depth0), "!=", "text", {"name":"ifcond","hash":{},"fn":this.program(30, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</label>\n";
},"useData":true});
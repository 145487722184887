/* global
  ga: true
*/

Gryphon.analytics = {
  // Google analytics event tracing.
  trackEvent: function (category, action, label, value) {
    /*
    Value     Type    Required  Description
    -----     ----    --------  -----------
    Category  String  Yes       Typically the object that was interacted with
                                (e.g. button)
    Action    String  Yes       The type of interaction (e.g. click)
    Label     String  No        Useful for categorizing events
                                (e.g. nav buttons)
    Value     Number  No        Values must be non-negative.
                                Useful to pass counts (e.g. 4 times)
    */

    if (!window.ga) {
      // Return true regardless of result. Callers don't need to know if
      // anything went wrong.
      return true;
    }

    // The following code is non-blocking. Any additional code or execution
    // should go in the timeout.
    setTimeout(function () {
      ga('send', {
        hitType: 'event',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value,
      });
    }, 0);

    return true;
  },
};

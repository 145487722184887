import Handlebars from 'handlebars/dist/handlebars';

(function ($) {
  'use strict';

  var arraySlice = Array.prototype.slice;
  var hasOwnProperty = Object.prototype.hasOwnProperty;
  window.arraySlice = arraySlice;
  window.hasOwnProperty = hasOwnProperty; // jshint ignore:line

  function handlebarsIfCond(left, operator, right, options) {
    /*jshint validthis: true */
    var self = this;

    function returnResult(val) {
      return val ? options.fn(self) : options.inverse(self);
    }

    switch (operator) {
      case '==':
        return returnResult(left === right);
      case '!=':
        return returnResult(left !== right);
      case '===':
        return returnResult(left === right);
      case '!==':
        return returnResult(left !== right);
      case '<':
        return returnResult(left < right);
      case '<=':
        return returnResult(left <= right);
      case '>':
        return returnResult(left > right);
      case '>=':
        return returnResult(left >= right);
      case 'and':
        return returnResult(left && right);
      case 'or':
        return returnResult(left || right);
      case 'in':
        return returnResult(
          left ? right.split(/[,][\s?]+/).indexOf(left) >= 0 : false
        );
      default:
        return returnResult(false);
    }
  }
  window.handlebarsIfCond = handlebarsIfCond;

  function getAnswer(name) {
    return page_state.form_data[name];
  }
  window.getAnswer = getAnswer;

  function getDeepProperty(object, propertyList) {
    if (!object) {
      return;
    }

    var length = propertyList.length;
    var result = object;

    for (var i = 0; i < length; i++) {
      if (typeof result === 'object' && propertyList[i] in result) {
        result = result[propertyList[i]];
      } else {
        return;
      }
    }

    return result;
  }
  window.getDeepProperty = getDeepProperty;

  var helpers = {
    /**
     * Return sum of two values.
     *
     * @param {number|string} a First value
     * @param {number|string} b Second value
     */
    add: function (a, b) {
      return a + b;
    },
    /**
     * Return result of a mathematical expression.
     *
     * @param {number|string} dynamic
     */
    calculate: function () {
      var expression = '';

      // skip the last argument being a handlebars object
      for (var i = 0; i < arguments.length - 1; ++i) {
        expression = expression + String(arguments[i]);
      }

      return Function(`'use strict'; return (${expression})`)();
    },
    /**
     * Block helper for checking whether a value exists in an array.
     * @param {*} value The value to search for.
     * @param {Array} array The array to search in.
     */
    contains: function (value, array, options) {
      if ($.inArray(value, array) !== -1) {
        return options.fn(this);
      } else {
        return options.inverse(this);
      }
    },
    filesize: function (size, options) {
      return Handlebars.helpers.upper(
        filesize(size, $.extend({ round: 0 }, JSON.parse(options || '{}')))
      );
    },
    forloop: function (from, to, incr, block) {
      var accum = '';
      for (var i = from; i < to; i += incr) {
        accum += block.fn(i);
      }
      return accum;
    },
    /*
     * Format dates using momentjs.
     * @param {string} date A valid daformatDatete time string.
     * @param {string} format The momentjs format.
     */
    formatDate: function (date, format) {
      format = format || 'lll';
      if (format == 'calendar') {
        return moment(date).calendar();
      }

      var months = moment(Date.now()).diff(moment(date), 'months', true);
      if (months >= 11) {
        format = format + ' YYYY';
      }

      return moment(date).format(format);
    },
    reverseArray: function (array) {
      return array.reverse();
    },
    getFirstObjectValue: function (object) {
      return object[Object.keys(object).shift()];
    },
    getFirstObjectKey: function (object) {
      return Object.keys(object).shift();
    },
    getLastObjectValue: function (object) {
      return object[Object.keys(object).pop()];
    },
    getLastObjectKey: function (object) {
      return Object.keys(object).pop();
    },
    upper: function (options) {
      if (!options) {
        return '';
      }

      // Only use this helper when the content is expected to be just text.
      if (typeof options === 'string') {
        return options.toUpperCase();
      }

      var text = options.fn(this);
      return typeof text === 'string' ? text.toUpperCase() : '';
    },
    lower: function (options) {
      if (!options) {
        return '';
      }

      // Only use this helper when the content is expected to be just text.
      if (typeof options === 'string') {
        return options.toLowerCase();
      }

      var text = options.fn(this);
      return typeof text === 'string' ? text.toLowerCase() : '';
    },
    startsWith: function (text, value, options) {
      return text.indexOf(value) === 0
        ? options.fn(this)
        : options.inverse(this);
    },
    splitItem: function (str, separator, index, options) {
      return str.split(separator)[parseInt(index)] || '';
    },
    iflike: function (left, right, options) {
      // Standard equality. e.g. '1' == 1 // => true.
      return left == right ? options.fn(this) : options.inverse(this);
    },
    ifequal: function (left, right, options) {
      // Strict equality. e.g. '1' === 1 // => false.
      return left === right ? options.fn(this) : options.inverse(this);
    },
    unlessequal: function (left, right, options) {
      return left === right ? options.inverse(this) : options.fn(this);
    },
    ifcond: function (left, operator, right, options) {
      return handlebarsIfCond.call(this, left, operator, right, options);
    },
    /**
     * Like the 'if' block helper, but accepts any number of arguments. If any
     * are truthy, return the main block, otherwise return the else block.
     */
    or: function () {
      var len = arguments.length - 1;
      var args = arraySlice.call(arguments, 0, len);
      var options = arguments[len];

      for (var i = 0; i < len; i++) {
        if (args[i]) {
          return options.fn(this);
        }
      }

      return options.inverse(this);
    },
    unlesscond: function (left, operator, right, options) {
      return handlebarsIfCond.call(this, right, operator, left, options);
    },
    ifindexof: function (value, left, operator, right, options) {
      var allowedOperators = ['<', '==', '!=', '>'];

      if (!value || isNaN(right) || allowedOperators.indexOf(operator) < 0) {
        return options.inverse(this);
      }
      return handlebarsIfCond.call(
        this,
        value.indexOf(left),
        operator,
        parseInt(right, 10),
        options
      );
    },
    eachkeys: function (context, options) {
      var ret = '';

      if (!context) {
        return options.inverse(this);
      }

      for (var key in context) {
        ret += options.fn({ key: key, value: context[key] });
      }

      return ret;
    },
    /**
     * Similar to the built-in `each` helper, but limit iteration to the first
     * `limit` items. If there are more items than `limit`, then the `else`
     * branch is executed *in addition*. This is unlike the built-in each,
     * where it is executed only if the list is empty.
     *
     * The `else` branch is passed a private variable `excess`, which
     * represents how many excess items there are.
     *
     * @param {array} context A list of items.
     * @param {number} limit The number of items to limit the iteration to.
     */
    eachlimit: function (context, limit, options) {
      var items = context.length;
      var excess = items - limit;
      var data, dataExcess;
      var ret = '';

      if (options.data) {
        data = Handlebars.createFrame(options.data);
      }

      if (excess > 0) {
        if (options.data) {
          dataExcess = Handlebars.createFrame(options.data);
          dataExcess.excess = excess;
        }
      } else {
        limit = items;
      }

      for (var i = 0; i < limit; i++) {
        if (data) {
          data.index = i;
        }
        ret += options.fn(context[i], { data: data });
      }

      if (dataExcess) {
        ret += options.inverse(this, { data: dataExcess });
      }

      return ret;
    },
    item: function (context, options) {
      if (context.length > 0 && context[0] === 'Aqua') {
        // placement colors (multiples of 10 to support more than 10)
        return context[options % 10];
      }
      return context[options];
    },
    ifitem: function (context, key, value, options) {
      var yes = String(context[key]) === String(value);
      return yes ? options.fn(this) : options.inverse(this);
    },
    ifmod: function (left, right, options) {
      return left % right ? options.fn(this) : options.inverse(this);
    },
    foreach: function (arr, options) {
      if (options.inverse && !arr.length) {
        return options.inverse(this);
      }

      return arr
        .map(function (item, index) {
          if (typeof item === 'string') {
            item = String(item);
          }

          item.$index = index;
          item.$first = index === 0;
          item.$last = index === arr.length - 1;

          return options.fn(item);
        })
        .join('');
    },
    /**
     * Checks that the current grid phase is not equal to the value passed in.
     * @param {number} phase The current phase.
     * @param {number} index The phase iteration number to check against.
     */
    ifnotphase: function (phase, index, options) {
      // phase is `undefined` when the grid doesn't use displaymax, so provide
      // a default.
      phase = phase || 0;
      if (phase !== index) {
        return options.fn(this);
      } else {
        return options.inverse(this);
      }
    },
    /**
     * Conditional to check whether or not there's an answer at all.
     * @param {string} name The input name to check for.
     */
    ifanswerexists: function (name, options) {
      if (name in page_state.form_data) {
        return options.fn(this);
      } else {
        return options.inverse(this);
      }
    },
    ifanswer: function (name, value, options) {
      if (
        // If it's a regular single code grid, and the result is
        // the given value.
        getAnswer(name) === String(value) ||
        getAnswer(name) === 'on' ||
        getAnswer(name + '-' + String(value))
      ) {
        return options.fn(this);
      }

      return options.inverse(this);
    },
    /**
     * Argh. Wrapper around ifanswer so that it also makes use of passed-in
     * answers. Defers to ifanswer if passed null.
     * @param {*} answer Current value of questionnaire variable.
     * @param {string} name Form input name.
     * @param {(string|number)} value The value to check the answer against.
     * @param {Object} options The options object passed in by Handlebars.
     */
    ifans: function (answer, name, value, options) {
      if ($.isArray(answer) && $.inArray(value, answer) !== -1) {
        return options.fn(this);
      }

      if (value === answer) {
        return options.fn(this);
      }

      return helpers.ifanswer.call(this, name, value, options);
    },
    /**
     * Compare a ``first-last`` named answer's value to ``value``.
     * This use case isn't covered by ifanswer, which is already overloaded
     * enough.
     * @param {string} first The first part of the answer's name, e.g., ``id``.
     * @param {string} last The last part of the answer's name, e.g., ``code``.
     * @param {(string|number)} value The value to check the answer against.
     * @param {Object} options The options object passed in by Handlebars.
     */
    ifansweris: function (first, last, value, options) {
      var answer = getAnswer(first + '-' + last);
      if (answer === value) {
        return options.fn(this);
      } else {
        return options.inverse(this);
      }
    },
    unlessanswer: function (name, value, options) {
      if (
        // If it's a regular single code grid, and the result is
        // the given value.
        getAnswer(name) === String(value) ||
        getAnswer(name) === 'on' ||
        getAnswer(name + '-' + String(value))
      ) {
        return options.inverse(this);
      }

      return options.fn(this);
    },
    /**
     * Get an answer from the form data.
     * @param {string} name The question id.
     * @param {string} [value] The question id modifier. Tries to access
     *     <name>-<value> key, falling back to <name> if it's falsey.
     * Note: this will not work as a block helper.
     */
    answer: function (name, value) {
      var result = getAnswer(name + '-' + String(value));
      return result || getAnswer(name);
    },
    /**
     * Returns the passed-in answer if not `null` or `undefined`, otherwise
     * defers to the `answer` helper.
     * @param {*} answer Current value of questionnaire variable.
     * @param {string} name The question id.
     * @param {string} [value] The question id modifier. Tries to access
     *     <name>-<value> key, falling back to <name> if it's falsey.
     */
    ans: function (answer, name, value) {
      if (answer == null) {
        return helpers.answer.call(this, name, value);
      }

      return answer;
    },
    /**
     * Set properties on the given context via the options hash. Note that
     * this will *mutate* the object originally passed into the template!
     *
     * This is useful, for instance, for making a parent loop's private @index
     * variable available within a nested loop. For example:
     *
     *     {{#each items}}
     *     {{set this parent_index=@index}}
     *     {{#each subitems}}
     *     subitem index: {{@index}}, item index: {{../parent_index}}
     *     {{/each}}
     *     {{/each}}
     */
    set: function (context, options) {
      $.extend(context, options.hash);
    },
    /**
     * Access the given property on the given context.
     *
     * Useful, for example, for looking up values in another structure based on
     * the current @index in a loop, combined with Handlebars's support for
     * subexpressions.
     *
     * @param {object|array} context The object which contains the property.
     * @param {string} property The property name.
     */
    get: function (context, property) {
      return context[property];
    },
    getText: function (context) {
      return context.match(/([A-Z])\w+/g).join(' ');
    },
    /**
     * Inject a partial of the given name, feeding it with a context and/or
     * key/value pairs.
     *
     * Note that key/value pairs will overwrite keys of the same name in the
     * passed-in context.
     *
     * Because our partials and templates live in the same namespace, this
     * could technically inject a template as well. The difference is that
     * partials tend to expect relatively flat, simple data, whereas templates
     * tend to iterate over complex structures, while referencing shallower
     * properties.
     *
     * This helper makes it easy to pass arbitrary flat structures to the
     * partial.
     *
     * Example usages:
     *     {{{partial 'button' name=name handler=../action}}}
     *     {{{partial 'button' @root example=true}}}
     *     {{{partial 'button' this}}}
     */
    partial: function (name, context, options) {
      if (options === undefined) {
        options = context;
        context = {};
      }

      $.extend(context, options.hash);
      return Gryphon.templates[name](context);
    },
    debug: function () {
      /* jshint devel: true */
      console.log({
        this: this,
        arguments: arguments,
      });
    },
    /**
     * Block helper to choose the correct template branch based on the
     * browser's width.
     * @param {number} [width] Maximum width that will be considered skinny.
     *     Defaults to Gryphon's standard skinny max-width.
     * @param {object} options The options object provided by Handlebars.
     */
    ifskinny: function (width, options) {
      if (options === undefined) {
        options = width;
        width = Gryphon.widgets.MEDIA_SKINNY_MAX_WIDTH;
      }
      return $(window).width() <= width
        ? options.fn(this)
        : options.inverse(this);
    },
  };
  window.helpers = helpers;

  // Attach all the helper functions to the Handlebars namespace.
  for (var name in helpers) {
    if (hasOwnProperty.call(helpers, name)) {
      Handlebars.registerHelper(name, helpers[name]);
    }
  }
})(jQuery);

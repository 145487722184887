(function (widgets, BaseClass) {
  function custom_colorder(widget, order) {
    var $order = JSON.parse(order);
    var c, element_col, t_headers, selector, isCheckbox;
    if ($('td.grid-cell').length > 0) {
      element_col = 'td.grid-cell';
    } else {
      element_col = 'li.grid-cell';
    } //for mobile
    $.each(
      $("input[id*='response']").closest(element_col).parent(),
      function (idx, row) {
        $(row).find(element_col).hide();
        for (c in $order) {
          // we need to check the full suffix of the id
          // with name and code for checkbox input types
          isCheckbox = $(row).find('input').attr('type') === 'checkbox';
          selector = isCheckbox
            ? "input[id*='response-" + $order[c] + '-' + $order[c] + "']"
            : "input[id*='response-" + $order[c] + "']";
          if (
            $(row).find(selector).closest(element_col).html().trim() !==
            $(row).find(element_col).last().html().trim()
          ) {
            $(row)
              .find(selector)
              .closest(element_col)
              .show()
              .insertAfter($(row).find(element_col).last());
          } else {
            $(row).find(selector).closest(element_col).show();
          }
        }
      }
    );

    if ($('[id*="response-grid"] thead th').length > 0) {
      t_headers = '[id*="response-grid"] thead th';
      $('th[class*="grid-header-response"]').hide();
      for (c in $order) {
        if (
          $('.grid-header-response-' + $order[c]).html() !==
          $(t_headers).last().html()
        ) {
          $('.grid-header-response-' + $order[c])
            .show()
            .first()
            .insertAfter($(t_headers).last());
        } else {
          $('.grid-header-response-' + $order[c]).show();
        }
      }
    }
  }

  function disableInputs(element, widgetEl) {
    // to not lose the focus after disabling DK
    if (typeof element === 'string') {
      element = $(element);
    } else {
      // "element" is actually an "event" in this case
      // uses event.data to identify the elements passed
      widgetEl = element.data.widgetEl;
      element = element.data.inputEl;
    }

    // Should only target the elements underneath the 'widgetEl'
    if (element.is(':checked')) {
      const allGridInputs = $(widgetEl).find(
        '.grid-cell input, .open-cell input'
      );
      if (allGridInputs.length) {
        $.each(allGridInputs, function (index, item) {
          if (item.id) {
            disableSpellCheck('#' + item.id);
            $('#' + item.id).addClass('hidden-response');
          }
        });
      }

      $(widgetEl)
        .find('.grid-cell .uniform-checked, .grid-cell .response-label')
        .addClass('hidden-response');

      // grid-open with collapsible options
      $(widgetEl)
        .find('.response-label .input-wrapper input')
        .addClass('hidden-response');
      $(widgetEl).find('.fa-check').addClass('hidden-check');
    } else {
      $(widgetEl).find('.hidden-response').removeClass('hidden-response');
      $(widgetEl).find('.hidden-check').removeClass('hidden-check');
    }
    element.blur().trigger('changed');
  }

  // sessionGridOptions keeps the options for grid-open for one page session
  var gridOpenSession = {
    sessionGridOptions: {},
    isDirty: false,
    softCount: 0,
    gridId: null,
  };

  var Grid = widgets.Widget.extend(
    {
      render: function render() {
        var self = this;
        Grid.__super__.render.call(this);

        if (this._object.unique) {
          this._initUnique();
        }

        if (this._object.displaymax) {
          this._initDisplaymax();
        }

        // ensure we're not collapsed (ie. mobile viewing)
        // before initiating our "sticky" table headers
        if (
          !!this._object.sticky_header &&
          $(document).width() > this._object.collapse_width
        ) {
          this._initStickyHeader();
        }

        function makeDirty() {
          if (
            self._object.required.toLowerCase() === 'hard' ||
            (typeof self._object.ranges_start !== 'undefined' &&
              typeof self._object.ranges_stop !== 'undefined') ||
            typeof self._object.rowsum !== 'undefined' ||
            typeof self._object.colsum !== 'undefined'
          ) {
            gridOpenSession.isDirty = true;
          } else if (
            self._object.required.toLowerCase() === 'soft' &&
            gridOpenSession.softCount === 0
          ) {
            gridOpenSession.isDirty = true;
            gridOpenSession.softCount++;
          } else {
            gridOpenSession.isDirty = false;
          }
        }

        if (this._object.header_alignment) {
          switch (this._object.header_alignment) {
            case 'top':
              $('table thead tr th').addClass('grid-header-vertical-top');
              break;
            case 'bottom':
              $('table thead tr th').addClass('grid-header-vertical-bottom');
              break;
            default:
              break;
          }
        }

        if (this._object.type === 'grid-open') {
          if (gridOpenSession['gridId'] !== this._object.id) {
            gridOpenSession.isDirty = false;
            gridOpenSession.gridId = this._object.id;
          }
          var gridOptionsBeforeUpdate = JSON.parse(
            JSON.stringify(gridOpenSession.sessionGridOptions)
          );
          if (
            this._object.category_options &&
            this._object.category_options.length > 0
          ) {
            var errors = [];
            this._object.category_options.forEach(function (category_option) {
              category_option.response_inputs.forEach(function (
                response_input,
                index
              ) {
                var optionInput = $(`input[name="${response_input}"]`);
                optionInput.on('input', function (e) {
                  gridOpenSession.sessionGridOptions[e.currentTarget.name] =
                    e.currentTarget.value;
                });

                // Data doesn't erase when user comes back to grid-open
                if (
                  category_option.answer &&
                  category_option.answer.length > 0 &&
                  category_option.answer[index] !== null
                ) {
                  if (typeof category_option.answer[index] !== 'boolean') {
                    optionInput.val(
                      gridOpenSession.sessionGridOptions[response_input]
                    );
                  }
                  // if user removes one input and click on next, error is shown but previous input comes back
                  if (
                    (typeof gridOpenSession.sessionGridOptions[
                      response_input
                    ] === 'undefined' ||
                      (typeof gridOpenSession.sessionGridOptions[
                        response_input
                      ] === 'string' &&
                        gridOpenSession.sessionGridOptions[
                          response_input
                        ].trim() === '')) &&
                    !errors.includes(category_option.varname) &&
                    gridOpenSession.isDirty
                  ) {
                    errors[errors.length] = category_option.varname;
                  }
                }
                // Previous answers are not wiped on clicking next button
                else if (
                  gridOpenSession.sessionGridOptions[response_input] &&
                  gridOpenSession.sessionGridOptions[response_input].trim() !==
                    '' &&
                  gridOpenSession.isDirty
                ) {
                  optionInput.val(
                    gridOpenSession.sessionGridOptions[response_input]
                  );
                }
                // catches error
                else if (
                  !errors.includes(category_option.varname) &&
                  gridOpenSession.isDirty
                ) {
                  errors[errors.length] = category_option.varname;

                  $('#' + category_option.varname)
                    .parents('.accordion-group')
                    .addClass('accordion-group-error');
                }
              });
            });

            if (
              typeof this._object.ranges_start !== 'undefined' &&
              typeof this._object.ranges_stop !== 'undefined' &&
              gridOpenSession.isDirty
            ) {
              errors = [];
              Object.keys(gridOpenSession.sessionGridOptions).forEach(function (
                key
              ) {
                if (
                  (gridOptionsBeforeUpdate[key] != undefined &&
                    (gridOptionsBeforeUpdate[key] > self._object.ranges_stop ||
                      gridOptionsBeforeUpdate[key] <
                        self._object.ranges_start)) ||
                  (gridOptionsBeforeUpdate[key] != undefined &&
                    isNaN(gridOptionsBeforeUpdate[key]) === true)
                ) {
                  errors[errors.length] = key.substr(
                    0,
                    key.indexOf('response')
                  );
                }
              });
            }
            if (
              typeof self._object.rowsum !== 'undefined' &&
              gridOpenSession.isDirty
            ) {
              errors = [];
              self._object.category_options.forEach(function (category_option) {
                var sum;
                category_option.response_inputs.some(function (
                  response_input,
                  index
                ) {
                  if (
                    gridOptionsBeforeUpdate[response_input] !== undefined &&
                    !isNaN(parseInt(gridOptionsBeforeUpdate[response_input]))
                  ) {
                    if (sum === undefined) {
                      sum = 0;
                    }
                    sum =
                      sum + parseInt(gridOptionsBeforeUpdate[response_input]);
                  }
                  if (
                    sum !== undefined &&
                    (sum > self._object.rowsum ||
                      (index === category_option.response_inputs.length - 1 &&
                        sum < self._object.rowsum))
                  ) {
                    errors[errors.length] = response_input.substr(
                      0,
                      response_input.indexOf('response')
                    );
                    return true;
                  }
                });
              });
            }
            if (
              typeof self._object.colsum !== 'undefined' &&
              gridOpenSession.isDirty
            ) {
              errors = [];
            }

            // Sets category_errors
            this._object.category_errors = errors;

            // Marks question as dirty to on clicking on next
            $('#mainNav #next_button').on('click', makeDirty);
          }
        } else {
          gridOpenSession.sessionGridOptions = {};
          gridOpenSession.isDirty = false;
          $('#mainNav #next_button').off('click', makeDirty);
        }
        // style inputs if collapsed
        this.$el
          .find('.accordion')
          .find('input[type="checkbox"], input[type="radio"]')
          .uniform();

        this.$el.find('.grid-cell').on('click', function (e) {
          const nearestDk = $('#r-' + self._object.dk_id);
          if (nearestDk.attr('checked')) {
            nearestDk.removeAttr('checked');
            nearestDk.parent().removeClass('uniform-checked');
            $(self.$el).find('.hidden-response').removeClass('hidden-response');
            $(self.$el).find('.hidden-check').removeClass('hidden-check');
          }

          // desktop
          if ($(this).parent().hasClass('error')) {
            self.toggle_highlighted_row(
              $(this).parent(),
              $(this).find('input').attr('name')
            );
          }
          // mobile
          if (
            $(this)
              .closest('.accordion-body')
              .parent('.accordion-group')
              .hasClass('accordion-group-error')
          ) {
            self.toggle_highlighted_row(
              $(this).closest('.accordion-body').parent('.accordion-group'),
              $(this).find('input').attr('name')
            );
          }
        });

        this.$el.find('input:radio, input:checkbox').uniform();
        this.$el
          .find('input:radio, input:checkbox')
          .on('change focus', function (e) {
            checkSingleInputValue($(this), self);
          });

        this.$el.find('input:text').on('keyup', function (e) {
          checkMultipleInputsValue($(this), self._object.required);
        });

        // after refreshing
        $('.accordion-inner').each(function () {
          if (self._object.type === 'grid-open') {
            checkMultipleInputsValue($(this), self._object.required);
          } else {
            checkSingleInputValue($(this), self);
          }
        });

        this.$el.find('.accordion').on('show', function () {
          $(this)
            .find('.' + jQuery.uniform.defaults.checkedClass)
            .parents('label')
            .addClass('selected');
        });

        if (
          this._object.custom_colorder !== '' &&
          this._object.custom_colorder !== 'None'
        ) {
          custom_colorder(this, this._object.custom_colorder);
        }

        if (this._object.category_errors.length > 0) {
          this.highlight_required_rows();
        }

        if (this._object.dk && this._object.dk_id) {
          // Watch for input changes on the dk input if True,
          // and visually disable any previous row values
          var dk_input = $('#r-' + this._object.dk_id);
          disableInputs(dk_input.selector, this.$el.selector); // For consistency, sends "strings"
          dk_input.on(
            'input',
            { inputEl: dk_input, widgetEl: this.$el },
            disableInputs
          ); // Instead of a single data object, send two (one for the input, one for the widget element).
          handle_keyboard_checkbox('#r-' + this._object.dk_id);
        }
      },

      destroy: function destroy() {
        // Reset any adjustments to the Main Navigation buttons
        $('#mainNav').css('margin-bottom', '');
      },

      _initDisplaymax: function _initDisplaymax() {
        var settings = _.pick(this._object, [
          'phase_pos',
          'phase_max',
          'required',
          'soft_required',
          'required_message',
          'ranges_start',
          'ranges_stop',
          'dk',
        ]);
        this._displayMaxHelper = new GridDisplayMaxHelper(settings);
        this._displayMaxHelper.applySideEffects();
        this._displayMaxHelper.highlight_required_rows =
          this.highlight_required_rows.bind(this);
        this._displayMaxHelper.transpose = this._object.transpose;
        this._displayMaxHelper.displaymax = this._object.displaymax;

        if (this._object.dk) {
          this._displayMaxHelper.dk_id = this._object.dk_id;
        }
      },

      _initUnique: function _initUnique() {
        var tableId = this._object.input_id + '-grid';
        var grid = $('#' + tableId);

        $('input:radio, input:checkbox', grid).on('click', function () {
          var elem = $(this);
          var selector = [
            'input[data-column="',
            elem.data('column'),
            '"], input[name="',
            elem.attr('name'),
            '"]',
          ].join('');

          $(selector, grid)
            .not(elem)
            .prop('checked', false)
            .parent('span')
            .removeClass('uniform-checked'); // removes 'checked' class from parent element
        });
      },

      _initStickyHeader: function _initStickyHeader() {
        var elementId = $('#' + this._object.element_id),
          elementIdOffset = elementId.find('.question').css('margin-bottom');

        var tableContainer = elementId.find('.grid-container-sticky-header'),
          tableContainerOffset = tableContainer.offset().top + 'px';

        var mainFormMarginBottom = $('#main_form').css('margin-bottom');
        var mainNavHeight = $('#mainNav').height() + 'px';
        var contentWrapperMarginBottom =
          $('.content-wrapper').css('margin-bottom');

        var previewDebugPanelHeight = '0px';
        $('#mainNav').css('margin-bottom', '0px');
        if ($('#previewDebugPanel').length > 0) {
          previewDebugPanelHeight = $('#previewDebugPanel').height() + 'px';
        }

        // Total offset of non-grid table elements
        var documentHeightOffset =
          tableContainerOffset +
          ' - ' +
          elementIdOffset +
          ' - ' +
          previewDebugPanelHeight +
          ' - ' +
          mainFormMarginBottom +
          ' - ' +
          mainNavHeight +
          ' - ' +
          contentWrapperMarginBottom;

        // Adjust the table container's height to enable vertical / horizontal scrolling
        tableContainer.css(
          'max-height',
          'calc(100vh - ' + documentHeightOffset + ')'
        );
      },

      _handleShowOnSkip: function _handleShowOnSkip() {
        var self = this;
        var header_el,
          row_el,
          elms = self._object.show_on_skip || [];
        for (var i = 0; i < elms.length; i++) {
          header_el = $('.grid-header-response-' + elms[i].code);
          row_el = $('input[data-column="' + elms[i].code + '"]').closest('td');

          if (!$('.alert-error').is(':visible')) {
            header_el.hide();
            row_el.hide();
          }
        }

        // interrupt the next click event to check for conditionals
        if (mainNav) {
          mainNav.nextButton.bind(
            'click keypress',
            $.proxy(this, '_checkConditionals')
          );
        }
      },

      _checkConditionals: function _checkConditionals(ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        var self = this,
          el,
          elms = self._object.show_on_skip || [];

        var responses = $('#' + self._object.element_id + ' input');
        var answered = _.filter(responses, function (i) {
          return $(i).is(':checked');
        });
        var hidden = _.filter(elms, function (i) {
          return $(
            'tbody[data-phase]:not(:hidden) input[data-column="' + i.code + '"]'
          ).is(':hidden');
        });

        if (self.isPassed(self, answered, elms, hidden)) {
          if (self._object.phase_count === 1) {
            return click_next({ key: ev.key, type: ev.type });
          } else {
            if ($('.alert-error').is(':visible')) {
              $('.alert-error').hide();
            }
            return this._displayMaxHelper.pgrid_next(ev);
          }
        }

        // show the message and extra response option
        for (var i = 0; i < elms.length; i++) {
          el = $('input[data-column="' + elms[i].code + '"]').closest('td');
          if (!el.is(':visible')) {
            $('.grid-header-response-' + elms[i].code).show();
            el.show();
            if ($('.alert-error').is(':visible')) {
              $('.alert-error').text(self._object.required_text);
            } else {
              $('.question-text').after(
                '<div class="alert alert-error">' +
                  self._object.required_text +
                  '</div>'
              );
            }
          }
        }
      },

      highlight_required_rows: function highlight_required_rows(errors) {
        if (errors) {
          this._object.category_errors = errors;
        }
        var that = this,
          found;
        if ($('.mobile').length > 0 && that._object.displaymax) {
          $.each(this._object.category_errors, function (idx, varname) {
            found = _.find(that._object.category_options, {
              varname: varname,
            });
            if (found) {
              $('#r-' + varname).addClass('accordion-group-error');
            }
          });
        } else {
          // desktop
          $.each(this._object.category_errors, function (idx, varname) {
            found = _.find(that._object.category_options, {
              varname: varname,
            });
            if (found) {
              try {
                if (!that._object.transpose) {
                  $('[name*="' + found.name + '"]')
                    .first()
                    .closest('tr')
                    .addClass('error');
                } else {
                  $('[name*="' + found.name + '"]')
                    .closest('td')
                    .addClass('error');
                  $('thead th[class*="' + varname + '"').addClass('error');
                }
              } catch (e) {}
            }
          });
        }
      },

      toggle_highlighted_row: function (element, row_name) {
        // desktop and mobile
        $(element).removeClass('error').removeClass('accordion-group-error');

        // Remove the 'selected' input name from the list of
        // previous category errors
        var found = _.find(this._object.category_options, {
          name: row_name,
        });
        if (found) {
          var idx = _.indexOf(this._object.category_errors, found.varname);
          if (idx !== -1) {
            // remove from category errors once answered
            this._object.category_errors.splice(idx, 1);
          }
        }

        // if no more category errors, hide the alert
        if (this._object.category_errors.length == 0) {
          if ($('.alert-error').is(':visible')) {
            $('.alert-error').hide();
          }
        }
      },
    },
    {
      types: ['grid', 'grid-check'],
      views: ['grid'],
    }
  );

  function validate_numbers(content) {
    return Number.isSafeInteger(Number(content));
  }

  function validateColsumVal(param) {
    var elem = $(param);
    var value = parseInt(elem.val());
    var max_value = parseInt(elem.attr('max'));

    if (value > max_value) {
      elem.addClass('grid-input-error');
    } else {
      elem.removeClass('grid-input-error');
    }
  }

  var GridOpen = Grid.extend(
    {
      render: function render() {
        GridOpen.__super__.render.call(this);

        var inputSelector = '.grid-container input';

        //record and validate on backspace
        $(inputSelector).on('keyup mouseup', function (e) {
          // Validate value against max value
          validateColsumVal(this);
        });

        $(inputSelector).on('paste', function (event) {
          const pastedContent =
            event.originalEvent.clipboardData.getData('text');
          if (validate_numbers(pastedContent)) {
            validateColsumVal(this);
          } else {
            return false;
          }
        });

        $(inputSelector).on('blur focus', function (e) {
          if (e.type === 'blur') {
            $('.grid-cell label').removeClass('focused');
          } else {
            var parentEl = $(e.currentTarget).parents('label');
            if (!parentEl.hasClass('focused')) {
              parentEl.addClass('focused');
            }
          }
        });
        if (this._object.rowsum) {
          $(inputSelector).blur(this._onRowSumTextInputBlur).blur();
        }
        if (this._object.colsum) {
          $(inputSelector).blur(this._onColSumTextInputBlur).blur();
        }
      },

      _onRowSumTextInputBlur: function _onRowSumTextInputBlur() {
        var elem = $(this);
        var total = sumvals(elem.parents('tr').find('input:text'));
        var tcell = elem.parents('td').siblings('.total');
        tcell.text(total);
      },

      _onColSumTextInputBlur: function _onColSumTextInputBlur() {
        var elem = $(this);
        var grid = elem.parents('table');

        var col_id = elem.data('column');
        var total = sumvals($('input[data-column="' + col_id + '"]', grid));
        var total_cell = $('tfoot [data-column="' + col_id + '"]', grid);

        total_cell.text(total);
      },

      _checkConditionals: function _checkConditionals(ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        var self = this,
          el,
          elms = self._object.show_on_skip || [];

        var responses = $(
          '#' + self._object.element_id + ' input[type="text"]'
        );
        var answered = _.filter(responses, function (i) {
          return $(i).val() !== '';
        });
        var hidden = _.filter(elms, function (i) {
          return $(
            'tbody[data-phase]:not(:hidden) input[data-column="' + i.code + '"]'
          ).is(':hidden');
        });

        if (self.isPassed(self, answered, elms, hidden)) {
          if (self._object.phase_count === 1) {
            return click_next({ key: ev.key, type: ev.type });
          } else {
            if ($('.alert-error').is(':visible')) {
              $('.alert-error').hide();
            }
            return this._displayMaxHelper.pgrid_next(ev);
          }
        }

        // show the message and extra response option
        for (var i = 0; i < elms.length; i++) {
          el = $('input[data-column="' + elms[i].code + '"]').closest('td');
          if (!el.is(':visible')) {
            $('.grid-header-response-' + elms[i].code).show();
            el.show();
            if ($('.alert-error').is(':visible')) {
              $('.alert-error').text(self._object.required_text);
            } else {
              $('.question-text').after(
                '<div class="alert alert-error">' +
                  self._object.required_text +
                  '</div>'
              );
            }
          }
        }
      },
    },
    {
      types: ['grid-open'],
      views: ['grid'],
    }
  );

  Grid.register();
  GridOpen.register();
  widgets.Grid = Grid;
  widgets.GridOpen = GridOpen;

  /**
   * Manages the behaviour specific to grid widgets with displaymax set.
   *
   * This is mostly a wrapper around legacy code and its side-effects; call
   * `.applySideEffects()` to initialise the behaviours.
   */
  var GridDisplayMaxHelper = BaseClass.extend({
    /**
     * @param {object} settings The displaymax-related settings: { phase_pos,
     *     phase_max, required, soft_required, required_message }.
     */
    constructor: function GridDisplayMaxHelperConstructor(settings) {
      $.extend(this, settings);
      this.displaymax_soft_prompts = 0;
    },

    // Candidate for deprecation. Part of base.js now
    applySideEffects: function applySideEffects() {
      this.update_paged_buttons();
      mainNav.nextButton.bind('click keypress', $.proxy(this, 'pgrid_next'));
      mainNav.backButton.bind('click keypress', $.proxy(this, 'pgrid_back'));
    },

    // Candidate for deprecation. Part of base.js now
    update_paged_buttons: function update_paged_buttons() {
      var isFirstQuestion =
        Object.keys(window.page_state.form_data).length === 0;
      if (this.back_allowed === undefined) {
        this.back_allowed = $('#back_button').css('display') !== 'none';
      }
      var button_states = {};
      button_states.no_back_nav = !!$('#back_button').attr('no-back-nav');
      if (button_states.no_back_nav) this.back_allowed = false;
      button_states.next_button = true;
      button_states.back_button = this.back_allowed;
      var pos = this.phase_pos;
      if (pos > 0 && this.back_allowed) {
        button_states.back_button = true;
      } else if (isFirstQuestion) {
        button_states.back_button = false;
      }
      setTimeout(function () {
        $(window).scrollTop(0);
      });
      set_nav_button_visibility(button_states);
    },

    pgrid_next: function pgrid_next(ev) {
      //Get the dk element value when dk is enabled
      var dk_checked = false;
      if (this.dk && this.dk_id) {
        var dk_input = $('#r-' + this.dk_id);
        if (dk_input.is(':checked')) {
          dk_checked = true;
        }
      }

      if (
        ev &&
        ev.type === 'keypress' &&
        ev.key !== ' ' &&
        ev.key !== 'Enter'
      ) {
        return;
      }
      var phase_rows = this.get_phase_rows();
      var that = this,
        errors = false,
        r_start = parseFloat(this.ranges_start),
        r_stop = parseFloat(this.ranges_stop);

      if (!dk_checked) {
        try {
          phase_rows.each(function () {
            var phase_row = $(this),
              inputs = phase_row.find('input:text');

            inputs.each(function () {
              if ($(this).val() < r_start || $(this).val() > r_stop) {
                that.display_range_validation_message();
                errors = true;
              }
            });
          });
        } catch (e) {}
      }
      if (errors) {
        return;
      }

      var cur = this.phase_pos;
      this.clear_range_validation_message();
      this.clear_required_message();
      if (!errors && (this.required_is_satisfied() || dk_checked)) {
        this.displaymax_soft_prompts = 0;
        if (cur + 1 > this.phase_max) {
          click_next({ key: ev.key, type: ev.type });
        } else {
          this.back_allowed = true;
          if (this.displaymax && this.transpose) {
            $("td[data-phase='" + cur + "']").css('display', 'none');
            $("th[data-phase='" + cur + "']").css('display', 'none');
            this.phase_pos++;
            cur++;
            $("td[data-phase='" + cur + "']").css('display', '');
            $("th[data-phase='" + cur + "']").css('display', '');
          } else {
            $('#phase-' + cur).css('display', 'none');
            this.phase_pos++;
            cur++;
            $('#phase-' + cur).css('display', '');
          }
          this.update_paged_buttons();
        }
      } else if (!errors) {
        this.display_required_message();
      }
      return false;
    },

    pgrid_back: function pgrid_back(ev) {
      if (
        ev &&
        ev.type === 'keypress' &&
        ev.key !== ' ' &&
        ev.key !== 'Enter'
      ) {
        return;
      }
      var cur = this.phase_pos;
      if (cur - 1 < 0) {
        click_back({ key: ev.key, type: ev.type });
      } else {
        $('#phase-' + cur).css('display', 'none');
        this.phase_pos--;
        cur--;
        $('#phase-' + cur).css('display', '');
        this.update_paged_buttons();
      }
      return false;
    },

    // Candidate for deprecation. Part of base.js now
    required_is_satisfied: function required_is_satisfied() {
      var required = this.required === 'HARD' ? true : false;
      var soft_required = this.soft_required;
      if (required || (soft_required && !this.displaymax_soft_prompts)) {
        if (this.selected_rows_count() < this.visible_rows_count()) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    // Candidate for deprecation. Part of base.js now
    selected_rows_count: function selected_rows_count() {
      var phase_rows = this.get_phase_rows();
      var isCategorical = !!phase_rows.find('input:radio, input:checkbox')
        .length;
      var num_selected = 0;
      phase_rows.each(function () {
        var phase_row = $(this);

        if (isCategorical) {
          if (phase_row.find('input:checked').length) {
            num_selected++;
          }
        } else {
          var inputs = phase_row.find('input:text');
          var answers = 0;
          inputs.each(function () {
            if ($(this).val() !== '') {
              answers += 1;
            }
          });
          if (answers === inputs.length) {
            num_selected += 1;
          }
        }
      });
      return num_selected;
    },

    // Candidate for deprecation. Part of base.js now
    visible_rows_count: function visible_rows_count() {
      if (this.displaymax && this.transpose) {
        // If transposed and displaymax, we need to divide the number of table rows
        // with the total # of table cells to determine visible columns
        return (
          this.get_phase_rows().length /
          $('table.grid-layout tbody > tr').length
        );
      }
      return this.get_phase_rows().length;
    },

    get_phase_rows: function get_phase_rows() {
      if (this.displaymax && this.transpose) {
        return $("td[data-phase='" + this.phase_pos + "']");
      }
      return $('tr[id], .accordion-group', '#phase-' + this.phase_pos);
    },

    // Candidate for deprecation. Part of base.js now
    display_required_message: function display_required_message() {
      var template = Gryphon.templates['displaymax-required-message'];
      var data = { required_message: this.required_message };
      $('.grid-container').before(template(data));
      if (this.soft_required) {
        this.displaymax_soft_prompts = 1;
      }

      var that = this;
      var varname,
        phase_rows = this.get_phase_rows();
      var isCategorical = !!phase_rows.find('input:radio, input:checkbox')
        .length;
      // reset our category_errors
      var errors = [],
        answered = [];
      phase_rows.each(function () {
        var phase_row = $(this);

        if (!phase_row.attr('id')) return;

        if (isCategorical) {
          if (!phase_row.find('input:checked').length) {
            varname = phase_row.attr('id').slice(2);
            if (
              errors.indexOf(varname) == -1 &&
              answered.indexOf(varname) == -1
            ) {
              errors.push(varname);
            }
          } else {
            varname = phase_row.attr('id').slice(2);
            if (answered.indexOf(varname) == -1) {
              answered.push(varname);
            }
          }
        } else {
          var inputs = phase_row.find('input:text');
          var answers = 0;
          inputs.each(function () {
            if ($(this).val() !== '') {
              answers += 1;
            }
          });
          if (answers !== inputs.length) {
            varname = phase_row.attr('id').slice(2);
            if (errors.indexOf(varname) == -1) {
              errors.push(varname);
            }
          }
        }
      });

      if (errors.length > 0) {
        this.highlight_required_rows(errors);
      }
    },

    // Candidate for deprecation. Part of base.js now
    clear_required_message: function clear_required_message() {
      $('#displaymax-required-error').remove();
    },

    display_range_validation_message:
      function display_range_validation_message() {
        if (!$('.alert-validation').is(':visible')) {
          var tmp =
            '<div class="alert alert-error alert-validation">Please provide a whole number' +
            ' between ' +
            this.ranges_start +
            ' and ' +
            this.ranges_stop +
            '.</div>';
          $('.grid-container').before(tmp);
        }
      },

    clear_range_validation_message: function clear_range_validation_message() {
      $('.alert-validation').remove();
    },
  });

  function sumvals(input_list) {
    var total = 0;
    $.each(input_list, function (i, elem) {
      var val = parseInt($(elem).val());
      if (val) {
        total += val;
      }
    });
    return total;
  }

  function checkSingleInputValue(elem, self) {
    $('td label, .response-label').removeClass('selected');
    self.$el
      .find('.' + jQuery.uniform.defaults.checkedClass)
      .parents('label')
      .addClass('selected');

    // we need to have at least one selected option (grid-check)
    var totalSelected = elem
      .parents('.accordion-group')
      .find('.selected').length;
    if (totalSelected) {
      $(self.$el).find(elem).parents('.accordion-group').addClass('selected');
    } else {
      $(self.$el)
        .find(elem)
        .parents('.accordion-group')
        .removeClass('selected');
    }
  }

  function checkMultipleInputsValue(elem, requiredHARD) {
    var isFilled = false;

    elem
      .parents('.accordion-group')
      .find('input:text')
      .each(function () {
        if ($(this).val()) {
          isFilled = true;
        } else if (requiredHARD === 'HARD') {
          isFilled = false;
          return false;
        }
      });

    if (isFilled) {
      elem.parents('.accordion-group').addClass('selected');
    } else {
      elem.parents('.accordion-group').removeClass('selected');
    }
  }
})((Gryphon.widgets = Gryphon.widgets || {}), Gryphon.BaseClass);

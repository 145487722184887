var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div class=\"audioplayer\">\n  <object\n    classid=\"clsid:d27cdb6e-ae6d-11cf-96b8-444553540000\"\n    codebase=\"http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=8,0,0,0\"\n    width=\"300\"\n    height=\"70\"\n    align=\"middle\"\n  >\n    <param name=\"allowScriptAccess\" value=\"sameDomain\" />\n    <param name=\"allowFullScreen\" value=\"false\" />\n    <param\n      name=\"movie\"\n      value=\""
    + escapeExpression(((helper = (helper = helpers.static_prefix || (depth0 != null ? depth0.static_prefix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"static_prefix","hash":{},"data":data}) : helper)))
    + "/widgets/media/MP3Widget.swf?audioUrl="
    + escapeExpression(((helper = (helper = helpers.filename || (depth0 != null ? depth0.filename : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"filename","hash":{},"data":data}) : helper)))
    + "&amp;showProgressBar="
    + escapeExpression(((helper = (helper = helpers.showProg || (depth0 != null ? depth0.showProg : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"showProg","hash":{},"data":data}) : helper)))
    + "\"\n    />\n    <param name=\"quality\" value=\"best\" />\n    <param name=\"bgcolor\" value=\"#ffffff\" />\n    <embed\n      src=\""
    + escapeExpression(((helper = (helper = helpers.static_prefix || (depth0 != null ? depth0.static_prefix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"static_prefix","hash":{},"data":data}) : helper)))
    + "/widgets/media/MP3Widget.swf?audioUrl="
    + escapeExpression(((helper = (helper = helpers.filename || (depth0 != null ? depth0.filename : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"filename","hash":{},"data":data}) : helper)))
    + "&amp;showProgressBar="
    + escapeExpression(((helper = (helper = helpers.showProg || (depth0 != null ? depth0.showProg : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"showProg","hash":{},"data":data}) : helper)))
    + "\"\n      quality=\"best\"\n      bgcolor=\"#ffffff\"\n      width=\"300\"\n      height=\"70\"\n      align=\"middle\"\n      allowScriptAccess=\"sameDomain\"\n      allowFullScreen=\"false\"\n      type=\"application/x-shockwave-flash\"\n      pluginspage=\"http://www.macromedia.com/go/getflashplayer\"\n    />\n  </object>\n  <input type=\"hidden\" name=\""
    + escapeExpression(((helper = (helper = helpers.input_id || (depth0 != null ? depth0.input_id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"input_id","hash":{},"data":data}) : helper)))
    + "\" value=\"1\" />\n</div>\n";
},"useData":true});
(function () {
  /**
   * This script (implicitly) specifically targets IE<=8 and IE11, all of which
   * successfully execute it. These are targeted because they are the only
   * known browsers which suffer from this bug.
   *
   * Some other browsers may fail to execute it, but since we know that they
   * won't be one of the targeted IE versions (since we know those IE versions
   * execute this script successfully), we don't care.
   *
   * In particular, some browsers don't provide a click() method on IMG
   * elements, which the test relies on.
   *
   * If it turns out that other browsers do exhibit this bug, then either:
   *     1. this script fixes them, as it does for IE
   *     2. this script DOESN'T fix them, in which case the test will need to
   *        be slightly modified - perhaps by creating a synthetic click event
   *        and calling dispatchEvent() instead of calling click() directly.
   *
   * As it stands, though, there's no evidence of any other browser exhibiting
   * this bug, so there's no point implementing what's described in the the
   * second point above.
   *
   * This script *won't* fix the bug when the click event from the image is
   * prevented from bubbling up to the document.
   *
   * Finally, this script assumes the existence of `document.body`, so it
   * should *not* be included in the HEAD of the HTML, but rather somewhere in
   * the BODY.
   */
  function testHasBug() {
    function el(tag) {
      return document.createElement(tag);
    }

    var form = el('form');
    var label = el('label');
    var input = el('input');
    var img = el('img');
    var id = 'label-img-bug';

    input.type = 'radio';
    input.id = id;
    label.setAttribute('for', id);
    form.style.cssText =
      'border:0;width:0;height:0;position:absolute;' +
      'top:0;left:-9999px;margin-top:1px';

    form.appendChild(label);
    label.appendChild(input);
    label.appendChild(img);
    document.body.appendChild(form);

    img.click();
    var result = !input.checked;

    document.body.removeChild(form);
    form = null;
    label = null;
    input = null;
    img = null;

    return result;
  }

  var hasBug;
  try {
    hasBug = testHasBug();
  } catch (e) {
    // we know IE<=8 and IE11 won't get here, so don't do anything
    return;
  }

  if (hasBug) {
    // TODO: implement without requiring jQuery.
    $(document).on('click', 'form label img', function () {
      var label = $(this).closest('label');
      var id = label.attr('for');
      var input = $('#' + id)[0] || label.find('input')[0];
      if (input && input.click) {
        // need to invoke native click() method; jQuery's trigger() doesn't
        // actually do the right thing here; it's not clear why, but perhaps
        // because it manually bubbles events in addition to calling the native
        // event method.
        input.click();
      }
    });
  }
})();

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '      ', 'error', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  return " colorpicker-response-list-columns";
  },"5":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"dk-center\">\n  <input type=\"checkbox\" class=\"dk-check\" />\n  <span class=\"dk-label\"\n    ><label for=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "-dk\">";
  stack1 = lambda((depth0 != null ? depth0.dk_text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</label></span\n  >\n</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"question question-"
    + escapeExpression(lambda((depth0 != null ? depth0.type : depth0), depth0))
    + "\">\n  <div class=\"question-text\">\n    <span id=\"page-stem\"\n      >";
  stack1 = __default(require("../js/helpers/get.js")).call(depth0, __default(require("../js/helpers/get.js")).call(depth0, (depth0 != null ? depth0.category_options : depth0), (depth0 != null ? depth0.category_index : depth0), {"name":"get","hash":{},"data":data}), "text", {"name":"get","hash":{},"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</span\n    >\n  </div>\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n  <p class=\"colorpicker-instructions\">";
  stack1 = lambda((depth0 != null ? depth0.instructions : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</p>\n  <div class=\"question-response-list";
  stack1 = __default(require("../js/helpers/ifcond.js")).call(depth0, (depth0 != null ? depth0.columns : depth0), ">", 1, {"name":"ifcond","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"></div>\n</div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.dk : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
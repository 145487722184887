var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "<span class=\"dropdown-inline\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.subtext : depth0), {"name":"if","hash":{},"fn":this.program(2, data, depths),"inverse":this.program(5, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</span>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "  <div class=\"dropdown-subtext-cont\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <div class=\"dropdown-subtext-message\" id=\""
    + escapeExpression(lambda((depth0 != null ? depth0.element_id : depth0), depth0))
    + "-label\" tabindex=\"-1\">\n      ";
  stack1 = lambda((depth0 != null ? depth0.subtext : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n    </div>\n  </div>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '      ', 'error', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"5":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "  <select name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "\" aria-labelledby=\""
    + escapeExpression(lambda((depth0 != null ? depth0.element_id : depth0), depth0))
    + "-label\">\n    <option value=\"\">";
  stack1 = lambda((depth0 != null ? depth0.default_text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</option>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.response_options : depth0), {"name":"each","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </select>\n";
},"6":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "    <option\n      value=\""
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\"\n";
  stack1 = __default(require("../js/helpers/ifans.js")).call(depth0, (depths[1] != null ? depths[1].answer : depths[1]), (depths[1] != null ? depths[1].input_id : depths[1]), (depth0 != null ? depth0.code : depth0), {"name":"ifans","hash":{},"fn":this.program(7, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "      >";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</option>\n";
},"7":function(depth0,helpers,partials,data) {
  return "      selected=\"selected\"\n";
  },"9":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<fieldset class=\"question question-dropdown\">\n  <legend class=\"question-text\" id=\""
    + escapeExpression(lambda((depth0 != null ? depth0.element_id : depth0), depth0))
    + "-label\" tabindex=\"-1\">\n    ";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n  </legend>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(10, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  <div class=\"question-open-ended-input\">\n    <select name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "\" aria-labelledby=\""
    + escapeExpression(lambda((depth0 != null ? depth0.element_id : depth0), depth0))
    + "-label\">\n      <option value=\"\">";
  stack1 = lambda((depth0 != null ? depth0.default_text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</option>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.response_options : depth0), {"name":"each","hash":{},"fn":this.program(12, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </select>\n  </div>\n</fieldset>\n";
},"10":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '    ', 'error', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"12":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "      <option\n        value=\""
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\"\n";
  stack1 = __default(require("../js/helpers/ifans.js")).call(depth0, (depths[1] != null ? depths[1].answer : depths[1]), (depths[1] != null ? depths[1].input_id : depths[1]), (depth0 != null ? depth0.code : depth0), {"name":"ifans","hash":{},"fn":this.program(13, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        >";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</option>\n";
},"13":function(depth0,helpers,partials,data) {
  return "        selected=\"selected\"\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = escapeExpression(lambda((depth0 != null ? depth0.responses : depth0), depth0))
    + "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.is_subwidget : depth0), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.program(9, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true,"useDepths":true});
/**
 * Simple collection class for manipulating homogenous collections of objects.
 */
Gryphon.Collection = Gryphon.BaseClass.extend({
  /**
   * Constructs the collection instance.
   */
  constructor: function CollectionConstructor() {
    this._list = [];
  },

  /**
   * Add an item to the collection.
   *
   * @param {*} item The item to add to the collection.
   */
  add: function add(item) {
    this._list.push(item);
  },

  /**
   * Remove all items from the collection.
   */
  empty: function empty() {
    this._list.length = 0;
  },

  /**
   * Call a method on each of the items in the collection.
   *
   * @param {string} method The method name to call on each item.
   */
  invoke: function invoke(method) {
    $.each(this._list, function (index, item) {
      item[method]();
    });
  },
});

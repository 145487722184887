(function (widgets) {
  var Thermometer = widgets.Widget.extend(
    {
      constructor: function ThermometerConstructor(object) {
        Thermometer.__super__.constructor.call(this, object);
        before_thermometer();
        mainNav.nextButton.bind(
          'click keypress',
          $.proxy(next_thermometer, window, object)
        );
        mainNav.backButton.bind(
          'click keypress',
          $.proxy(prev_thermometer, window, object)
        );
      },

      render: function render() {
        Thermometer.__super__.render.call(this);
        this.$el.find('input:checkbox').uniform();

        this._object.state = {};
        // loop thru phases and add a key/value pair
        // for each into our local state
        for (var i = 0; i < this._object.phase_inputs.length; i++) {
          this._object.state[this._object.phase_inputs[i]] = null;
        }
        init_thermometer(this._object);
      },
    },
    {
      types: ['thermometer'],
      views: ['thermometer'],
    }
  );

  Thermometer.register();
  widgets.Thermometer = Thermometer;

  /* jshint
    curly: false
  */

  function before_thermometer() {
    $('#main_cont').hide();
  }

  function disable_thermometer(object) {
    if (
      $('.slider-input:visible').val() !== '' &&
      _.isInteger(parseInt($('.slider-input:visible').val()))
    ) {
      object.state[object.phase_inputs[object.phase]] = parseInt(
        $('.slider-input:visible').val()
      );
    } else if (
      $('.slider-input-mobile:visible').val() !== '' &&
      _.isInteger(parseInt($('.slider-input-mobile:visible').val()))
    ) {
      object.state[object.phase_inputs[object.phase]] = parseInt(
        $('.slider-input-mobile:visible').val()
      );
    }
    $('.slider-input:visible').val(0);
    $('.slider-input-mobile:visible').val(0);
  }

  function validate_degrees(content) {
    return Number.isSafeInteger(Number(content));
  }

  function update_degrees(val, object) {
    var percentage =
      ((val - object.therm_min) * 100) / (object.therm_max - object.therm_min);
    if (percentage < 0) {
      percentage = 0;
    }

    // target and update only the current phase's therm
    // so we do not update out from under ourselves
    var current_phase = $('.therm_cont')[object.phase];
    $(current_phase)
      .find('.on')
      .css('height', percentage + '%');
    $(current_phase).find('.results').slider({ value: val });
    // update the corresponding input to the current phase
    $('#r-' + object.phase_inputs[object.phase]).val(val);

    $('#r-' + object.phase_inputs[object.phase] + '-mobile').val(val);
  }

  function init_thermometer(object) {
    object.zero_phase_back = $('#back_button').css('display') != 'none';
    object.last_phase_next = $('#next_button').css('display') != 'none';
    var in_handler = false;

    $('.level').each(function () {
      var id = $(this).prop('id').replace('val-', '');
      var val = parseInt(id);
      var percentage =
        ((val - object.therm_min) * 100) /
        (object.therm_max - object.therm_min);
      $(this).css('bottom', percentage + '%');
    });

    $('.results').slider({
      orientation: 'vertical',
      min: object.therm_min,
      max: object.therm_max,
      slide: function (event, ui) {
        $('.hidden-response').removeClass('hidden-response');
        $('#r-' + object.phase_inputs[object.phase] + '-mobile').css(
          'visibility',
          'visible'
        );
        global.disable_dk_check();
        update_degrees(ui.value, object);
      },
    });

    $('.slider-input, .slider-input-mobile').on('keypress', function (event) {
      return validate_degrees(event.key);
    });

    $('.slider-input, .slider-input-mobile').on('paste', function (event) {
      const pastedContent = event.originalEvent.clipboardData.getData('text');
      if (validate_degrees(pastedContent)) {
        event.preventDefault();
        $('.slider-input').val(Math.round(pastedContent));
      } else {
        return false;
      }
    });

    $(window).off('change', '.slider-input');
    $(window).on('change', '.slider-input', function (e) {
      $('.checkbox, .dk-check').attr('checked', false);
      update_degrees(this.value, object);
    });

    $(window).off('change', '.slider-input-mobile');
    $(window).on('change', '.slider-input-mobile', function (e) {
      $('.checkbox, .dk-check').attr('checked', false);
      update_degrees(this.value, object);
    });

    // Handle clicks to the very top of the thermometer
    // where the hidden slider stops
    $('.top-rounded').click(function () {
      update_degrees(object.therm_max, object);
    });

    if (object.therm_dk === false) {
      $('.dk-input:visible').css('display', 'none');
    } else {
      $(window).off('change', '.dk-input:visible input');
      $(window).on('change', '.dk-input:visible input', function () {
        in_handler = true;
        var check = $(this).attr('checked');
        var idx = object.phase_inputs[$(this).data('idx')];
        if (check) {
          disable_thermometer(object);
          $('#r-' + idx).val('');
          $('#r-' + idx + '-mobile').val('');
          $('.therm-container:visible').addClass('hidden-response');
          $('#r-' + idx + '-mobile').css('visibility', 'hidden');
        } else {
          update_degrees(object.state[idx], object);
          $('#r-' + idx).val(object.state[idx]);
          $('#r-' + idx + '-mobile').val(object.state[idx]);
          $('.hidden-response:visible').removeClass('hidden-response');
          $('#r-' + idx + '-mobile').css('visibility', 'visible');
        }
        $(this).attr('checked', check);
        if (check && object.therm_aa) next_thermometer(object, null);
        in_handler = false;
        $(this).blur().trigger('changed');
      });
      handle_keyboard_checkbox('.dk-input input');
    }
    showCurrent_thermometer(object);
    $('#main_cont').show();
  }

  function showCurrent_thermometer(object) {
    var current = object.phase;
    var button_states = {
      back_button: true,
      next_button: true,
      hide_nav: false,
    };
    if (current === 0) button_states.back_button = object.zero_phase_back;
    else if (current == object.num_phases - 1)
      button_states.next_button = object.last_phase_next;
    set_nav_button_visibility(button_states);
    $('.therm_cont').each(function (n) {
      if (n == current) {
        $(this).css('display', 'block');
        update_degrees($('#r-' + object.phase_inputs[current]).val(), object);
        if (
          $('#r-' + object.phase_dks[current]).attr('checked') === 'checked' &&
          !$(this).find('.therm-container').hasClass('hidden-response')
        ) {
          // if DK is pre-checked, hide the input
          $(this).find('.therm-container').addClass('hidden-response');
        }
      } else {
        $(this).css('display', 'none');
      }
    });
  }

  var next_thermometer = function (object, e) {
    var val = parseInt(
      $('#' + object.element_id + ' .therm_cont:visible')
        .find('#r-' + object.phase_inputs[object.phase])
        .val()
    );

    if (val > object.therm_max || val < object.therm_min) {
      showError(object.out_of_range_error);
      return;
    }

    if (
      object.required === 'HARD' ||
      (object.required === 'SOFT' && !object.prompted)
    ) {
      if ($.trim($('.alert-error').html()) && object.required == -1) {
        $('.alert-error').html('').hide();
      } else {
        object.prompted = true;
        var dkval = $('.dk-input:visible input:checkbox:checked').val();
        if ((val === '' || isNaN(val) || val === undefined) && !dkval) {
          showError(object.required_text);
          return;
        }
      }
    }

    object.prompted = false;
    return phasechange_thermometer(object, e, 1);
  };

  var prev_thermometer = function (object, e) {
    return phasechange_thermometer(object, e, -1);
  };

  function phasechange_thermometer(object, ev, direction) {
    if (ev && ev.type === 'keypress' && ev.key !== ' ' && ev.key !== 'Enter') {
      return;
    }
    if (object.phase + direction == object.num_phases)
      click_next({ key: ev.key, type: ev.type });
    else if (object.phase + direction == -1)
      click_back({ key: ev.key, type: ev.type });
    else {
      object.phase += direction;
      object.therm_is_hidden = false;
      showCurrent_thermometer(object);
      $('.alert-error').html('').hide();
    }
    return false;
  }

  function showError(error) {
    $('.alert-error')
      .html('<i class="fa fa-exclamation-circle"></i>' + error)
      .show();
  }
})((Gryphon.widgets = Gryphon.widgets || {}));

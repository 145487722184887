// we do not have any feature flags setup as of now

import * as configcat from 'configcat-js';
let logger = configcat.createConsoleLogger(3); // Set the log level to INFO to track how your feature flags were evaluated. When moving to production, you can remove this line to avoid too detailed logging.

if (window.configcatSdkKey) {
  let configCatClient = configcat.createClient(window.configcatSdkKey, {
    logger: logger,
  });

  //   //example implementation
  //   configCatClient
  //     .getValueAsync('sampleflag_gryphon_ivw_ui', false)
  //     .then((value_flag) => {
  //       // add required logic here
  //       console.log('sampleflag_gryphon_ivw_ui: ' + value_flag);
  //     });
}

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '      ', 'error', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "<input type=\"hidden\" name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.key : depth0), depth0))
    + "\" value=\""
    + escapeExpression(lambda((depth0 != null ? depth0.value : depth0), depth0))
    + "\" />\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"question question-rank\">\n  <div class=\"question-cont\">\n    <div class=\"question-text\">";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n    <div class=\"instructions ranking-instructions text-info\">\n      <div class=\"regular-inst\">";
  stack1 = lambda((depth0 != null ? depth0.instructions : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n      <div class=\"small-inst\">";
  stack1 = lambda((depth0 != null ? depth0.small_instructions : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n    </div>\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    <div id=\"rankingWidget\" class=\"widget\"></div>\n\n    <input type=\"hidden\" name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "\" value=\"\" />\n  </div>\n</div>\n\n";
  stack1 = __default(require("../js/helpers/eachkeys.js")).call(depth0, (depth0 != null ? depth0.passthru : depth0), {"name":"eachkeys","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
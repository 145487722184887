(function ($, widgets) {
  function highlight(addOrRemove) {
    return function (element, errorClass, validClass) {
      var controlGroup = $(element).closest('.control-group');
      controlGroup[addOrRemove + 'Class']('error');
    };
  }

  var Address = widgets.Widget.extend(
    {
      render: function render() {
        Address.__super__.render.call(this);
        var object = this._object;
        var form = (this.form = this.$el.closest('form'));

        var validator = (this.validator = form.validate({
          highlight: highlight('add'),
          unhighlight: highlight('remove'),
          errorClass: 'help-inline',
          errorElement: 'span',
          showErrors: function (errorMap, errorList) {
            if (errorList.length) {
              $(this.currentForm)
                .find('.alert')
                .removeClass('hide')
                .text(object.required_text);
              $(window).resize();
            }
            this.defaultShowErrors();
          },
          submitHandler: $.noop,
        }));

        var liveaddress = (this.liveaddress = $.LiveAddress({
          key: object.key,
          autoMap: false,
        }));

        // The LiveAddress constructor returns early if previously initialised, so
        // we must call set up methods explicitly.
        liveaddress.mapFields({
          street: '#' + object.id + '-street-address1',
          street2: '#' + object.id + '-street-address2',
          city: '#' + object.id + '-city',
          state: '#' + object.id + '-stateprovince',
          zipcode: '#' + object.id + '-postalcode',
          country: '#' + object.id + '-country',
        });

        $('#' + object.id + '-country').on('change', $.proxy(this, '_submit'));

        this._bindNav();

        if ($(window).scrollTop() > 0 && $.scrollTo) {
          try {
            $.scrollTo(0, $.noop);
          } catch (e) {}
        }

        if (object.error) {
          validator.showErrors(object.error.fields);
        }
      },

      destroy: function () {
        Address.__super__.destroy.call(this);
        this.liveaddress.deactivate();
      },

      _bindNav: function () {
        mainNav.nextButton.bind('click keypress', $.proxy(this, '_submit'));
      },

      _submit: function (ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        var selectedCountry = this.form.find('[name="country"]').val();

        if (selectedCountry !== this._object.country_code) {
          // Defer to server-side validation, since the selected country no longer
          // matches the current form.
          return click_next(ev);
        }

        // Run validator on form, and if successful, move to the next page.
        if (this.validator.form()) {
          return click_next(ev);
        }
      },
    },
    {
      types: ['address'],
      views: ['address'],
    }
  );

  Address.register();
  widgets.Address = Address;
})(jQuery, (Gryphon.widgets = Gryphon.widgets || {}));

// eslint-disable-next-line no-extra-semi
(function (widgets) {
  var numScripts = 0;
  var Protect = widgets.Widget.extend(
    {
      constructor: function CaptchaConstructor(object) {
        Protect.__super__.constructor.call(this, object);

        numScripts = Object.keys(object.integrations).length;
        allResponsesId = '#r-' + object.input_id;

        object.PROTECT_TIMEOUT = 5000;
        object.PROTECT_INTERVAL = 50;
      },
      render: function render(object) {
        Protect.__super__.render.call(this, object);

        var self = this;

        $.each(Object.keys(this._object.integrations), function (i, name) {
          var integration = self._object.integrations[name];
          self._injectScript(name, integration);
        });
      },

      _injectScript: function (name, data) {
        switch (name) {
          case 'rd':
            $.ajax({
              type: 'POST',
              url: `https://prod.rtymgt.com/api/v4/respondents/search/${data.api_key}`,
              withCredentials: true,
              data: JSON.stringify({
                sy_nr: `${data.survey_id}`,
                rt_sr_pd: `${data.encoded_person_id}`,
                sn_ud: `${data.visa}`,
              }),
              dataType: 'json',
              headers: {
                'Content-Type': 'application/json',
              },
              global: false,
            })
              .done(function (dataRd) {
                $('#integration-rd-response').val(JSON.stringify(dataRd));
              })
              .fail(function () {
                $('#integration-rd-response').val(
                  JSON.stringify('SCRIPT_LOADING_ERROR')
                );
              })
              .always(function () {
                var current_loaded = parseInt($('#loaded-scripts').val());
                $('#loaded-scripts').val(current_loaded + 1);

                if (NODE_ENV === 'development') {
                  console.log(
                    'Protect Widget: Snippet for Research Defender loaded'
                  );
                }
              });
            break;
          case 'rdv3':
            try {
              $.ajax({
                type: 'GET',
                url: `https://prod.rtymgt.com/api/v3/respondents/get_token/${data.publishable_key}?tokens=1&rt_sr_pd=${data.encoded_person_id}`,
                headers: {
                  'Content-Type': 'application/json',
                },
                global: false,
              })
                .done(function (dataToken) {
                  var token =
                    dataToken.results[0] && dataToken.results[0].token;
                  if (token) {
                    $('#integration-rdv3-response').val(
                      JSON.stringify({ token: token })
                    );
                  } else {
                    $('#integration-rdv3-response').val(
                      JSON.stringify('SCRIPT_TOKEN_ERROR')
                    );
                  }
                })
                .fail(function () {
                  $('#integration-rdv3-response').val(
                    JSON.stringify('SCRIPT_TOKEN_ERROR')
                  );
                })
                .always(function () {
                  var current_loaded = parseInt($('#loaded-scripts').val());
                  $('#loaded-scripts').val(current_loaded + 1);

                  if (NODE_ENV === 'development') {
                    console.log(
                      'Protect Widget: Snippet for Research Defender v3 loaded'
                    );
                  }
                });
              break;
            } catch (e) {
              $('#integration-rdv3-response').val(
                JSON.stringify('SCRIPT_LOADING_ERROR')
              );
            }
            break;
          case 'vs':
            // TODO: This needs to be cleaned when BE stop to send the integration
            // In case we receive it, just considered as loaded
            var current_loaded = parseInt($('#loaded-scripts').val());
            $('#loaded-scripts').val(current_loaded + 1);

            $('#integration-vs-response').val(JSON.stringify({}));

            if (NODE_ENV === 'development') {
              console.log('Protect Widget: Snippet for Verisoul loaded');
            }
            break;
        }

        var retries = 0;
        var max_retries =
          this._object.PROTECT_TIMEOUT / this._object.PROTECT_INTERVAL;
        var intervalScript = setInterval(function () {
          var loadedScripts = parseInt($('#loaded-scripts').val());
          if (retries > max_retries) {
            clearInterval(intervalScript);
          }

          if (loadedScripts === numScripts) {
            var allResponses = {};
            var responseInputs = document.querySelectorAll(
              'input[id*="integration-"]'
            );
            responseInputs.forEach(function (tag) {
              try {
                allResponses[tag.name] = JSON.parse(tag.value);
              } catch (e) {
                // GRYP-9802: Handle exception when
                // `tag.value` is not parseable JSON
                allResponses[tag.name] = 'SCRIPT_LOADING_ERROR';
              }
            });
            $(allResponsesId).val(JSON.stringify(allResponses));
            clearInterval(intervalScript);
          }
          retries++;
        }, this._object.PROTECT_INTERVAL);
      },

      destroy: function destroy() {
        var tags = document.querySelectorAll('script[id*="protect-"]');
        tags.forEach(function (tag) {
          $(tag).remove();
        });
      },
    },
    {
      types: ['protect'],
      views: ['protect'],
    }
  );

  Protect.register();
  widgets.Protect = Protect;
})((Gryphon.widgets = Gryphon.widgets || {}));

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "  <div class=\"instructions text-info\">\n    ";
  stack1 = lambda((depth0 != null ? depth0.instructions : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = lambda((depth0 != null ? depth0.dk_instructions : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n  </div>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "  <div class=\"instructions text-info\" style=\"margin-bottom: 0px\">\n    ";
  stack1 = lambda((depth0 != null ? depth0.instructions : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = lambda((depth0 != null ? depth0.dk_instructions : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n  </div>\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '      ', 'error', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"7":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = " ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.color : depth0), {"name":"if","hash":{},"fn":this.program(8, data, depths),"inverse":this.program(13, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " ";
},"8":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "\n          <style>\n";
  stack1 = __default(require("../js/helpers/startsWith.js")).call(depth0, (depth0 != null ? depth0.color : depth0), "#", {"name":"startsWith","hash":{},"fn":this.program(9, data, depths),"inverse":this.program(11, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "          </style>\n          <span\n            class=\"placement-item placement-user-defined-color placement-"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + "\"\n            id=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "-item\"\n          >\n            <div class=\"slider-indicator\">\n              <div class=\"slider-indicator-line\" style=\"background-color: "
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + "\"></div>\n              <div class=\"slider-indicator-dot\" style=\"background-color: "
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + "\"></div>\n            </div>\n            <div\n              class=\"placement-pointer-text-container accessible-dropdown-trigger\"\n            >\n              <div\n                aria-haspopup=\"true\"\n                role=\"button\"\n                title=\"Move ";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n                tabindex=\"0\"\n              >\n                <span class=\"placement-pointer-text\">\n                  <div class=\"placement-ball\"></div>\n                  <div class=\"placement-label\">";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n                </span>\n              </div>\n              <ul class=\"accessible-dropdown\" role=\"menu\">\n                <li\n                  role=\"menuitem\"\n                  data-source=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "-item\"\n                  data-placement=\"drop\"\n                >\n                  Place on scale\n                </li>\n                <li\n                  role=\"menuitem\"\n                  data-source=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "-item\"\n                  data-placement=\"notsure\"\n                >\n                  Place on "
    + escapeExpression(lambda((depths[2] != null ? depths[2].dk_text : depths[2]), depth0))
    + "\n                </li>\n              </ul>\n            </div>\n          </span>\n";
},"9":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "          .placement-user-defined-color.placement-\\"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " .placement-ball:before {\n            background-color: "
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " !important;\n          }\n";
},"11":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "          .placement-user-defined-color.placement-"
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " .placement-ball:before {\n            background-color: "
    + escapeExpression(lambda((depth0 != null ? depth0.color : depth0), depth0))
    + " !important;\n          }\n";
},"13":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "          <span\n            class=\"placement-item placement-";
  stack1 = __default(require("../js/helpers/lower.js")).call(depth0, {"name":"lower","hash":{},"fn":this.program(14, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n            id=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "-item\"\n          >\n            <div class=\"slider-indicator\">\n              <div class=\"slider-indicator-line\"></div>\n              <div class=\"slider-indicator-dot\"></div>\n            </div>\n            <div\n              class=\"placement-pointer-text-container accessible-dropdown-trigger\"\n            >\n              <div\n                aria-haspopup=\"true\"\n                role=\"button\"\n                title=\"Move ";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n                tabindex=\"0\"\n              >\n                <span class=\"placement-pointer-text\">\n                  <div class=\"placement-ball\"></div>\n                  <div class=\"placement-label\">";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n                </span>\n              </div>\n              <ul class=\"accessible-dropdown\" role=\"menu\">\n                <li\n                  role=\"menuitem\"\n                  data-source=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "-item\"\n                  data-placement=\"drop\"\n                >\n                  Place on scale\n                </li>\n                <li\n                  role=\"menuitem\"\n                  data-source=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "-item\"\n                  data-placement=\"notsure\"\n                >\n                  Place on "
    + escapeExpression(lambda((depths[2] != null ? depths[2].dk_text : depths[2]), depth0))
    + "\n                </li>\n              </ul>\n            </div>\n          </span>\n          ";
},"14":function(depth0,helpers,partials,data,depths) {
  var escapeExpression=this.escapeExpression;
  return escapeExpression(__default(require("../js/helpers/item.js")).call(depth0, (depths[3] != null ? depths[3].colors : depths[3]), (depth0 != null ? depth0.idx : depth0), {"name":"item","hash":{},"data":data}));
  },"16":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "      <div class=\"placement-notsure-wrapper\" tabindex=\"2\">\n        <div id=\"placement-notsure\">\n          <div id=\"placement-notsure-label\">"
    + escapeExpression(lambda((depth0 != null ? depth0.dk_text : depth0), depth0))
    + "</div>\n        </div>\n      </div>\n";
},"18":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "  <input\n    type=\"hidden\"\n    name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "\"\n    class=\"placement-values\"\n    id=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "-value\"\n    value=\""
    + escapeExpression(__default(require("../js/helpers/ans.js")).call(depth0, (depth0 != null ? depth0.answer : depth0), (depth0 != null ? depth0.input_id : depth0), {"name":"ans","hash":{},"data":data}))
    + "\"\n  />\n  ";
},"20":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "\n  <span id=\"required\" style=\"display: none; visible: hidden\">"
    + escapeExpression(lambda((depth0 != null ? depth0.required : depth0), depth0))
    + "</span>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"question question-placement\">\n  <div class=\"question-text\">";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.legacy : depth0), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.program(3, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n  <div id=\"placement-container\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <script>\n      window.placement_narrow_view = '"
    + escapeExpression(lambda((depth0 != null ? depth0.narrow_view : depth0), depth0))
    + "'\n      window.placement_wide_view = '"
    + escapeExpression(lambda((depth0 != null ? depth0.wide_view : depth0), depth0))
    + "'\n    </script>\n    <div\n      class=\"placement-widget placement-outer narrow_view_"
    + escapeExpression(lambda((depth0 != null ? depth0.narrow_view : depth0), depth0))
    + " wide_view_"
    + escapeExpression(lambda((depth0 != null ? depth0.wide_view : depth0), depth0))
    + "\"\n    >\n      <div class=\"placement-wrapper\">\n        <div class=\"placement-options\">\n          ";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.category_options : depth0), {"name":"each","hash":{},"fn":this.program(7, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n        </div>\n        ";
  stack1 = __default(require("../js/helpers/partial.js")).call(depth0, "rule-partial", {"name":"partial","hash":{
    'show_value': ((depth0 != null ? depth0.show_value : depth0)),
    'input_id': ((depth0 != null ? depth0.input_id : depth0)),
    'right_message': ((depth0 != null ? depth0.right_message : depth0)),
    'left_message': ((depth0 != null ? depth0.left_message : depth0)),
    'increment': (1),
    'max': (10),
    'min': (0),
    'widget': ("placement")
  },"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n      </div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.dont_know : depth0), {"name":"if","hash":{},"fn":this.program(16, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </div>\n    <div class=\"clearfix\"></div>\n  </div>\n\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.category_options : depth0), {"name":"each","hash":{},"fn":this.program(18, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.required : depth0), {"name":"if","hash":{},"fn":this.program(20, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});
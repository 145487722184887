var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"alert alert-error alert-with-icon "
    + escapeExpression(((helper = (helper = helpers.extra_classes || (depth0 != null ? depth0.extra_classes : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"extra_classes","hash":{},"data":data}) : helper)))
    + "\" style=\""
    + escapeExpression(((helper = (helper = helpers.style || (depth0 != null ? depth0.style : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"style","hash":{},"data":data}) : helper)))
    + "\">\n  <i class=\"fa fa-exclamation-circle\"></i>\n  ";
  stack1 = ((helper = (helper = helpers.error_message || (depth0 != null ? depth0.error_message : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"error_message","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n</div>";
},"useData":true});
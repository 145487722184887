(function (widgets) {
  var Calendar = widgets.Widget.extend(
    {
      render: function render() {
        Calendar.__super__.render.call(this);
        var id = this._object.input_id + '-' + this._object.id;
        init_cal(id);
      },
    },
    {
      types: ['calendar'],
      views: ['calendar'],
    }
  );

  Calendar.register();
  widgets.Calendar = Calendar;

  function init_cal(id) {
    var inp = $('#r-' + id);
    var i = id.split('-')[id.split('-').length - 1];
    $('#' + id + ' td.valid-day').click(function () {
      var val = this.id.split('-')[1];
      if (inp.val() == val) {
        inp.attr('value', '');
      } else {
        inp.attr('value', val);
      }
      $(this).toggleClass('selected');
      $('#' + id + ' td.valid-day')
        .not(this)
        .removeClass('selected');
    });
    inp = $('#r-' + id);
    if (inp.val()) {
      $('#d-' + inp.val() + '-' + i).addClass('selected');
    }
  }
})((Gryphon.widgets = Gryphon.widgets || {}));

module.exports = function (context, options) {
  var ret = '';

  if (!context) {
    return options.inverse(this);
  }

  for (var key in context) {
    ret += options.fn({ key: key, value: context[key] });
  }

  return ret;
};

module.exports = function (answer, name, value, options) {
  if ($.isArray(answer) && $.inArray(value, answer) !== -1) {
    return options.fn(this);
  }

  if (value === answer) {
    return options.fn(this);
  }

  return helpers.ifanswer.call(this, name, value, options);
};

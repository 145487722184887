var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = " ";
  stack1 = __default(require("../js/helpers/ifskinny.js")).call(depth0, (depth0 != null ? depth0.collapse_width : depth0), {"name":"ifskinny","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " ";
},"2":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "\n  <div class=\"instructions text-info\">";
  stack1 = lambda((depth0 != null ? depth0.instructions : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n  ";
},"4":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '    ', 'error', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  return "grid-container-sticky-header";
  },"8":function(depth0,helpers,partials,data) {
  return "collapsible";
  },"10":function(depth0,helpers,partials,data) {
  var stack1, buffer = " ";
  stack1 = __default(require("../js/helpers/ifskinny.js")).call(depth0, (depth0 != null ? depth0.collapse_width : depth0), {"name":"ifskinny","hash":{},"fn":this.program(11, data),"inverse":this.program(13, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " ";
},"11":function(depth0,helpers,partials,data) {
  var stack1, buffer = " ";
  stack1 = this.invokePartial(require("./grid-skinny.hbs"), '', 'grid-skinny', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + " ";
},"13":function(depth0,helpers,partials,data) {
  var stack1, buffer = "\n    ";
  stack1 = this.invokePartial(require("./grid-wide.hbs"), '', 'grid-wide', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + " ";
},"15":function(depth0,helpers,partials,data) {
  var stack1, buffer = " ";
  stack1 = this.invokePartial(require("./grid-wide.hbs"), '', 'grid-wide', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + " ";
},"17":function(depth0,helpers,partials,data) {
  var stack1, buffer = "  <div class=\"grid-dk-container\">\n    ";
  stack1 = __default(require("../js/helpers/partial.js")).call(depth0, "dk-partial", {"name":"partial","hash":{
    'dk_id': ((depth0 != null ? depth0.dk_id : depth0)),
    'dk_value': (997),
    'response_id': ((depth0 != null ? depth0.response_id : depth0)),
    'dk_text': ((depth0 != null ? depth0.dk_text : depth0))
  },"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n  </div>\n  ";
},"19":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "\n  <input type=\"hidden\" name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.key : depth0), depth0))
    + "\" value=\""
    + escapeExpression(lambda((depth0 != null ? depth0.value : depth0), depth0))
    + "\" />\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"question question-grid question-grid-"
    + escapeExpression(lambda((depth0 != null ? depth0.input_type : depth0), depth0))
    + "\">\n  <div class=\"question-text\">";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n  ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.collapsible : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n  <div class=\"grid-container ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.sticky_header : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.collapsible : depth0), {"name":"if","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n    ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.collapsible : depth0), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.program(15, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n  </div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.dk : depth0), {"name":"if","hash":{},"fn":this.program(17, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = __default(require("../js/helpers/eachkeys.js")).call(depth0, (depth0 != null ? depth0.passthru : depth0), {"name":"eachkeys","hash":{},"fn":this.program(19, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"usePartial":true,"useData":true});
(function (widgets) {
  var Dropdown = widgets.Widget.extend(
    {
      render: function render() {
        Dropdown.__super__.render.call(this);
        global.removeFocusBorderSelect();

        // Handles "selected" styling of bolder font weight and color
        $('#' + this._object.element_id + ' select').change(function () {
          if ($(this).val() !== '') {
            $(this).addClass('selected');
          } else {
            $(this).removeClass('selected');
          }
        });

        // Handle previously selected answers
        if (this._object.answer) {
          $('#' + this._object.element_id + ' select').addClass('selected');
        }
        if (this._object.autoadvance) {
          this.$el.change(click_next);
        }
      },
      _handleShowOnSkip: function _handleShowOnSkip() {
        var self = this;
        var el,
          elms = self._object.show_on_skip || [];
        for (var i = 0; i < elms.length; i++) {
          el = $(
            '#' +
              self._object.element_id +
              ' select[name="' +
              self._object.input_id +
              '"]'
          ).find('option[value="' + elms[i].code + '"]');

          if (!$('.alert-error').is(':visible')) {
            el.hide();
          }
        }

        // interrupt the next click event to check for conditionals
        if (mainNav) {
          mainNav.nextButton.bind(
            'click keypress',
            $.proxy(this, '_checkConditionals')
          );
        }
      },

      _checkConditionals: function _checkConditionals(ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        var self = this,
          el,
          elms = self._object.show_on_skip || [];

        var responses = $('#' + self._object.element_id + ' option');
        var answered = _.filter(responses, function (i) {
          return $(i).is(':selected') && $(i).val() !== '';
        });
        var hidden = _.filter(elms, function (i) {
          return (
            $('select[name="' + self._object.input_id + '"]')
              .find('option[value="' + i.code + '"]')
              .css('display') === 'none'
          );
        });

        if (self.isPassed(self, answered, elms, hidden)) {
          return click_next({ key: ev.key, type: ev.type });
        }

        // show the message and extra response option
        for (var i = 0; i < elms.length; i++) {
          el = $('select[name="' + self._object.input_id + '"]').find(
            'option[value="' + elms[i].code + '"]'
          );
          if (el.css('display') === 'none') {
            el.show();
            if ($('.alert-error').is(':visible')) {
              $('.alert-error').text(self._object.required_text);
            } else {
              $('.question-text').after(
                '<div class="alert alert-error">' +
                  self._object.required_text +
                  '</div>'
              );
            }
          }
        }
      },
    },
    {
      types: ['dropdown', 'state', 'country'],
      views: ['dropdown'],
    }
  );

  Dropdown.register();
  widgets.Dropdown = Dropdown;
})((Gryphon.widgets = Gryphon.widgets || {}));

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  return " checked=\"checked\"\n  ";
  },"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = " ";
  stack1 = __default(require("../js/helpers/ifanswer.js")).call(depth0, (depth0 != null ? depth0.dk_id : depth0), "on", {"name":"ifanswer","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " ";
},"4":function(depth0,helpers,partials,data) {
  return "checked=\"checked\"";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<label class=\"dk-input "
    + escapeExpression(lambda((depth0 != null ? depth0.narrow_view : depth0), depth0))
    + " "
    + escapeExpression(lambda((depth0 != null ? depth0.wide_view : depth0), depth0))
    + " dk-align-"
    + escapeExpression(lambda((depth0 != null ? depth0.dk_align : depth0), depth0))
    + "\" for=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.dk_id : depth0), depth0))
    + "\">\n  <input type=\"checkbox\" id=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.dk_id : depth0), depth0))
    + "\" class=\"dk-check\" name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.dk_id : depth0), depth0))
    + "\"\n  data-idx=\""
    + escapeExpression(lambda((depth0 != null ? depth0.index : depth0), depth0))
    + "\" ";
  stack1 = __default(require("../js/helpers/ifans.js")).call(depth0, (depth0 != null ? depth0.answer : depth0), (depth0 != null ? depth0.response_id : depth0), (depth0 != null ? depth0.dk_value : depth0), {"name":"ifans","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n  <span class=\"dk-label\">";
  stack1 = lambda((depth0 != null ? depth0.dk_text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</span>\n</label>\n";
},"useData":true});
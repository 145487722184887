var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  return "    <div id=\"rankPlacementBlockWide\" class=\"rankPlacementBlock span6\"></div>\n    <div id=\"rankSelectionBlockWide\" class=\"rankSelectionBlock span6\"></div>\n    ";
  },"3":function(depth0,helpers,partials,data) {
  return "\n    <div id=\"rankPlacementBlockSkinny\" class=\"rankPlacementBlock\"></div>\n";
  },"5":function(depth0,helpers,partials,data) {
  return "  <div class=\"rank-dk row-fluid\"></div>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div id=\""
    + escapeExpression(lambda((depth0 != null ? depth0.identifier : depth0), depth0))
    + "\" class=\"rankingView\">\n  <div class=\"rank-responses row-fluid\">\n";
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, (depth0 != null ? depth0.identifier : depth0), "wideView", {"name":"ifequal","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, (depth0 != null ? depth0.identifier : depth0), "skinnyView", {"name":"ifequal","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  </div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.dk : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"useData":true});
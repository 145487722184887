var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '    ', 'error', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = " ";
  stack1 = helpers.each.call(depth0, (depths[1] != null ? depths[1].response_options : depths[1]), {"name":"each","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " ";
},"4":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = " <input type=\"radio\"\n    class=\"card-sort-input\" id=\"r-"
    + escapeExpression(lambda((depths[1] != null ? depths[1].name : depths[1]), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\" name=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].name : depths[1]), depth0))
    + "\"\n    value=\""
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\" ";
  stack1 = __default(require("../js/helpers/ifans.js")).call(depth0, (depths[1] != null ? depths[1].answer : depths[1]), (depths[1] != null ? depths[1].name : depths[1]), (depth0 != null ? depth0.code : depth0), {"name":"ifans","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " > ";
},"5":function(depth0,helpers,partials,data) {
  return " checked=\"checked\"\n    ";
  },"7":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "  <input type=\"hidden\" name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.key : depth0), depth0))
    + "\" value=\""
    + escapeExpression(lambda((depth0 != null ? depth0.value : depth0), depth0))
    + "\" />\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"question question-cardsort\">\n  <div class=\"question-text\">";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n  <div id=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "-cardsort\">\n    ";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.category_options : depth0), {"name":"each","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n  </div>\n\n";
  stack1 = __default(require("../js/helpers/eachkeys.js")).call(depth0, (depth0 != null ? depth0.passthru : depth0), {"name":"eachkeys","hash":{},"fn":this.program(7, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});
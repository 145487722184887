var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "  <input\n  type=\"hidden\"\n  id=\"integration-"
    + escapeExpression(lambda((data && data.key), depth0))
    + "-response\"\n  name=\""
    + escapeExpression(lambda((data && data.key), depth0))
    + "_response\"\n/>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"protect-widget\">\n  <input\n    id=\"r-"
    + escapeExpression(((helper = (helper = helpers.input_id || (depth0 != null ? depth0.input_id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"input_id","hash":{},"data":data}) : helper)))
    + "\"\n    name=\""
    + escapeExpression(((helper = (helper = helpers.input_id || (depth0 != null ? depth0.input_id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"input_id","hash":{},"data":data}) : helper)))
    + "\"\n    type='hidden'\n  />\n</div>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.integrations : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "<input\n  type=\"hidden\"\n  id=\"loaded-scripts\"\n  value='0'\n/>\n";
},"useData":true});
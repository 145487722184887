(function (widgets) {
  var Audio = widgets.Widget.extend(
    {
      constructor: function AudioConstructor(object) {
        Audio.__super__.constructor.call(this, object);
        $(page_state).on('audio_finished', $.proxy(mainNav, 'show'));
      },
    },
    {
      types: ['audio'],
      views: ['audio'],
    }
  );

  Audio.register();
  widgets.Audio = Audio;
})((Gryphon.widgets = Gryphon.widgets || {}));

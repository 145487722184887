var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  return "role=\"radiogroup\"";
  },"3":function(depth0,helpers,partials,data) {
  return "role=\"group\"";
  },"5":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = __default(require("../js/helpers/ifcond.js")).call(depth0, (depth0 != null ? depth0.qtext_len : depth0), ">", 1, {"name":"ifcond","hash":{},"fn":this.program(6, data),"inverse":this.program(10, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.qtext : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.qtext : depth0), {"name":"each","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "          "
    + escapeExpression(lambda(depth0, depth0))
    + "<br>\n";
},"10":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "      ";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"12":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "    ";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"14":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '    ', 'error', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"question question-"
    + escapeExpression(lambda((depth0 != null ? depth0.type : depth0), depth0))
    + "\"\n  ";
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, (depth0 != null ? depth0.input_type : depth0), "radio", {"name":"ifequal","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " \n  ";
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, (depth0 != null ? depth0.input_type : depth0), "checkbox", {"name":"ifequal","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " \n  aria-labelledby=\""
    + escapeExpression(lambda((depth0 != null ? depth0.element_id : depth0), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "label\">\n  <div class=\"question-text\" id=\""
    + escapeExpression(lambda((depth0 != null ? depth0.element_id : depth0), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "label\">\n";
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, (depth0 != null ? depth0.type : depth0), "language", {"name":"ifequal","hash":{},"fn":this.program(5, data),"inverse":this.program(12, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  </div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  <div class=\"question-response-list\"></div>\n</div>\n";
},"usePartial":true,"useData":true});
module.exports = function () {
  var len = arguments.length - 1;
  var args = arraySlice.call(arguments, 0, len);
  var options = arguments[len];

  for (var i = 0; i < len; i++) {
    if (args[i]) {
      return options.fn(this);
    }
  }

  return options.inverse(this);
};

/* eslint-disable no-func-assign */
var JsonView = (function (exports) {
  'use strict';

  function _typeof(obj) {
    '@babel/helpers - typeof';

    if (typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol') {
      _typeof = function (obj) {
        return typeof obj;
      };
    } else {
      _typeof = function (obj) {
        return obj &&
          typeof Symbol === 'function' &&
          obj.constructor === Symbol &&
          obj !== Symbol.prototype
          ? 'symbol'
          : typeof obj;
      };
    }

    return _typeof(obj);
  }

  function expandedTemplate() {
    var params =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var key = params.key,
      size = params.size;
    return '\n    <div class="line">\n      <div class="caret-icon"><i class="fas fa-caret-right"></i></div>\n      <div class="json-key">'
      .concat(key, '</div>\n      <div class="json-size">')
      .concat(size, '</div>\n    </div>\n  ');
  }

  function notExpandedTemplate() {
    var params =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var key = params.key,
      value = params.value,
      type = params.type;
    return '\n    <div class="line">\n      <div class="empty-icon"></div>\n      <div class="json-key">'
      .concat(
        key,
        '</div>\n      <div class="json-separator">:</div>\n      <div class="json-value json-'
      )
      .concat(type, '">')
      .concat(value, '</div>\n    </div>\n  ');
  }

  function hideNodeChildren(node) {
    node.children.forEach(function (child) {
      child.el.classList.add('hide');

      if (child.isExpanded) {
        hideNodeChildren(child);
      }
    });
  }

  function showNodeChildren(node) {
    node.children.forEach(function (child) {
      child.el.classList.remove('hide');

      if (child.isExpanded) {
        showNodeChildren(child);
      }
    });
  }

  // Build a string representation to use as a localStorage key
  function buildStorageKey(node, key) {
    if (node.parent) {
      if (!key) key = node.key;
      key = (node.parent.key + '>').concat(key);
      return buildStorageKey(node.parent, key);
    } else {
      return key ? key : node.key;
    }
  }

  function setCaretIconDown(node) {
    if (node.children.length > 0) {
      var icon = node.el.querySelector('.fas');

      if (icon) {
        icon.classList.replace('fa-caret-right', 'fa-caret-down');
        localStorage.setItem(buildStorageKey(node), node.isExpanded);
      }
    }
  }

  function setCaretIconRight(node) {
    if (node.children.length > 0) {
      var icon = node.el.querySelector('.fas');

      if (icon) {
        icon.classList.replace('fa-caret-down', 'fa-caret-right');

        // Remove any children which were also expanded
        var key = buildStorageKey(node),
          toBeRemoved = [];
        for (var i = 0; i < localStorage.length; i++) {
          if (new RegExp(key).test(localStorage.key(i))) {
            toBeRemoved.push(localStorage.key(i));
          }
        }
        // eslint-disable-next-line no-redeclare
        for (var i = 0; i < toBeRemoved.length; i++) {
          localStorage.removeItem(toBeRemoved[i]);
        }
      }
    }
  }

  function toggleNode(node) {
    if (node.isExpanded) {
      node.isExpanded = false;
      setCaretIconRight(node);
      hideNodeChildren(node);
    } else {
      node.isExpanded = true;
      setCaretIconDown(node);
      showNodeChildren(node);
    }
    // Trigger an event when toggling
    document.dispatchEvent(new Event('json-tree-node-toggled'));
  }

  function createContainerElement() {
    var el = document.createElement('div');
    el.className = 'json-container';
    return el;
  }

  function htmlEntities(value) {
    // eslint-disable-next-line no-useless-escape
    return value.replace(/\</g, '&lt;').replace(/\>/g, '&gt;');
  }

  function createNodeElement(node) {
    var el = document.createElement('div');

    var getSizeString = function getSizeString(node) {
      var len = node.children.length;
      if (node.type === 'array') return '['.concat(len, ']');
      if (node.type === 'object') return '{'.concat(len, '}');
      return null;
    };

    if (node.children.length > 0) {
      el.innerHTML = expandedTemplate({
        key: node.key,
        size: getSizeString(node),
      });
      var caretEl = el.querySelector('.caret-icon');
      caretEl.addEventListener('click', function () {
        toggleNode(node);
      });
    } else {
      el.innerHTML = notExpandedTemplate({
        key: node.key,
        value:
          typeof node.value == 'string' ? htmlEntities(node.value) : node.value,
        type: _typeof(node.value),
      });
    }

    var lineEl = el.children[0];

    if (node.parent !== null) {
      lineEl.classList.add('hide');
    }

    lineEl.style = 'margin-left: ' + node.depth * 18 + 'px;';
    return lineEl;
  }

  function getDataType(val) {
    var type = _typeof(val);

    if (Array.isArray(val)) type = 'array';
    if (val === null) type = 'null';
    return type;
  }

  function traverseTree(node, callback) {
    callback(node);

    if (node.children.length > 0) {
      node.children.forEach(function (child) {
        traverseTree(child, callback);
      });
    }
  }

  function createNode() {
    var opt =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return {
      key: opt.key || null,
      parent: opt.parent || null,
      value: opt.hasOwnProperty('value') ? opt.value : null,
      isExpanded: opt.isExpanded || false,
      type: opt.type || null,
      children: opt.children || [],
      el: opt.el || null,
      depth: opt.depth || 0,
    };
  }

  function createSubnode(data, node) {
    if (_typeof(data) === 'object') {
      for (var key in data) {
        var child = createNode({
          value: data[key],
          key: key,
          depth: node.depth + 1,
          type: getDataType(data[key]),
          parent: node,
        });
        child.isExpanded = localStorage.getItem(buildStorageKey(child))
          ? true
          : false;
        node.children.push(child);
        if (
          (child.type === 'object' && Object.keys(child.value).length === 0) ||
          (child.type === 'array' && child.value.length === 0)
        ) {
          child.value = JSON.stringify(child.value);
        }
        createSubnode(data[key], child);
      }
    }
  }

  function createTree(jsonData) {
    var data = typeof jsonData === 'string' ? JSON.parse(jsonData) : jsonData;
    var pVal = localStorage.getItem(getDataType(data));
    var rootNode = createNode({
      value: data,
      key: getDataType(data),
      type: getDataType(data),
      isExpanded: pVal === 'true' ? true : false,
    });
    createSubnode(data, rootNode);
    return rootNode;
  }

  function renderJSON(jsonData, targetElement) {
    var parsedData =
      typeof jsonData === 'string' ? JSON.parse(jsonData) : jsonData;
    var tree = createTree(parsedData);
    render(tree, targetElement);
    return tree;
  }

  function render(tree, targetElement) {
    var containerEl = createContainerElement();
    traverseTree(tree, function (node) {
      node.el = createNodeElement(node);
      if (node.isExpanded) {
        setTimeout(function () {
          node.isExpanded = !node.isExpanded;
          toggleNode(node);
        });
      }
      containerEl.appendChild(node.el);
    });
    targetElement.appendChild(containerEl);
  }

  function expandChildren(node) {
    traverseTree(node, function (child) {
      child.el.classList.remove('hide');
      child.isExpanded = true;
      setCaretIconDown(child);
    });
  }

  function collapseChildren(node) {
    traverseTree(node, function (child) {
      child.isExpanded = false;
      if (child.depth > node.depth) child.el.classList.add('hide');
      setCaretIconRight(child);
    });
  }

  exports.collapseChildren = collapseChildren;
  exports.createTree = createTree;
  exports.expandChildren = expandChildren;
  exports.render = render;
  exports.renderJSON = renderJSON;
  exports.traverseTree = traverseTree;

  return exports;
})({});
window.JsonView = JsonView;

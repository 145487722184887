(function (widgets) {
  var ColorPicker = widgets.SingleColorPicker.extend(
    {
      constructor: function ColorPickerConstructor(object) {
        ColorPicker.__super__.constructor.call(this, object);
        this.categoryIndex = object.category_index;
        mainNav.nextButton.bind(
          'click keypress',
          $.proxy(this, '_nextCategory')
        );
        mainNav.backButton.bind(
          'click keypress',
          $.proxy(this, '_prevCategory')
        );
        $.each(object.category_options, function (i, category) {
          page_state.vardata[category.name] = (category.answer || []).slice();
        });
      },

      render: function render() {
        ColorPicker.__super__.render.call(this);
        this._setCategory(this._object.category_index);
        $('.dk-check').on('click', function () {
          if ($('.dk-check').attr('checked')) {
            $('.response-button').addClass('hidden-response');
          } else {
            $('.response-button').removeClass('hidden-response');
          }
        });
      },

      _createResponseElements: function () {
        var parentData = { input_type: 'checkbox' };
        var self = this;
        var responses = $.map(
          this._object.response_options,
          function (response) {
            var data = $.extend({}, response, parentData);
            var $el = $(Gryphon.templates['response-button'](data));
            $el.responsebutton({
              onChange: function (isChecked) {
                if (isChecked) {
                  self._addCode(response.code);
                  $el.data('categoryIndex', self.categoryIndex);
                } else {
                  self._removeCode(response.code);
                  $el.removeData('categoryIndex');
                }
              },
            });

            // if it's in vardata, set the color and check it
            $.each(
              self._object.category_options,
              function (categoryIndex, category) {
                if (
                  contains_value(
                    self._categoryCodes(categoryIndex),
                    response.code
                  )
                ) {
                  $el.responsebutton({
                    color: category.color,
                  });
                  $el.responsebutton('check');
                  $el.data('categoryIndex', categoryIndex);
                  self._addCode(response.code, categoryIndex);
                }
              }
            );

            return $el[0];
          }
        );
        var $responses = $(responses);
        this._equaliseButtonHeightsOnImageLoad($responses);
        this._setResponseMargin($responses);
        return $responses;
      },

      _setCategory: function (index, animate, ev) {
        mainNav.disable();

        if (index < 0) {
          try {
            return click_back({ key: ev.key, type: ev.type });
          } catch (e) {
            return click_back({});
          }
        } else if (
          index >= this._object.category_options.length ||
          this._categoryCodes(this.categoryIndex).length >=
            this._responses.length
        ) {
          try {
            return click_next({ key: ev.key, type: ev.type });
          } catch (e) {
            return click_next({});
          }
        }

        this.categoryIndex = index;
        var category = this._object.category_options[index];
        this.$el.find('#page-stem').html(category.text);
        var self = this;
        $.each(this._object.category_options, function (i, category) {
          if (i !== index) {
            self._buttonsInCategory(i).responsebutton('disable');
          } else {
            self._buttonsInCategory(i).responsebutton('enable');
          }
        });
        // set unchecked buttons to an appropriate color
        this._buttonsInCategory(undefined).responsebutton({
          color: category.color,
        });

        if ($(window).scrollTop() > 0 && $.scrollTo) {
          try {
            $.scrollTo(0, {
              duration: ColorPicker.ANIM_DURATION,
              onAfter: $.proxy(this, '_animateCategoryChange', animate),
            });
          } catch (e) {}
        } else {
          this._animateCategoryChange(animate);
        }
      },

      _animateCategoryChange: function _animateCategoryChange(animate) {
        var duration = animate ? ColorPicker.ANIM_DURATION : 0;
        var category = this._object.category_options[this.categoryIndex];

        $('#page-stem').fadeOut(duration, function () {
          $(this)
            .attr('class', 'colorpicker-stem stem-' + category.color)
            .fadeIn(duration, $.proxy(mainNav, 'enable'));
        });
      },

      _prevCategory: function (ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        var animate = true;
        this._setCategory(this.categoryIndex - 1, animate, ev);
      },

      _nextCategory: function (ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        var animate = true;
        this._setCategory(this.categoryIndex + 1, animate, ev);
      },

      _addCode: function _addCode(code, categoryIndex) {
        if (categoryIndex === undefined) {
          categoryIndex = this.categoryIndex;
        }
        var codes = this._categoryCodes(categoryIndex);
        if (!contains_value(codes, code)) {
          codes.push(code);
        }
      },

      _removeCode: function _removeCode(code) {
        var codes = this._categoryCodes(this.categoryIndex);
        Gryphon.util.remove_array_value(codes, code);
      },

      _categoryCodes: function _categoryCodes(categoryIndex) {
        var category = this._object.category_options[categoryIndex];
        return page_state.vardata[category.name];
      },

      /**
       * Return jQuery collection of buttons selected for the given categoryIndex.
       *
       * If categoryIndex is not given or undefined, then return the buttons not
       * assigned to a category.
       */
      _buttonsInCategory: function _buttonsInCategory(categoryIndex) {
        return this._responses.filter(function () {
          return $(this).data('categoryIndex') === categoryIndex;
        });
      },
    },
    {
      types: ['colorpicker'],
      views: ['colorpicker'],
      ANIM_DURATION: 250,
    }
  );

  ColorPicker.register();
  widgets.ColorPicker = ColorPicker;
})((Gryphon.widgets = Gryphon.widgets || {}));

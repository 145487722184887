/*
 * NPM Libraries
 * (TODO) Add additional libraries
 * currently being loaded in dist/index.html
 * ie. Bootstrap, jQuery, jQuery-UI
 *
 */

// JQuery
import 'jquery-ivw';
import 'jquery-ui-ivw/jquery-ui';

// require("webpack-jquery-ui")

// TODO-Split: Make sure that Video dial still works with jquery-ui

// Bootstrap
import 'bootstrap2/js/bootstrap-transition';
import 'bootstrap2/js/bootstrap-alert';
import 'bootstrap2/js/bootstrap-button';
import 'bootstrap2/js/bootstrap-carousel';
import 'bootstrap2/js/bootstrap-collapse';
import 'bootstrap2/js/bootstrap-dropdown';
import 'bootstrap2/js/bootstrap-modal';
import 'bootstrap2/js/bootstrap-tooltip';
import 'bootstrap2/js/bootstrap-popover';
import 'bootstrap2/js/bootstrap-scrollspy';
import 'bootstrap2/js/bootstrap-tab';
import 'bootstrap2/js/bootstrap-typeahead';
import 'bootstrap2/js/bootstrap-affix';

// Plugins
import 'jqueryui-touch-punch/jquery.ui.touch-punch-improved';
import 'jquery.scrollTo/jquery.scrollTo';
import 'jquery.textnodes/dist/jquery.textnodes';
import 'jquery.shyphen/src/jquery.shyphen';
import 'jquery-throttle-debounce/jquery.ba-throttle-debounce';
import 'jquery.uniform/jquery.uniform';
import 'jquery-filedrop/jquery.filedrop';
import 'jquery.validation/dist/jquery.validate';
import 'jquery.validation/src/additional/pattern';
import 'jquery.liveaddress/src/jquery.liveaddress';
import 'jquery-simulate/jquery.simulate';

import _ from 'lodash';
window._ = _;

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "        for=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\"\n";
},"3":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "        for=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.input_name : depth0), depth0))
    + "\"\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "            id=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\"\n            name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "\"\n            value=\""
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\"\n";
  stack1 = __default(require("../js/helpers/ifans.js")).call(depth0, (depth0 != null ? depth0.answer : depth0), (depth0 != null ? depth0.input_id : depth0), (depth0 != null ? depth0.code : depth0), {"name":"ifans","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  return "            checked=\"checked\"\n";
  },"8":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "            id=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.input_name : depth0), depth0))
    + "\"\n            ";
  stack1 = lambda((depth0 != null ? depth0.onclick : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n            name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_name : depth0), depth0))
    + "\"\n";
  stack1 = __default(require("../js/helpers/ifans.js")).call(depth0, (depth0 != null ? depth0.answer : depth0), (depth0 != null ? depth0.input_name : depth0), (depth0 != null ? depth0.code : depth0), {"name":"ifans","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "            data-trailing-popup=\"r-"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.trailer : depth0)) != null ? stack1.input_id : stack1), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\"\n            data-target=\"#r-"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.trailer : depth0)) != null ? stack1.input_idx : stack1), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\"\n";
},"12":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda;
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { return stack1; }
  else { return ''; }
  },"14":function(depth0,helpers,partials,data) {
  var stack1, buffer = "            ";
  stack1 = __default(require("../js/helpers/partial.js")).call(depth0, "open_trailer", (depth0 != null ? depth0.trailer : depth0), {"name":"partial","hash":{},"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"16":function(depth0,helpers,partials,data) {
  var stack1, buffer = "    ";
  stack1 = __default(require("../js/helpers/partial.js")).call(depth0, "popup", (depth0 != null ? depth0.trailer : depth0), {"name":"partial","hash":{
    'code': ((depth0 != null ? depth0.code : depth0))
  },"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"question-response-item w-choice-base\" id=\""
    + escapeExpression(lambda((depth0 != null ? depth0.element_id : depth0), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\">\n    <label\n";
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, (depth0 != null ? depth0.input_type : depth0), "radio", {"name":"ifequal","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, (depth0 != null ? depth0.input_type : depth0), "checkbox", {"name":"ifequal","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        class=\"response-label\"\n        >\n        <input\n            tabindex=\"0\"\n            type=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_type : depth0), depth0))
    + "\"\n";
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, (depth0 != null ? depth0.input_type : depth0), "radio", {"name":"ifequal","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, (depth0 != null ? depth0.input_type : depth0), "checkbox", {"name":"ifequal","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "            class=\"top-level\"\n";
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, ((stack1 = (depth0 != null ? depth0.trailer : depth0)) != null ? stack1.type : stack1), "popup", {"name":"ifequal","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "            aria-labelledby=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "-label\"\n            />\n        <span class=\"label-inner\">\n            <span class=\"label-text\" id=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "-label\">";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.text : depth0), {"name":"if","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</span>\n";
  stack1 = __default(require("../js/helpers/ifindexof.js")).call(depth0, ((stack1 = (depth0 != null ? depth0.trailer : depth0)) != null ? stack1.type : stack1), "open", "==", 0, {"name":"ifindexof","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </span>\n    </label>\n";
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, ((stack1 = (depth0 != null ? depth0.trailer : depth0)) != null ? stack1.type : stack1), "popup", {"name":"ifequal","hash":{},"fn":this.program(16, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"useData":true});
import localization from '../localization';
import { load_preview, prepare_preview } from './preview';
import { init_page_state, clear_page_state } from './state';

global.before_load_new_question = function (res) {
  $(page_state).trigger('before_new_question');
  Gryphon.widgets.instances.invoke('destroy');
  Gryphon.widgets.instances.empty();
  clear_page_state();
  mainNav.unbind();
  init_page_state(res);
  debug_timing_push(2);
  if (check_redirect(res)) {
    return;
  }
  check_scroll(res);
  injectPartnerUrl(res);
  update_pbar(res);
  discover_variables(res);
  check_timing(res);

  var promises = [
    Gryphon.util.do_imports(),
    Gryphon.util.add_css(),
    Gryphon.util.add_js(),
  ];

  $.when
    .apply($, promises)
    .then(function () {
      $(window).trigger('load');
      load_new_question(res);
    })
    .fail(function (error) {
      load_new_question(res);
      // Silently log exception
      console.error('Failed to load', error);
      report.logError(error, true);
    });

  if (window.newrelic) {
    window.newrelic.addPageAction('Next');
  }
};

global.load_new_question = function (res) {
  connect_signal_handlers(res);
  connect_nav_handlers(res);
  set_nav_button_visibility(res);
  set_spd_elements_state(res);
  set_language_direction(res.rtl);
  localization.setLocale(res.language);
  set_page_name_visibility(res);

  var container = null;

  // Fetch localizations for UI strings
  setTimeout(() => {
    localization.fetchLocalizations(res.language);
  }, 1000); // Timeout added to allow rest of UI to render before triggering network request

  /*
      Occasionally, custom QSL Javascript is wrapped with
      $(page_state).on('before_content_loaded', function() {...}

      Will allow logging of any exceptions within the
      `before_content_loaded` event listener separately
    */
  try {
    $(page_state).trigger('before_content_loaded');
  } catch (err) {
    // Rethrow the error with `cause` specified
    throw new Error(err, {
      cause: 'customQSLError',
      page_name: res.init_state.this_page,
    });
  }

  // Stop the loading animation.
  clearInterval(window.loadingTimer);

  // Remove references to form elements about to be removed from the DOM.
  $.uniform.restore();

  if (res.html !== undefined) {
    container = res.container ? res.container : 'main_cont';
    $('#' + container).html(res.html);
  }

  if (nextQuestionStartTime) {
    var endTime = new Date().getTime();
    var timeSpent = endTime - nextQuestionStartTime;
    Gryphon.analytics.trackEvent('system', 'load questions', '', timeSpent);
  }

  // The `native` suffixed trigger is meant to be used only by IVW
  // natively - so we can discern between IVW and custom QSL
  $(page_state).trigger('after_content_loaded_native');

  /*
      Generally, custom QSL Javascript is wrapped with
      $(page_state).on('after_content_loaded', function() {...}

      Will allow logging of any exceptions within the
      `after_content_loaded` event listener separately
    */
  try {
    $(page_state).trigger('after_content_loaded');
  } catch (err) {
    // Rethrow the error with `cause` specified
    throw new Error(err, {
      cause: 'customQSLError',
      page_name: res.init_state.this_page,
    });
  }

  setTimeout(function () {
    // After loading a new question, ensure we're at the top of the page
    $(window).scrollTop(0);

    load_preview();
  });
};

global.get_next_question = function (data) {
  // Handles navigation to root URL (without a VISA)
  if (!segments) {
    document.title = '404 Page Not Found';
    var template = Gryphon.templates['404'];
    return $('#main_cont').html(template);
  }

  data = data || {};
  var client = $.extend({}, Gryphon.util.get_client_caps());

  data.__client__ = JSON.stringify(client);
  data.__version__ = window.appVersion;

  nextQuestionStartTime = new Date().getTime();

  if (!process.env.API_BASE_URL) return;

  prepare_preview();

  // When end of Preview is reached, send user to JSON API response
  if (segments.includes('session_summary')) {
    window.location = localStorage.getItem('admin_url') + '/' + segments;
    return;
  }

  const qURL =
    window.context_name !== 'preview'
      ? process.env.API_BASE_URL + 'q/' + segments
      : localStorage.getItem('admin_url') +
        segments.replace(/preview\//g, '/preview/q/');

  // Set `qParams` to querystring when not submitting answers
  const qParams = set_query_params(data);

  var config = {
    url: qURL + qParams,
    type: 'post',
    dataType: 'json',
    data: data,
    traditional: true,
    timeout: window.ajax_timeout,
    xhrFields: {
      retries: window.ajax_error_max_tries,
      withCredentials: true,
    },
    success: before_load_new_question,
  };

  Gryphon.util.IE_do_not_cache(config);
  Gryphon.util.Firefox_include_content_length(config);

  $.ajax(config).fail(function (xhr) {
    // Handle Status Code 0, Status Text 'error'
    if (
      xhr.statusText === 'error' &&
      xhr.status === 0 &&
      config.xhrFields.retries > 0
    ) {
      try {
        // Get the current question
        $.ajax({
          url: qURL,
          type: 'get',
          data: false,
          traditional: true,
          timeout: window.ajax_timeout,
          success: function (res) {
            // Proceed with request retry only when on the same page
            if (
              res &&
              res.init_state &&
              res.init_state.this_page === page_state.this_page
            ) {
              console.log('retrying request...');
              config.xhrFields.retries--;
              $.ajax(config);
            }
          },
          error: function (res) {
            console.log('error : ' + res);
          },
        });
      } catch (e) {}
    }
  });
};

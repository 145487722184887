// eslint-disable-next-line no-extra-semi
(function (widgets) {
  var Registration = widgets.Widget.extend(
    {
      constructor: function (object) {
        Registration.__super__.constructor.call(this, object);

        this._object = object;

        $('#mainNav').hide();
        $('#join_button').show();
      },
      render: function () {
        Registration.__super__.render.call(this);

        $('.btn-join').on('click', $.proxy(this, '_submit'));

        this.$wrapper = $('.registration-widget');
        this.$emailInput = $('.registration-email-input');
        this.$tosAgree = $('.registration-tos-agree');
      },
      destroy: function () {
        $('#join_button').hide();
        $('#mainNav').show();
        $('.btn-join').off('click', $.proxy(this, '_submit'));
      },
      _validate: function () {
        if (!this.$tosAgree.is(':checked')) {
          return {
            error: 'tos',
            message: this._object.terms_and_conditions_validation_text,
          };
        }

        if (
          this.$emailInput.val().trim() === '' ||
          !this.$emailInput.val().match(/^.+@([a-z.0-9-]+)$/)
        ) {
          return {
            error: 'email',
            message: this._object.email_validation_text,
          };
        }
      },
      _showValidationError: function (validationResult) {
        this.$wrapper.find('.validation-result').remove();
        this.$emailInput.removeClass('alert alert-error');

        if (validationResult.error === 'email') {
          this.$emailInput.addClass('alert alert-error');
        }

        this.$wrapper.prepend(
          `<div class="validation-result alert alert-error">${validationResult.message}</div>`
        );
      },
      _submit: function (e) {
        const validationErrors = this._validate();
        if (validationErrors) {
          return this._showValidationError(validationErrors);
        }

        click_next();
      },
    },
    {
      types: ['registration'],
      views: ['registration'],
    }
  );

  Registration.register();
  widgets.Registration = Registration;
})((Gryphon.widgets = Gryphon.widgets || {}));

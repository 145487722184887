var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = escapeExpression(__default(require("../js/helpers/set.js")).call(depth0, depth0, {"name":"set","hash":{
    'phase_index': ((data && data.index))
  },"data":data}))
    + "\n<div class=\"accordion\" id=\"phase-"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\" ";
  stack1 = __default(require("../js/helpers/ifnotphase.js")).call(depth0, (depths[1] != null ? depths[1].phase_pos : depths[1]), (data && data.index), {"name":"ifnotphase","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " data-phase=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">\n";
  stack1 = helpers.each.call(depth0, depth0, {"name":"each","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"2":function(depth0,helpers,partials,data) {
  return "style=\"display: none;\"";
  },"4":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "  <div ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.varname : depth0), {"name":"if","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " class=\"accordion-group accordion-caret accordion-caret-";
  stack1 = helpers['if'].call(depth0, (depths[2] != null ? depths[2].collapse_expanded : depths[2]), {"name":"if","hash":{},"fn":this.program(7, data, depths),"inverse":this.program(9, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = __default(require("../js/helpers/contains.js")).call(depth0, (depth0 != null ? depth0.varname : depth0), (depths[2] != null ? depths[2].category_errors : depths[2]), {"name":"contains","hash":{},"fn":this.program(11, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n    <div class=\"accordion-heading\">\n      <a class=\"accordion-toggle ";
  stack1 = helpers.unless.call(depth0, (depths[2] != null ? depths[2].collapse_expanded : depths[2]), {"name":"unless","hash":{},"fn":this.program(9, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" data-toggle=\"collapse\" data-parent=\"#phase-"
    + escapeExpression(lambda((depths[1] != null ? depths[1].phase_index : depths[1]), depth0))
    + "\" href=\"#"
    + escapeExpression(lambda((depth0 != null ? depth0.varname : depth0), depth0))
    + "\">\n        <div style=\"content\">\n";
  stack1 = helpers['if'].call(depth0, (depths[2] != null ? depths[2].rowsum : depths[2]), {"name":"if","hash":{},"fn":this.program(13, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "            <i class=\"fa fa-check\"></i><span class=\"accordion-category-text\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1['0'] : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</span>\n        </div>\n        <div class=\"toggle\">\n            <i class=\"fa fa-chevron-down\"></i>\n            <i class=\"fa fa-chevron-up\"></i>\n        </div>\n      </a>\n    </div>\n    <div id=\""
    + escapeExpression(lambda((depth0 != null ? depth0.varname : depth0), depth0))
    + "\" class=\"accordion-body collapse ";
  stack1 = helpers['if'].call(depth0, (depths[2] != null ? depths[2].collapse_expanded : depths[2]), {"name":"if","hash":{},"fn":this.program(15, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n      <div class=\"accordion-inner\">\n        <ul class=\"unstyled\">\n";
  stack1 = helpers.each.call(depth0, (depths[2] != null ? depths[2].response_options : depths[2]), {"name":"each","hash":{},"fn":this.program(17, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </ul>\n      </div>\n    </div>\n  </div>\n  <script>\n    (function () {\n      var grid = $('#phase-"
    + escapeExpression(lambda((depths[1] != null ? depths[1].phase_index : depths[1]), depth0))
    + "');\n      var expanded = 'accordion-caret-expanded';\n      var collapsed = 'accordion-caret-collapsed';\n      var setClasses = function (event) {\n        var el = $(event.target).closest('.accordion-caret');\n        if (event.type === 'show') {\n          el.addClass(expanded)\n            .removeClass(collapsed);\n        } else if (event.type === 'hide') {\n          el.addClass(collapsed)\n            .removeClass(expanded);\n        }\n      };\n      grid.on('show.collapse hide.collapse', setClasses);\n    })();\n  </script>\n";
},"5":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "id=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.varname : depth0), depth0))
    + "\"";
},"7":function(depth0,helpers,partials,data) {
  return "expanded";
  },"9":function(depth0,helpers,partials,data) {
  return "collapsed";
  },"11":function(depth0,helpers,partials,data) {
  return "accordion-group-error";
  },"13":function(depth0,helpers,partials,data) {
  return "            <span class=\"badge pull-right\">0</span>\n";
  },"15":function(depth0,helpers,partials,data) {
  return "in";
  },"17":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "          <li class=\"grid-cell\">\n            ";
  stack1 = __default(require("../js/helpers/partial.js")).call(depth0, "grid-cell-input", {"name":"partial","hash":{
    'label_resp': (__default(require("../js/helpers/get.js")).call(depth0, (depths[3] != null ? depths[3].response_options : depths[3]), (data && data.index), {"name":"get","hash":{},"data":data})),
    'label_cat': (__default(require("../js/helpers/get.js")).call(depth0, (depths[1] != null ? depths[1].text : depths[1]), 0, {"name":"get","hash":{},"data":data})),
    'dk_id': ((depths[3] != null ? depths[3].dk_id : depths[3])),
    'answer': ((depths[1] != null ? depths[1].answer : depths[1])),
    'text': ((depth0 != null ? depth0.text : depth0)),
    'skinny': (true),
    'input_class': ((depth0 != null ? depth0.input_class : depth0)),
    'input_type': ((depths[3] != null ? depths[3].input_type : depths[3])),
    'code': ((depth0 != null ? depth0.code : depth0)),
    'category_name': ((depths[1] != null ? depths[1].name : depths[1])),
    'name': (__default(require("../js/helpers/get.js")).call(depth0, (depths[1] != null ? depths[1].response_inputs : depths[1]), (data && data.index), {"name":"get","hash":{},"data":data}))
  },"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n          </li>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.phases : depth0), {"name":"each","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true,"useDepths":true});
var loadDeferredStyles = function () {
  try {
    var addStylesNode = document.getElementById('deferred-styles');
    var replacement = document.createElement('div');
    replacement.innerHTML = addStylesNode.textContent;
    document.body.appendChild(replacement);
    addStylesNode.parentElement.removeChild(addStylesNode);
  } catch (e) {}
};

var raf =
  requestAnimationFrame ||
  mozRequestAnimationFrame ||
  webkitRequestAnimationFrame ||
  msRequestAnimationFrame;
if (raf) {
  raf(function () {
    window.setTimeout(loadDeferredStyles, 0);
  });
} else {
  window.addEventListener('load', loadDeferredStyles);
}

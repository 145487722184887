module.exports = function (phase, index, options) {
  // phase is `undefined` when the grid doesn't use displaymax, so provide
  // a default.
  phase = phase || 0;
  if (phase !== index) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
};

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  return " grid-layout-striped";
  },"3":function(depth0,helpers,partials,data) {
  return "grid-layout-splitlabels";
  },"5":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "  <thead>\r\n    <tr class=\"grid-row grid-row-"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.response_options : depth0)) != null ? stack1.length : stack1), depth0))
    + "\">\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.header_row : depth0), {"name":"each","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "      ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.rowsum : depth0), {"name":"if","hash":{},"fn":this.program(15, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n    </tr>\r\n  </thead>\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.phases : depth0), {"name":"each","hash":{},"fn":this.program(17, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.colsum : depth0), {"name":"if","hash":{},"fn":this.program(42, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  ";
},"6":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.empty : depth0), {"name":"if","hash":{},"fn":this.program(7, data, depths),"inverse":this.program(10, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"7":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, buffer = "      ";
  stack1 = __default(require("../js/helpers/set.js")).call(depth0, (data && data.root), {"name":"set","hash":{
    'question_column_index': ((data && data.index))
  },"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n      <td ";
  stack1 = __default(require("../js/helpers/unlessequal.js")).call(depth0, (depths[2] != null ? depths[2].resp_width : depths[2]), "auto", {"name":"unlessequal","hash":{},"fn":this.program(8, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "          >";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</td>\r\n";
},"8":function(depth0,helpers,partials,data) {
  return "\r\n          style=\"width: auto\"\r\n";
  },"10":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, buffer = "      <th ";
  stack1 = __default(require("../js/helpers/ifcond.js")).call(depth0, (depths[2] != null ? depths[2].resp_width : depths[2]), "!=", "auto", {"name":"ifcond","hash":{},"fn":this.program(11, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.options : depth0)) != null ? stack1.distinct : stack1), {"name":"if","hash":{},"fn":this.program(13, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "          scope=\"col\" class=\"grid-header-response-";
  stack1 = lambda((depth0 != null ? depth0.code : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</th>\r\n";
},"11":function(depth0,helpers,partials,data,depths) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "\r\n          style=\"width: "
    + escapeExpression(lambda((depths[3] != null ? depths[3].resp_width : depths[3]), depth0))
    + ";\"\r\n";
},"13":function(depth0,helpers,partials,data) {
  return "          class=\"grid-header-distinct\"\r\n";
  },"15":function(depth0,helpers,partials,data,depths) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "<th class=\"total\" scope=\"col\">"
    + escapeExpression(lambda((depths[1] != null ? depths[1].total_label : depths[1]), depth0))
    + "</th>";
},"17":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "  <tbody id=\"phase-"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\" ";
  stack1 = __default(require("../js/helpers/ifnotphase.js")).call(depth0, (depths[1] != null ? depths[1].phase_pos : depths[1]), (data && data.index), {"name":"ifnotphase","hash":{},"fn":this.program(18, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " data-phase=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">\r\n";
  stack1 = helpers.each.call(depth0, depth0, {"name":"each","hash":{},"fn":this.program(20, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </tbody>\r\n";
},"18":function(depth0,helpers,partials,data) {
  return "style=\"display: none;\"";
  },"20":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "    <tr ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.varname : depth0), {"name":"if","hash":{},"fn":this.program(21, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\r\n";
  stack1 = helpers.each.call(depth0, (depths[2] != null ? depths[2].response_options : depths[2]), {"name":"each","hash":{},"fn":this.program(23, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.text : depth0), {"name":"if","hash":{},"fn":this.program(27, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n";
  stack1 = helpers.each.call(depth0, (depths[2] != null ? depths[2].response_options : depths[2]), {"name":"each","hash":{},"fn":this.program(30, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n";
  stack1 = helpers['if'].call(depth0, (depths[2] != null ? depths[2].rowsum : depths[2]), {"name":"if","hash":{},"fn":this.program(32, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n";
  stack1 = helpers['if'].call(depth0, (depths[2] != null ? depths[2].splitlabels : depths[2]), {"name":"if","hash":{},"fn":this.program(34, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </tr>\r\n";
  stack1 = helpers['if'].call(depth0, (depths[2] != null ? depths[2].splitlabels : depths[2]), {"name":"if","hash":{},"fn":this.program(37, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"21":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "id=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.varname : depth0), depth0))
    + "\"";
},"23":function(depth0,helpers,partials,data,depths) {
  var stack1, escapeExpression=this.escapeExpression, buffer = "      "
    + escapeExpression(__default(require("../js/helpers/set.js")).call(depth0, depth0, {"name":"set","hash":{
    'response_index': ((data && data.index))
  },"data":data}))
    + "\r\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.front : depth0), {"name":"if","hash":{},"fn":this.program(24, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"24":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "      <td class=\"grid-cell open-cell ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.options : depth0)) != null ? stack1.distinct : stack1), {"name":"if","hash":{},"fn":this.program(25, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\r\n        ";
  stack1 = __default(require("../js/helpers/partial.js")).call(depth0, "grid-cell-input", {"name":"partial","hash":{
    'label_resp': (__default(require("../js/helpers/get.js")).call(depth0, (depths[4] != null ? depths[4].response_options : depths[4]), (depths[1] != null ? depths[1].response_index : depths[1]), {"name":"get","hash":{},"data":data})),
    'label_cat': (__default(require("../js/helpers/get.js")).call(depth0, (depths[2] != null ? depths[2].text : depths[2]), 0, {"name":"get","hash":{},"data":data})),
    'dk_id': ((depths[4] != null ? depths[4].dk_id : depths[4])),
    'answer': ((depths[2] != null ? depths[2].answer : depths[2])),
    'input_class': ((depth0 != null ? depth0.input_class : depth0)),
    'colsum': ((depths[4] != null ? depths[4].colsum : depths[4])),
    'input_width': ((depths[4] != null ? depths[4].input_width : depths[4])),
    'input_type': ((depths[4] != null ? depths[4].input_type : depths[4])),
    'code': ((depth0 != null ? depth0.code : depth0)),
    'category_name': ((depths[2] != null ? depths[2].name : depths[2])),
    'name': (__default(require("../js/helpers/get.js")).call(depth0, (depths[2] != null ? depths[2].response_inputs : depths[2]), (depths[1] != null ? depths[1].response_index : depths[1]), {"name":"get","hash":{},"data":data}))
  },"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\r\n      </td>\r\n";
},"25":function(depth0,helpers,partials,data) {
  return "grid-cell-distinct";
  },"27":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = __default(require("../js/helpers/or.js")).call(depth0, ((stack1 = ((stack1 = (depths[4] != null ? depths[4].header_row : depths[4])) != null ? stack1['0'] : stack1)) != null ? stack1.empty : stack1), (depths[4] != null ? depths[4].offset : depths[4]), {"name":"or","hash":{},"fn":this.program(28, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"28":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "      <th class=\"grid-item-text-"
    + escapeExpression(lambda((depths[4] != null ? depths[4].cat_text_class : depths[4]), depth0))
    + "\" scope=\"row\">\r\n        ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1['0'] : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\r\n      </th>\r\n";
},"30":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.front : depth0), {"name":"unless","hash":{},"fn":this.program(24, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"32":function(depth0,helpers,partials,data) {
  return "      <th class=\"grid-item-text-right total\" scope=\"row\">\r\n        0\r\n      </th>\r\n";
  },"34":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, buffer = "      <th class=\"grid-item-text-right\"\r\n";
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, (depths[2] != null ? depths[2].resp_width : depths[2]), "auto", {"name":"ifequal","hash":{},"fn":this.program(35, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "          scope=\"row\"\r\n          >\r\n        ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1['1'] : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\r\n      </th>\r\n";
},"35":function(depth0,helpers,partials,data) {
  return "          style=\"width:16%\"\r\n";
  },"37":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = __default(require("../js/helpers/ifskinny.js")).call(depth0, (depths[3] != null ? depths[3].collapse_width : depths[3]), {"name":"ifskinny","hash":{},"fn":this.program(38, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"38":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, buffer = "    <tr class=\"labels\">\r\n      <td colspan=\"";
  stack1 = lambda((depths[4] != null ? depths[4].ritem_num : depths[4]), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\r\n        <table>\r\n          <tr>\r\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.text : depth0), {"name":"if","hash":{},"fn":this.program(39, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "            <th class=\"grid-item-text-right\" scope=\"row\">\r\n              ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1['1'] : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\r\n            </th>\r\n          </tr>\r\n        </table>\r\n      </td>\r\n    </tr>\r\n";
},"39":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = __default(require("../js/helpers/or.js")).call(depth0, ((stack1 = ((stack1 = (depths[6] != null ? depths[6].header_row : depths[6])) != null ? stack1['0'] : stack1)) != null ? stack1.empty : stack1), (depths[6] != null ? depths[6].offset : depths[6]), {"name":"or","hash":{},"fn":this.program(40, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"40":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "            <th class=\"grid-item-text-"
    + escapeExpression(lambda((depths[6] != null ? depths[6].cat_text_class : depths[6]), depth0))
    + "\" scope=\"row\">\r\n              ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1['0'] : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\r\n            </th>\r\n";
},"42":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "  <tfoot>\r\n    <tr class=\"info\">\r\n";
  stack1 = helpers.each.call(depth0, (depths[1] != null ? depths[1].response_options : depths[1]), {"name":"each","hash":{},"fn":this.program(43, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.question_column_index_displayed : depth0), {"name":"unless","hash":{},"fn":this.program(49, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </tr>\r\n  </tfoot>\r\n";
},"43":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, (depths[1] != null ? depths[1].question_column_index : depths[1]), (data && data.index), {"name":"ifequal","hash":{},"fn":this.program(44, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        <th class=\"grid-item total\" data-column=\""
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "\" scope=\"col\">0</th>\r\n";
},"44":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "        ";
  stack1 = __default(require("../js/helpers/set.js")).call(depth0, (data && data.root), {"name":"set","hash":{
    'question_column_index_displayed': (true)
  },"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n        <th class=\"grid-item-text";
  stack1 = helpers.unless.call(depth0, (depths[2] != null ? depths[2].question_column_index : depths[2]), {"name":"unless","hash":{},"fn":this.program(45, data, depths),"inverse":this.program(47, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\" scope=\"row\">"
    + escapeExpression(lambda((depths[2] != null ? depths[2].total_label : depths[2]), depth0))
    + "</th>\r\n";
},"45":function(depth0,helpers,partials,data) {
  return "-left";
  },"47":function(depth0,helpers,partials,data) {
  return "-middle";
  },"49":function(depth0,helpers,partials,data,depths) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "        <th class=\"grid-item-text-middle\" scope=\"row\">"
    + escapeExpression(lambda((depths[1] != null ? depths[1].total_label : depths[1]), depth0))
    + "</th>\r\n";
},"51":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "\r\n  <thead>\r\n    <tr class=\"grid-row grid-row-"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.category_options : depth0)) != null ? stack1.length : stack1), depth0))
    + "\">\r\n      <td ";
  stack1 = __default(require("../js/helpers/unlessequal.js")).call(depth0, (depths[1] != null ? depths[1].resp_width : depths[1]), "auto", {"name":"unlessequal","hash":{},"fn":this.program(8, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "          ></td>\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.phases : depth0), {"name":"each","hash":{},"fn":this.program(52, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </tr>\r\n  </thead>\r\n\r\n  <tbody>\r\n";
  stack1 = helpers.each.call(depth0, (depths[1] != null ? depths[1].response_options : depths[1]), {"name":"each","hash":{},"fn":this.program(56, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </tbody>\r\n  ";
},"52":function(depth0,helpers,partials,data,depths) {
  var stack1, escapeExpression=this.escapeExpression, buffer = "          "
    + escapeExpression(__default(require("../js/helpers/set.js")).call(depth0, depth0, {"name":"set","hash":{
    'category_index': ((data && data.index))
  },"data":data}))
    + "\r\n";
  stack1 = helpers.each.call(depth0, depth0, {"name":"each","hash":{},"fn":this.program(53, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"53":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "          <th ";
  stack1 = __default(require("../js/helpers/ifnotphase.js")).call(depth0, (depths[1] != null ? depths[1].phase_pos : depths[1]), (depths[1] != null ? depths[1].category_index : depths[1]), {"name":"ifnotphase","hash":{},"fn":this.program(18, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = __default(require("../js/helpers/ifcond.js")).call(depth0, (depths[2] != null ? depths[2].resp_width : depths[2]), "!=", "auto", {"name":"ifcond","hash":{},"fn":this.program(54, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "              data-phase=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].category_index : depths[1]), depth0))
    + "\"\r\n              scope=\"col\" class=\"";
  stack1 = lambda((depth0 != null ? depth0.varname : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1['0'] : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</th>\r\n";
},"54":function(depth0,helpers,partials,data,depths) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "\r\n              style=\"width: "
    + escapeExpression(lambda((depths[3] != null ? depths[3].resp_width : depths[3]), depth0))
    + ";\"\r\n";
},"56":function(depth0,helpers,partials,data,depths) {
  var stack1, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "    "
    + escapeExpression(__default(require("../js/helpers/set.js")).call(depth0, depth0, {"name":"set","hash":{
    'response_index': ((data && data.index))
  },"data":data}))
    + "\r\n    <tr ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.varname : depth0), {"name":"if","hash":{},"fn":this.program(21, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\r\n      <th class=\"grid-item-text-"
    + escapeExpression(lambda((depths[2] != null ? depths[2].cat_text_class : depths[2]), depth0))
    + "\" scope=\"row\">\r\n        ";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n      </th>\r\n";
  stack1 = helpers.each.call(depth0, (depths[1] != null ? depths[1].phases : depths[1]), {"name":"each","hash":{},"fn":this.program(57, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </tr>\r\n";
},"57":function(depth0,helpers,partials,data,depths) {
  var stack1, escapeExpression=this.escapeExpression, buffer = "      "
    + escapeExpression(__default(require("../js/helpers/set.js")).call(depth0, depth0, {"name":"set","hash":{
    'category_index': ((data && data.index))
  },"data":data}))
    + "\r\n";
  stack1 = helpers.each.call(depth0, depth0, {"name":"each","hash":{},"fn":this.program(58, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"58":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "      <td ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.varname : depth0), {"name":"if","hash":{},"fn":this.program(21, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " class=\"grid-cell\" ";
  stack1 = __default(require("../js/helpers/ifnotphase.js")).call(depth0, (depths[1] != null ? depths[1].phase_pos : depths[1]), (depths[1] != null ? depths[1].category_index : depths[1]), {"name":"ifnotphase","hash":{},"fn":this.program(18, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " data-phase=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].category_index : depths[1]), depth0))
    + "\">\r\n        ";
  stack1 = __default(require("../js/helpers/partial.js")).call(depth0, "grid-cell-input", {"name":"partial","hash":{
    'label_resp': (__default(require("../js/helpers/get.js")).call(depth0, (depths[4] != null ? depths[4].response_options : depths[4]), (depths[2] != null ? depths[2].response_index : depths[2]), {"name":"get","hash":{},"data":data})),
    'label_cat': (__default(require("../js/helpers/get.js")).call(depth0, (depth0 != null ? depth0.text : depth0), 0, {"name":"get","hash":{},"data":data})),
    'dk_id': ((depths[4] != null ? depths[4].dk_id : depths[4])),
    'answer': ((depth0 != null ? depth0.answer : depth0)),
    'input_class': ((depths[2] != null ? depths[2].input_class : depths[2])),
    'colsum': ((depths[4] != null ? depths[4].colsum : depths[4])),
    'input_width': ((depths[4] != null ? depths[4].input_width : depths[4])),
    'input_type': ((depths[4] != null ? depths[4].input_type : depths[4])),
    'code': ((depths[2] != null ? depths[2].code : depths[2])),
    'category_name': ((depth0 != null ? depth0.name : depth0)),
    'name': (__default(require("../js/helpers/get.js")).call(depth0, (depth0 != null ? depth0.response_inputs : depth0), (depths[2] != null ? depths[2].response_index : depths[2]), {"name":"get","hash":{},"data":data}))
  },"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\r\n      </td>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<table aria-label=\"Table options\" class=\"grid-layout";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.stripes : depth0), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.splitlabels : depth0), {"name":"if","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" id=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "-grid\">\r\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.transpose : depth0), {"name":"unless","hash":{},"fn":this.program(5, data, depths),"inverse":this.program(51, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\r\n</table>\r\n";
},"useData":true,"useDepths":true});
'use strict';

export function _render(object) {
  var items = $.map(object.rows, function (row) {
    return row.text;
  });
  var bins = $.map(object.response_options, function (option) {
    return option.text;
  });
  $('#' + object.input_id + '-cardsort').cardsort({
    items,
    bins,
    // binWidth: object.bin_width,
    // binHeight: object.bin_height,
    // itemWidth: object.item_width,
    // itemHeight: object.item_height,
    // scaleBinItems: object.scale_bin_items
  });
}

export default class {
  render(self, object) {
    _render(object);
  }
}

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  return "  <script src=\"https://prod.rtymgt.com/static/js/review/sc_review_lib_secured_v1-2.min.js?v=1.0.1\" type=\"text/javascript\"></script>\n";
  },"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '      ', 'error', depth0, {
    'extra_classes': ("control-hint")
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var stack1, buffer = " ";
  stack1 = __default(require("../js/helpers/partial.js")).call(depth0, "dk-partial", {"name":"partial","hash":{
    'dk_value': (997),
    'dk_id': ((depth0 != null ? depth0.dk_id : depth0)),
    'dk_text': ((depth0 != null ? depth0.dont_know : depth0)),
    'dk_align': ("left")
  },"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " ";
},"7":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "  <input\n    type=\"hidden\"\n    id=\"rd_"
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "_copy_paste\"\n  />\n  <input\n    type=\"hidden\"\n    id=\"rd_"
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "_time_page_load\"\n  />\n  <input\n    type=\"hidden\"\n    id=\"rd_"
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "_time_text_entry\"\n  />\n  <input\n    type=\"hidden\"\n    id=\"rd_"
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "_time_submit_entry\"\n  />\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.protect : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<div\n  class=\"question question-"
    + escapeExpression(lambda((depth0 != null ? depth0.type : depth0), depth0))
    + "\"\n  aria-labelledby=\""
    + escapeExpression(lambda((depth0 != null ? depth0.element_id : depth0), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "label\"\n>\n  <div class=\"question-text\" id=\""
    + escapeExpression(lambda((depth0 != null ? depth0.element_id : depth0), depth0))
    + "-"
    + escapeExpression(lambda((depth0 != null ? depth0.code : depth0), depth0))
    + "label\">";
  stack1 = lambda((depth0 != null ? depth0.qtext : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n  <div class=\"question-open-ended-input\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <div class=\"question-open-ended-above\">";
  stack1 = lambda((depth0 != null ? depth0.above : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n    <div class=\"question-open-ended-main\">\n      <span class=\"question-open-ended-left\">";
  stack1 = lambda((depth0 != null ? depth0.left : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</span>\n";
  stack1 = this.invokePartial(require("./open_inputarea.hbs"), '      ', 'open_inputarea', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "      <span class=\"question-open-ended-right\">";
  stack1 = lambda((depth0 != null ? depth0.right : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</span>\n      ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.dk : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    </div>\n    <div class=\"question-open-ended-below\">";
  stack1 = lambda((depth0 != null ? depth0.below : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n  </div>\n</div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.protect : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
/*
 * Card Sort v0.4.3
 *
 * Written by Mark Florian
 */
(function ($) {
  'use strict';

  var map = $.map,
    each = $.each,
    filter = $.grep,
    indexOf = $.inArray,
    cs = 'cardsort',
    csItem = cs + '-item-index',
    csc = cs + '-container',
    cscSkinny = cs + '-container-skinny',
    csic = cs + '-items',
    csicSkinny = cs + '-items-skinny',
    csbc = cs + '-bins',
    csb = cs + '-bin',
    csbl = csb + '-list',
    csi = cs + '-item';

  $.widget('yougov.cardsort', {
    options: {
      exclusiveBins: [],
      scaleBinItems: true,
      fixError: [
        'Please provide a response for each item in the grid',
        'Please put all the items in the boxes below',
      ],
      binWidth: 200,
      binHeight: 150,
      itemWidth: 200,
      itemHeight: 100,
    },

    _create: function () {
      this.scope = cs + '-' + Math.random();

      this.question = {
        items: this.options.items,
        bins: this.options.bins,

        // Inputs MUST be in "grid order", as they are to be rendered
        // after randomisation, i.e.,
        // for category in categories:
        //   for response in responses:
        //     input
        inputs: $('input', this.element),
      };

      this.bins = [];

      this.container = this._makeContainer()
        .append(this._makeSourceBin())
        .append(this._makeBins());
      this._makeItems();
      this.element.after(this.container).hide();
      this.containerSkinny = this._makeContainerSkinny().append(
        this._makeSkinnyDropdown()
      );

      //Appending skinny/mobile-veiw container after container without changing the main container
      this.container.after(this.containerSkinny);
    },

    _init: function () {
      this._readInputs();
      this._normaliseBins();
      this._fixErrorMessage(this.options.fixError[0], this.options.fixError[1]);
    },

    _makeContainer: function () {
      return $('<div class="' + csc + ' ui-widget"></div>');
    },

    _makeContainerSkinny: function () {
      return $('<div class="' + cscSkinny + ' ui-widget"></div>');
    },

    _makeSkinnyDropdown: function () {
      var self = this;
      var skinnyContainer = $(`<div class="${csicSkinny}"></div>`);
      var bins = this.question.bins;
      this.question.items.forEach(function (item, index) {
        var cardsortSkinny = $(
          Gryphon.templates['cardsort-skinny']({
            text: item,
            label: index + 1,
            required_text: self.options.required_text,
          })
        );
        if (item.indexOf('<img ') > 0) {
          var cardsortText = cardsortSkinny.find(`#cardsort-text-${index + 1}`);
          cardsortText[0].innerHTML = item;
          cardsortText.addClass('cardsort-image-text');
        }
        var dropdown = cardsortSkinny.find(`#cardsort-select-${index + 1}`);
        dropdown.children().prop('disabled', self.options.unique);
        var prevValue = null;
        bins.forEach(function (bin) {
          dropdown.append(
            $('<option></option>')
              .attr('value', bin)
              .text(bin.replace(/(<([^>]+)>)/gi, ''))
          );
        });
        // Focus event only if unique === TRUE, so the the previous option can be captured.
        if (self.options.unique) {
          dropdown.on('focus', function (e) {
            prevValue = this.value;
          });
        }
        // change event for the dropdowns
        dropdown.on('change', { itemIndex: `${index}` }, function (event) {
          //changing drag and drops so that both are in sync
          var item = self.items[event.data.itemIndex];
          var bin = self.bins[bins.indexOf(this.value) + 1];
          self.set(item, bin, true, prevValue);
          this.blur();
        });

        skinnyContainer.append(cardsortSkinny);
      });
      return skinnyContainer;
    },

    _makeBins: function () {
      var binContainer = $('<div></div>', { class: csbc }),
        bins = map(this.question.bins, $.proxy(this._makeBin, this));

      each(bins, function (index, bin) {
        binContainer.append(bin);
      });

      return binContainer;
    },

    _makeBin: function (title, index) {
      var width = this.options.binWidth,
        height = this.options.binHeight,
        bin = new Bin(title, width, height, this);

      if (indexOf(index, this.options.exclusiveBins) !== -1) {
        bin.element.droppable('option', 'accept', function () {
          return !bin.items.length;
        });
      }

      this.bins.push(bin);

      return bin.element;
    },

    _makeSourceBin: function () {
      var source = new ItemBin(this);

      this.bins.push(source);

      return source.element;
    },

    _makeItems: function () {
      var sourceBin = this.bins[0],
        items = map(this.question.items, $.proxy(this._makeItem, this));

      each(items, function (index, item) {
        sourceBin.element.append(item.element);
        sourceBin.add(item);
      });

      this.items = items;
    },

    _makeItem: function (content) {
      var width = this.options.itemWidth,
        height = this.options.itemHeight;

      return new Item(content, width, height, this);
    },

    set: function (item, bin, dontUpdateSkinny, prevValue) {
      // when dontUpdateSkinny is true the dropdowns will not be updated
      var oldBin = this._getBinWithItem(item);

      oldBin.remove(item);
      bin.add(item);

      this._set(item, bin);
      var dropdownIndex = -1;
      this.question.items.some(function (data, i) {
        if (data.indexOf(item.binElement[0].title) >= 0) {
          dropdownIndex = i;
          return true;
        }
      });
      if (!dontUpdateSkinny) {
        var optionText = null;
        $.each($(`#cardsort-select-1`).children(), function (key, option) {
          if (option.text === bin.element[0].childNodes[0].textContent) {
            optionText = option.value;
            return false;
          }
        });
        // bin.element[0].classList[0] === 'cardsort-bin' signifies when item is dropped in bin ; then value (bin.element[0].childNodes[0].innerHTML) is set
        // bin.element[0].classList[0] !== 'cardsort-bin' signifies when item is popped out of bin ; then null is set
        $(
          `#cardsort-select-${dropdownIndex + 1} option[value="${
            bin.element[0].classList[0] === 'cardsort-bin' ? optionText : null
          }"]`
        ).prop('selected', true);
      }
      if (this.options.unique) {
        var binList = [];
        for (let index = 0; index < this.question.items.length; index++) {
          var value = $(`#cardsort-select-${index + 1}`).val();
          if (value !== 'null' && !binList.includes(value)) {
            binList.push(value);
          }
        }
        this._setUniqueConfig(this.question.items, binList, dropdownIndex);
      }
    },
    // Will make sure user shouldn't be able to assign more than one card to the same answer when "unique=True"
    _setUniqueConfig: function (items, binList, dropdownIndex) {
      items.forEach(function (item, i) {
        $.each(
          $(`#cardsort-select-${i + 1}`).children(),
          function (key, option) {
            binList.forEach(function (bin) {
              if (
                option.value === bin ||
                (option.value === 'null' &&
                  $(`#cardsort-select-${dropdownIndex + 1}`).val() === 'null' &&
                  i === dropdownIndex)
              ) {
                $(
                  `#cardsort-select-${i + 1} option[value="${option.value}"]`
                ).prop('disabled', true);
              } else if (!binList.includes(option.value)) {
                $(
                  `#cardsort-select-${i + 1} option[value="${option.value}"]`
                ).prop(
                  'disabled',
                  option.value === 'null' &&
                    $(`#cardsort-select-${i + 1}`).val() !== 'null'
                    ? false
                    : i !== dropdownIndex && option.value === 'null'
                    ? true
                    : false
                );
              }
            });
            if (binList.length === 0) {
              $(
                `#cardsort-select-${i + 1} option[value="${option.value}"]`
              ).prop('disabled', option.value === 'null' ? true : false);
            }
          }
        );
      });
    },

    _getBinWithItem: function (item) {
      var bin = filter(this.bins, hasItem);

      function hasItem(bin) {
        return bin.has(item);
      }

      return bin[0];
    },

    _readInputs: function () {
      var self = this,
        bins = this.question.bins.length;

      each(this.question.inputs, set);

      function set(index, input) {
        var itemIndex, column, item, bin;

        if (input.checked) {
          itemIndex = Math.floor(index / bins);
          column = index % bins;
          item = self.items[itemIndex];

          // Plus one because the first bin is the source bin
          bin = self.bins[column + 1];

          self.set(item, bin);
        }
      }
    },

    _normaliseBins: function () {
      var binHeaders = map(this.bins, function (bin, index) {
        // the first bin is the source bin
        if (index) {
          return bin.element.find('label')[0];
        }
      });

      // Ugly fix for Chrome
      setTimeout(function () {
        $(binHeaders).equalisr('height');
      }, 100);
    },

    _fixErrorMessage: function (pattern, fixed) {
      var el = this.element.prev().find('.error_message'),
        elText = el.text();

      el.text(elText.replace(pattern, fixed));
    },

    _set: function (item, bin) {
      var itemIndex = indexOf(item, this.items),
        // Subtract one because the first bin is the source bin
        binIndex = indexOf(bin, this.bins) - 1,
        bins = this.question.bins.length,
        inputs = this.question.inputs,
        i;

      if (binIndex === -1) {
        for (i = itemIndex * bins; i < itemIndex * bins + bins; i++) {
          inputs[i].checked = false;
        }
        $(window).trigger('card::reset');
      } else {
        inputs[itemIndex * bins + binIndex].checked = true;
        $(window).trigger('card::sorted', [item.element[0].title]);
      }
    },
  });

  var text = (function ($) {
    var div = $('<div></div>');
    return function text(content) {
      return $.trim(div.html(content).text());
    };
  })($);

  function Bin(title, width, height, cardsort) {
    this.cardsort = cardsort;
    this.build(title, width, height);
    this.items = [];
  }

  Bin.prototype = {
    build: function (title, width, height) {
      var bin = $('<div class="' + csb + '"></div>'),
        header = $('<label>' + title + '</label>'),
        self = this,
        itemContainer = (this.itemContainer = $(
          '<div class="' + csbl + ' one-column"></div>'
        ).height(height));
      var unique = this.cardsort.options.unique;

      if (this.cardsort.options.binHeight === 'auto') {
        itemContainer.addClass('auto-height');
      }

      bin
        .append(header)
        .append(itemContainer)
        .droppable({
          tolerance: 'pointer',
          activeClass: cs + '-accepts',
          hoverClass: cs + '-accepts-hover',
          scope: this.cardsort.scope,
          accept: function (draggable) {
            var totalItems = $(this).find('.cardsort-item').length;
            if (unique && totalItems) {
              return false;
            }

            return !self.has(draggable.data(csItem));
          },
          drop: function (event, ui) {
            self.cardsort.set(ui.draggable.data(csItem), self);
          },
        })
        .width(width);

      this.element = bin;
    },

    add: function (item) {
      this.itemContainer.append(item.binElement);
      this.items.push(item);
      this.update();
    },

    remove: function (item) {
      var index = indexOf(item, this.items);

      if (index !== -1) {
        this.items.splice(index, 1);
        item.binElement.detach();
      }

      this.update();
    },

    update: function () {
      if (!this.cardsort.options.scaleBinItems) {
        return;
      }

      var number = this.items.length,
        className;
      this.itemContainer.removeClass('one-column two-column three-column');

      if (number < 2) {
        className = 'one-column';
      } else if (number < 5) {
        className = 'two-column';
      } else {
        className = 'three-column';
      }

      this.itemContainer.addClass(className);
    },

    has: function (item) {
      return indexOf(item, this.items) !== -1;
    },
  };

  function ItemBin(cardsort) {
    this.cardsort = cardsort;
    this.build();
    this.items = [];
  }

  ItemBin.prototype = {
    build: function () {
      var itemContainer = $('<div class="' + csic + '"></div>'),
        self = this;

      itemContainer.droppable({
        activeClass: cs + '-active',
        hoverClass: cs + '-hover',
        scope: this.cardsort.scope,
        accept: '.sorted',
        drop: function (event, ui) {
          self.cardsort.set(ui.draggable.data(csItem), self);
        },
      });

      this.element = itemContainer;
    },

    add: function (item) {
      this.items.push(item);
      item.element.removeClass('cardsort-item-empty');
    },

    remove: function (item) {
      var index = indexOf(item, this.items);

      if (index !== -1) {
        this.items.splice(index, 1);
        item.element.addClass('cardsort-item-empty');
      }
    },

    has: function (item) {
      return indexOf(item, this.items) !== -1;
    },
  };

  function Item(content, width, height, cardsort) {
    this.cardsort = cardsort;
    this.build(content, width, height);
  }

  Item.prototype = {
    build: function (content, width, height) {
      var elOptions = {
          zIndex: 100,
          revert: 'invalid',
          helper: this.helper,
          scope: this.cardsort.scope,
          appendTo: this.cardsort.container,
          cancel: '.cardsort-item-empty',
        },
        binElOptions = {
          zIndex: 100,
          revert: 'invalid',
          helper: this.helper,
          scope: this.cardsort.scope,
          appendTo: this.cardsort.container,
        };

      var elHtml = `
            <div class="icon">
                <button aria-haspopup="true"
                        role="button"
                        title="Move ${text(content)}"
                        tabindex="0">
                    <span class="assistive-text">Move ${text(content)}</span>
                </button>
                <ul class="accessible-dropdown" role="menu"></ul>
            </div>
            ${content}
            `;

      this.element = $('<div></div>', {
        class: csi,
        title: text(content),
      })
        .addClass('accessible-dropdown-trigger')
        .html(elHtml)
        .draggable(elOptions)
        .data(csItem, this);

      var menu = this.element.find('.accessible-dropdown');
      $.each(this.cardsort.question.bins, function () {
        menu.append(
          '<li role="menuitem" data-drop="' +
            this.replace(/(<([^>]+)>)/gi, '') +
            '" data-source="' +
            text(content) +
            '">' +
            this +
            '</li>'
        );
      });
      setTimeout(() => {
        // sets the position of the accessibility menu based on the position of
        // the parent element (card) with additional padding for shadows / borders
        menu.css('left', this.element.offset().left + menu.width() + 4 + 'px');
      });

      this.binElement = this.element
        .clone()
        .draggable(binElOptions)
        .data(csItem, this)
        .addClass('sorted');

      this.element.height(height).width(width);
    },

    helper: function () {
      var $this = $(this),
        width = $this.width(),
        height = $this.height();

      return $this.clone().width(width).height(height);
    },
  };
})(jQuery);

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '  ', 'error', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "    <div class=\"video-js-canvas-wrapper\" style=\"display: none\">\n      <canvas\n          id=\"canvasplayer\"\n          ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.width : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n          ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.height : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n          class=\"canvas video-js-canvas\">\n      </canvas>\n      <audio id=\"audioplayer\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.sources : depth0), {"name":"each","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "      </audio>\n      <div class=\"video-js-canvas-overlay\"></div>\n    </div>\n    <video\n        ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.width : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n        ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.height : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n        ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.autoplay : depth0), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n        webkit-playsinline\n        playsinline\n        id=\"player\"\n        class=\"video-js vjs-default-skin\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.sources : depth0), {"name":"each","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </video>\n";
},"4":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "width=\""
    + escapeExpression(lambda((depth0 != null ? depth0.width : depth0), depth0))
    + "\"";
},"6":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "height=\""
    + escapeExpression(lambda((depth0 != null ? depth0.height : depth0), depth0))
    + "\"";
},"8":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "        <source src=\""
    + escapeExpression(lambda((depth0 != null ? depth0.src : depth0), depth0))
    + "\" type=\""
    + escapeExpression(lambda((depth0 != null ? depth0.type : depth0), depth0))
    + "\">\n";
},"10":function(depth0,helpers,partials,data) {
  return "autoplay";
  },"12":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "      <source src=\""
    + escapeExpression(lambda((depth0 != null ? depth0.src : depth0), depth0))
    + "\" type=\""
    + escapeExpression(lambda((depth0 != null ? depth0.type : depth0), depth0))
    + "\">\n";
},"14":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "    <input type=\"hidden\" id=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.marker_input : depth0), depth0))
    + "\" name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.marker_input : depth0), depth0))
    + "\">\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"question question-"
    + escapeExpression(lambda((depth0 != null ? depth0.type : depth0), depth0))
    + "\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n  <div id=\"videoplayer\">\n";
  stack1 = helpers['with'].call(depth0, (depth0 != null ? depth0.player : depth0), {"name":"with","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <input type=\"hidden\" id=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "\" name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.input_id : depth0), depth0))
    + "\" value=\""
    + escapeExpression(__default(require("../js/helpers/ans.js")).call(depth0, (depth0 != null ? depth0.answer : depth0), (depth0 != null ? depth0.input_id : depth0), {"name":"ans","hash":{},"data":data}))
    + "\">\n    <input type=\"hidden\" id=\"r-"
    + escapeExpression(lambda((depth0 != null ? depth0.status_input : depth0), depth0))
    + "\" name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.status_input : depth0), depth0))
    + "\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.marker_input : depth0), {"name":"if","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n</div>\n";
},"usePartial":true,"useData":true});
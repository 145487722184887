module.exports = function (options) {
  if (!options) {
    return '';
  }

  // Only use this helper when the content is expected to be just text.
  if (typeof options === 'string') {
    return options.toLowerCase();
  }

  var text = options.fn(this);
  return typeof text === 'string' ? text.toLowerCase() : '';
};

(function (widgets) {
  var Rule = widgets.Widget.extend(
    {
      constructor: function RuleConstructor(object) {
        Rule.__super__.constructor.call(this, object);
        this._ruleOptions = {};
        var _this = this;
        var savedWindowWidth = window.innerWidth;

        // flip labels for RTL
        if ($('html').attr('dir') === 'rtl') {
          var copy = $.extend({}, this._object),
            rtl_left_message = copy.right_message,
            rtl_right_message = copy.left_message;
          this._object.left_message = rtl_left_message;
          this._object.right_message = rtl_right_message;
        }

        $.extend(this._ruleOptions, {
          narrow_view: object.narrow_view || false,
          wide_view: object.wide_view || false,
        });

        $(window).off(
          'change',
          '#' + this._object.element_id + ' .slider-input',
          function () {}
        );
        $(window).off(
          'keydown',
          '#' + this._object.element_id + ' .slider-input'
        );
        $(window).off(
          'keydown',
          '#' + this._object.element_id + ' .slider-indicator-dot'
        );
        $(window).on(
          'focus',
          '#' + this._object.element_id + ' .slider-input',
          function (event) {
            global.disable_dk_check();
            $('#' + _this._object.input_id + '-value').val($(this).val());
          }
        );
        $(window).on(
          'keydown',
          '#' + this._object.element_id + ' .slider-input',
          function (event) {
            var value = this.value;
            switch (event.keyCode) {
              case 38: // up
              case 39: // right
                value =
                  value === '' || value === null
                    ? 1
                    : parseInt(value) === _this._object.max
                    ? value
                    : parseInt(value) + 1;
                _this._updateRuleDrag(
                  _this._calculatePercentageFromLimits(value, _this),
                  value,
                  _this.$el
                );
                break;
              case 37: // left
              case 40: // down
                value =
                  value === '' || value === null
                    ? 1
                    : parseInt(value) === _this._object.min
                    ? value
                    : parseInt(value) - 1;
                _this._updateRuleDrag(
                  _this._calculatePercentageFromLimits(value, _this),
                  value,
                  _this.$el
                );
                break;
              default:
                break;
            }
          }
        );
        $(window).on(
          'keydown',
          '#' + this._object.element_id + ' .slider-indicator-dot',
          function (event) {
            const value = $(
              '#' + _this._object.element_id + ' .rule-ruler'
            ).slider('value');

            // Arrow keys only
            switch (event.keyCode) {
              case 37:
              case 38:
              case 39:
              case 40:
                if (value !== '') {
                  _this._updateValues(value);
                }
                break;
              default:
                break;
            }
          }
        );
        $(window).on(
          'change',
          '#' + this._object.element_id + ' .slider-input',
          function () {
            var value = _this._checkValueLimits(this.value, _this);
            _this._updateRuleDrag(
              _this._calculatePercentageFromLimits(value, _this),
              value,
              _this.$el
            );
          }
        );

        handle_keyboard_checkbox('.dk-input input');
      },

      destroy: function destroy() {
        Rule.__super__.destroy.call(this);
        $(window).off('.rule'); // unbinds all .rule event handlers.
      },

      render: function render(rValue, iRatio) {
        Rule.__super__.render.call(this);
        this.$el.find('input:checkbox').uniform();
        var that = this;
        var min = Number(this._object.min),
          max = Number(this._object.max),
          maxLength =
            Math.abs(max) > Math.abs(min)
              ? max.toString().length
              : min.toString().length;
        this.$el
          .find('.slider-input')
          .attr({ min, max })
          .css('max-width', `${18 * maxLength}px`); // base font size (M-width)
        var dkValue = this._object.dk_value;
        var dir = $('html').attr('dir') || 'ltr';
        // #47335, values needs to be reversed for RTL
        var range = dir === 'rtl' ? [max, min] : [min, max];
        var rule_aa = this._object.rule_aa;
        var tpl;

        function enableRule() {
          that.$el.find('.responsive-grid-wide .rule-drag').css('opacity', 1);
          that.$el.find('.responsive-grid-skinny .rule-drag').css('opacity', 1);
        }

        function disableRule() {
          that.$el.find('.responsive-grid-wide .rule-drag').css('opacity', 0);
          that.$el.find('.responsive-grid-skinny .rule-drag').css('opacity', 0);
        }

        this.$el.find('.slider-input').on('focus', function () {
          enableRule();
        });

        function init_rule() {
          var id = that._object.input_id;
          var elm = $('#' + that._object.element_id);
          var ruleElem,
            dkElem,
            indicatorElem = $('#' + id + '-indicator');

          var valueElem = $('#' + id + '-value');

          elm.find('.responsive-grid-wide .rule-ruler').slider({
            orientation: that._isWideViewVertical() ? 'vertical' : 'horizontal',
            // Note: In order to get a vertical ruler where the min value is at the top,
            // we invert the min/max values with negative numbers, converting back to
            // whole numbers with Math.abs when updating inputs
            min: that._isWideViewVertical()
              ? -that._object.max
              : that._object.min,
            max: that._isWideViewVertical()
              ? -that._object.min
              : that._object.max,
            slide: function (event, ui) {
              if (event.keyCode === undefined) {
                var percentage = that._calculatePercentageFromLimits(
                  that._isWideViewVertical() ? -ui.value : ui.value,
                  that
                );
                that._updateRuleDrag(
                  percentage,
                  that._isWideViewVertical() ? -ui.value : ui.value,
                  elm
                );
                // enables autoadvance option from widget options
                if (rule_aa && typeof click_next === 'function') {
                  click_next({});
                }
              }
            },
            change: function (event, ui) {
              if (elm.find('.dk-check').attr('checked')) {
                global.disable_dk_check(event.target.id + 'dk');
                enableRule();
              }
              // triggered by user
              if (event.eventPhase) {
                enableRule();
                // this check is only for the initial 'change' from the default (middle)
                // ensures that when the rule input is shown it's value is populated as well
                if (
                  event.keyCode === undefined &&
                  elm.find('.slider-input').val() !== ui.value
                ) {
                  var percentage = that._calculatePercentageFromLimits(
                    that._isWideViewVertical() ? -ui.value : ui.value,
                    that
                  );
                  that._updateRuleDrag(
                    percentage,
                    that._isWideViewVertical() ? -ui.value : ui.value,
                    elm
                  );
                }
              }
            },
            create: function (event, ui) {
              //Only Pre-render the slider when there is a previous answer
              if (that._object.answer) {
                var previousValue = that._object.answer;

                var percentage =
                  ((previousValue - that._object.min) * 100) /
                  (that._object.max - that._object.min);
                if (percentage <= 100) {
                  if (that._isWideViewVertical()) {
                    elm.find('.responsive-grid-wide .rule-drag').css({
                      top: percentage + '%',
                    });
                  } else {
                    elm.find('.responsive-grid-wide .rule-drag').css({
                      left: percentage + '%',
                    });
                  }
                }
              }
            },
          });

          elm.find('.responsive-grid-skinny .rule-ruler').slider({
            orientation: 'vertical',
            // Note: In order to get a vertical ruler where the min value is at the top,
            // we invert the min/max values with negative numbers, converting back to
            // whole numbers with Math.abs when updating inputs
            min: -that._object.max,
            max: -that._object.min,
            slide: function (event, ui) {
              if (event.keyCode === undefined) {
                var percentage = that._calculatePercentageFromLimits(
                  -ui.value,
                  that
                );
                that._updateRuleDrag(percentage, -ui.value, elm);
                // enables autoadvance option from widget options
                if (rule_aa && typeof click_next === 'function') {
                  click_next({});
                }
              }
            },
            create: function (event, ui) {
              //Only Pre-render the slider when there is a previous answer
              if (that._object.answer) {
                var previousValue = that._object.answer;

                var percentage = that._calculatePercentageFromLimits(
                  previousValue,
                  that
                );
                if (percentage <= 100) {
                  elm.find('.responsive-grid-skinny .rule-drag').css({
                    top: percentage + '%',
                  });
                  ui.value = -previousValue;
                  that._updateValues(previousValue);
                }
              }
            },
          });

          var checker;
          checker = setInterval(function () {
            id = that._object.input_id;
            elm = $('#' + that._object.element_id);
            ruleElem = elm.find('.rule-ruler');
            indicatorElem = $('#' + id + '-indicator');
            valueElem = $('#' + id + '-value');
            dkElem = $('#r-' + that._object.dk_id);

            if (ruleElem.is(':visible')) {
              clearInterval(checker);

              var value = rValue ? rValue : parseInt(valueElem.val(), 10);
              if ($.isNumeric(value)) {
                if (value === dkValue) {
                  dkElem.prop('checked', true);
                  $(`#uniform-r-${that._object.dk_id} span`).addClass(
                    'uniform-checked'
                  );
                  disableRule();
                } else {
                  // If we have a previous answer, we need to show the slide indicator
                  // and set the value of our slider
                  that._updateValues(value);
                }
              }

              dkElem.click(function () {
                if ($(this).prop('checked')) {
                  disableRule();
                  valueElem.val(dkValue);
                } else {
                  enableRule();
                  valueElem.val(elm.find('.slider-input').val());
                }
                $(this).blur().trigger('changed');
              });
            }
          }, 300);
        }
        init_rule();
      },

      // [Setter] Updates input and slider values
      _updateValues: function _updateValues(value) {
        if ($('.no-value').length) {
          $('.no-value').removeClass('no-value');
        }
        var id = this._object.input_id;
        var elm = $('#' + this._object.element_id);
        var valueElem = $('#' + id + '-value');

        elm
          .find('.responsive-grid-wide .rule-ruler')
          .slider('option', 'value', value);
        elm
          .find('.responsive-grid-skinny .rule-ruler')
          .slider('option', 'value', -value);
        elm.find('.slider-input').val(value);
        valueElem.val(value);
        if (this._object.show_value) {
          var dkInput = elm.find('.dk-input');
          if (!dkInput.hasClass('value-shown')) {
            dkInput.addClass('value-shown');
          }
        } else {
          // Update aria-valuenow attribute for show_value=False
          elm.find('.slider-indicator-dot').attr('aria-valuenow', value);
        }
      },

      _isWideViewVertical: function () {
        return $('.wide_view_vertical').length > 0;
      },

      _updateRuleDrag: function _updateRuleDrag(percentage, value, element) {
        var self = this;
        if ($('.wide_view_vertical').length > 0) {
          element
            .find('.responsive-grid-wide .rule-drag')
            .css('top', percentage + '%');
        } else {
          element
            .find('.responsive-grid-wide .rule-drag')
            .css('left', percentage + '%');
        }
        element
          .find('.responsive-grid-skinny .rule-drag')
          .css('top', percentage + '%');
        self._updateValues(value);
      },

      _calculatePercentageFromLimits: function _calculatePercentageFromLimits(
        value,
        _this
      ) {
        if (value < _this._object.min) {
          value = _this._object.min;
        } else if (value > _this._object.max) {
          value = _this._object.max;
        }
        return (
          ((value - _this._object.min) * 100) /
          (_this._object.max - _this._object.min)
        ); // calculating percentage
      },

      _checkValueLimits: function _checkValueLimits(value, _this) {
        if (value < _this._object.min) {
          return _this._object.min;
        } else if (value > _this._object.max) {
          return _this._object.max;
        } else {
          return value;
        }
      },
    },
    {
      types: ['rule'],
      views: ['rule'],
    }
  );

  Rule.register();
  widgets.Rule = Rule;
})((Gryphon.widgets = Gryphon.widgets || {}));

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./error.hbs"), '      ', 'error', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./matrix-table.hbs"), '        ', 'matrix-table', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./matrix-flashcards.hbs"), '        ', 'matrix-flashcards', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(require("./matrix-cards.hbs"), '        ', 'matrix-cards', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"question question-matrix question-matrix-render-"
    + escapeExpression(lambda((depth0 != null ? depth0.render : depth0), depth0))
    + "\">\n    <div class=\"question-text\">";
  stack1 = lambda((depth0 != null ? depth0.text : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    <div class=\"matrix-container\">\n\n        <!-- NOTE: Partial for table-based displays of the matrix widget, etc etc -->\n        <!-- This would allow this single template to handle multiple 'partials' based on the 'render' option -->\n";
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, (depth0 != null ? depth0.render : depth0), "list", {"name":"ifequal","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, (depth0 != null ? depth0.render : depth0), "flashcards", {"name":"ifequal","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, (depth0 != null ? depth0.render : depth0), "cards", {"name":"ifequal","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0, (depth0 != null ? depth0.render : depth0), "input_box", {"name":"ifequal","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n</div>";
},"usePartial":true,"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "\n    <div class=\"validation-result alert alert-error\">"
    + escapeExpression(lambda((depth0 != null ? depth0.error_message : depth0), depth0))
    + "</div>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "<p class=\"captcha-text\">";
  stack1 = lambda((depth0 != null ? depth0.privacy_and_terms_of_service : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</p>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"captcha-widget\">";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  <input\n    type=\"hidden\"\n    name=\""
    + escapeExpression(lambda((depth0 != null ? depth0.token_id : depth0), depth0))
    + "\"\n  />\n</div>\n";
  stack1 = __default(require("../js/helpers/ifindexof.js")).call(depth0, (depth0 != null ? depth0.version : depth0), "v3", ">", -1, {"name":"ifindexof","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<script src=\"";
  stack1 = lambda((depth0 != null ? depth0.RECAPTCHA_DOMAIN : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "/recaptcha/";
  stack1 = lambda((depth0 != null ? depth0.RECAPTCHA_SCRIPT : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "?";
  stack1 = lambda((depth0 != null ? depth0.RECAPTCHA_SCRIPT_PARAMS : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" async defer></script>\n<div id=\"captcha_v2_element\" class=\"g-recaptcha\" data-sitekey=\"";
  stack1 = lambda((depth0 != null ? depth0.RECAPTCHA_SITE_KEY : depth0), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\" data-callback=\"onVerifyCaptcha\"></div>\n<br>\n\n";
},"useData":true});